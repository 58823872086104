import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { AppBar, Tabs, Tab, Box, CircularProgress, LinearProgress } from '@mui/material';
import useStyles from './styles';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import { useStoreActions } from '../../../models/RootStore';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
			{value === index && <Box sx={{ p: 2 }}>{children}</Box>}
		</div>
	);
};

const ConfigComponent = ({ deviceId }) => {
	const classes = useStyles();

	const { getCategories, getConfigCategory, setConfigCategory } = useStoreActions((actions) => actions.devices);
	const { addMessage } = useStoreActions((action) => action.errorMessage);
	const [selectedTab, setSelectedTab] = useState('');
	const [categories, setCategories] = useState({});
	const [formSchema, setFormSchema] = useState({});
	const [isLoadingCategories, setIsLoadingCategories] = useState(true);
	const [isLoadingSchema, setIsLoadingSchema] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const fetchCategories = useCallback(async () => {
		if (!deviceId) {
			return;
		}
		setIsLoadingCategories(true);
		try {
			const response = await getCategories(deviceId);
			setCategories(response);
			const firstKey = Object.keys(response)[0] || '';
			setSelectedTab(firstKey);
		} catch (error) {
			addMessage({ message: error.message });
		} finally {
			setIsLoadingCategories(false);
		}
	}, [deviceId, getCategories]);

	useEffect(() => {
		fetchCategories();
	}, [fetchCategories]);

	const fetchSchema = useCallback(async () => {
		if (!deviceId || !selectedTab) {
			return;
		}
		setIsLoadingSchema(true);
		try {
			const schema = await getConfigCategory({ deviceId, category: selectedTab });
			setFormSchema(schema);
		} catch (error) {
			addMessage({ message: error.message });
		} finally {
			setIsLoadingSchema(false);
		}
	}, [deviceId, selectedTab, getConfigCategory]);

	useEffect(() => {
		fetchSchema();
	}, [fetchSchema]);

	const tabs = useMemo(() => Object.keys(categories), [categories]);

	const handleChange = useCallback((event, newTab) => {
		setSelectedTab(newTab);
	}, []);

	const handleSubmit = async (data) => {
		if(isSubmitting){
			return;
		}
		setIsSubmitting(true);
		try {
			await setConfigCategory({ deviceId, category: selectedTab, data: data.formData });
		} catch (error) {
			addMessage({ message: error.message });
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div className={classes.root}>
			{isLoadingCategories ? (
				<Box display='flex' justifyContent='center' p={2}>
					<CircularProgress color='inherit' />
				</Box>
			) : (
				<AppBar position='static' className={classes.tabsContainer}>
					<Tabs value={selectedTab || ''} onChange={handleChange} aria-label='tabs' className={classes.tabs}>
						{tabs.map((key) => (
							<Tab key={key} label={categories[key]} value={key} />
						))}
					</Tabs>
					{isSubmitting && <LinearProgress />}
				</AppBar>
			)}
			{!isLoadingCategories &&
				tabs.map((key) => (
					<TabPanel key={key} value={selectedTab} index={key} className={classes.tabPanel}>
						{isLoadingSchema ? (
							<Box display='flex' justifyContent='center' p={2}>
								<CircularProgress color='inherit' />
							</Box>
						) : (
							<Form
								key={`form-${selectedTab}`}
								schema={formSchema?.configSchema}
								uiSchema={formSchema?.uiSchema}
								formData={formSchema?.configData}
								onSubmit={handleSubmit}
								validator={validator}
							/>
						)}
					</TabPanel>
				))}
		</div>
	);
};

export default ConfigComponent;
