import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  IconButton
} from "@mui/material";
import { Search } from "@mui/icons-material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
// @ts-ignore
import { IDeviceModel } from "../../models/DeviceModel";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DeviceManagement.style";
import EditDevice from "./EditDevice";

// The main screen
export default function DeviceManager(props: any) {
  const styles: any = useStyles();
  const [selectedDevice, setSelectedDevice] = useState<IDeviceModel | null>(
    null
  );
  const [devicesList, setDevicesList] = useState<any>([]);
  const deviceTypes = useStoreState((state) => state.types.deviceTypes);
  const [searchVal, setSearchVal] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);
  const getDevicesBySerial = useStoreActions((actions) => actions.devices.getDevicesBySerial);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const setSelections = useStoreActions((a) => a.selections.setSelections);

  useEffect(() => {
    (async () => {
      if (submit && searchVal) {
        getDevicesBySerial({ serial: searchVal })
          .then((res: any) => {
            setDevicesList(Object.values(res));
          })
          .catch((err: any) => addMessage({ message: err.message }))
          .finally(() => {
            setSubmit(false);
          })
      }
    })();
  }, [submit]);

  if (selectedDevice) {
    return (
      <EditDevice
        onClose={() => setSelectedDevice(null)}
        selectedDevice={selectedDevice}
        {...props}
      />
    );
  }
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setSubmit(true)
    }
  };

  return (
    <ServiceNavigationBar
      title={t`Device Management`}
      searchComponent={
        <Input
          placeholder={t`Device Serial`}
          value={searchVal}
          onChange={(event: any) => setSearchVal(event.target.value)}
          disableUnderline={true}
          onKeyDown={handleKeyDown}
          classes={{ root: styles.inputRoot }}
          endAdornment={
            searchVal && (
              <IconButton
                onClick={() => setSubmit(true)}
                className={styles.searchIcon}
              >
                <Search />
              </IconButton>
            )
          }
        />
      }
      {...props}
    >
      <Paper elevation={0} className={styles.paperPadding}>
        <TableContainer>
          <Table className="" aria-label="customized table">
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell
                  className={styles.headCells}
                  align="left"
                >
                  <div className={styles.headContainer}>
                    {t`DEVICE SERIAL`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="left"
                >
                  <div className={styles.headContainer}>
                    {t`DEVICE TYPE`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="left"
                >
                  <div className={styles.headContainer}>
                    {t`SITE`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="left"
                >
                  <div className={styles.headContainer}>
                    {t`ACCOUNT`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="left"
                >
                  <div className={styles.headContainer}>
                    {t`CUSTOMER`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="center"
                >
                  <div className={styles.headContainer}>
                    {t`Firmware Version`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="center"
                >
                  <div className={styles.headContainer}>
                    {t`CONNECTED`}
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="center"
                >
                  <div className={styles.headContainer}>
                    {t`REGISTERED`}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devicesList.map((device: IDeviceModel) => {
                const { id, serial, deviceType, customerId, customer, firmwareVersion, isConnected, isRegistered, siteName, account } = device
                return (
                  <TableRow hover tabIndex={-1} key={id} >
                    <TableCell
                      onClick={() => {
                        setSelectedDevice(device);
                        props.history.push(`/devices/${id}`);
                      }}
                      className={styles.rowCellHyperLink}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {serial}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {deviceType ? deviceTypes[deviceType]?.shortName : ""}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {siteName}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {account}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setSelections({
                          customerId: customerId,
                        });
                        props.history.push(`/users2`);
                      }}
                      className={styles.rowCellHyperLink}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {customer}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {firmwareVersion}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {isConnected ? (
                        <i className="fas fa-check"></i>
                      ) : (
                        <i className="fas fa-times"></i>
                      )}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {isRegistered ? (
                        <i className="fas fa-check"></i>
                      ) : (
                        <i className="fas fa-times"></i>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </ServiceNavigationBar>
  );
}
