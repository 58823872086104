import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./Subscriptions.style";

export default function SubscriptionsCustomers(props: any) {
  const styles: any = useStyles();
  const getSubscriptionsCustomersStatus = useStoreActions((state) => state.subscriptions.getSubscriptionsCustomersStatus);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [customersData, setCustomersData] = useState<any>([]);
  const regionTypesMirror = useStoreState((s) => s.regionTypesMirror);
  const [lookupAnchor, setAnchor] = useState(null);
  const [filters, setFilters] = useState<any>({
    names: [],
    regions: [],
    statuses: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    names: [],
    regions: [],
    statuses: []
  });
  const [clickedColumnName, setClickedColumnName] = useState<any>("");

  useEffect(() => {
    getSubscriptionsCustomersStatus().then((res: any) => {
      setCustomersData(res);
    }
    ).catch(({ message }: any) => {
      addMessage({ message });
    });

  }, []);

  useEffect(() => {
    const filteredCustomers = _((Object.values(customersData)))
      .filter((customer: any) => {

        const name = customer?.name || "";
        return appliedFilters.names.length
          ? appliedFilters.names.includes(name)
          : true;
      })

      .filter((customer: any) => {
        const regions = customer?.region ? regionTypesMirror[customer.region] : "";
        return appliedFilters.regions.length
          ? appliedFilters.regions.includes(regions)
          : true;
      })
      .filter((customer: any) => {
        const status = customer?.isOk ? "OK" : "NOT OK";
        return appliedFilters.statuses.length
          ? appliedFilters.statuses.includes(status)
          : true;
      })

      .value();
    setFilteredData(filteredCustomers);

  }, [appliedFilters, customersData]);

  useEffect(() => {

    const namesList: any[] = [];
    const regionsList: any = [];
    const statusesList: any = ["OK", "NOT OK"];

    Object.values(customersData).map((customer: any) => {
      const name = customer?.name || "";
      const region = customer.region ? regionTypesMirror[customer.region] : "";

      if (namesList.indexOf(name) === -1) {
        namesList.push(name);
      }

      if (regionsList.indexOf(region) === -1) {
        regionsList.push(region);
      }

      return true;

    });
    setFilters({ regions: regionsList, names: namesList, statuses: statusesList });

  }, [filteredData, appliedFilters]);

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;

  return (
    <>
      <ServiceNavigationBar
        title={t`Subscriptions Customers status`}
        {...props}
      >

        <Paper elevation={0} className={styles.paperPadding}>
          <TableContainer>
            <Table stickyHeader className="" aria-label="customized table">
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell
                    className={styles.headCells}
                    onClick={(e: any) => handleFilterPopup(e, "names")}>

                    <div className={styles.headContainer}>
                      {t`Customer Name`}
                      <FilterList
                        className={clsx(styles.filterStyle, {
                          [styles.blueFilter]: appliedFilters.names.length
                        })}
                      />
                    </div>
                  </TableCell>

                  <TableCell
                    className={styles.headCells}
                    onClick={(e: any) => handleFilterPopup(e, "regions")}>
                    <div className={styles.headContainer}>
                      {t`Region`}
                      <FilterList
                        className={clsx(styles.filterStyle, {
                          [styles.blueFilter]: appliedFilters.regions.length
                        })}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className={styles.headCells}
                    onClick={(e: any) => handleFilterPopup(e, "statuses")}
                  >
                    <div className={styles.headContainer}>
                      {t`Status`}
                      <FilterList
                        className={clsx(styles.filterStyle, {
                          [styles.blueFilter]: appliedFilters.statuses.length
                        })}
                      />
                    </div>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy(filteredData, [(customer) => customer.name.toLowerCase()], ["asc"]).map((customer: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={customer.customerId} onDoubleClick={() => {
                      setSelections({ customerId: customer.customerId, siteId: null, systemId: null, unitId: null });
                      props.history.push(`/subscriptions-customer-sites`);
                    }} >

                      <TableCell className={styles.rowCell} >
                        {customer.name}
                      </TableCell>

                      <TableCell className={styles.rowCell} >
                        {customer?.region ? regionTypesMirror[customer.region] : ""}
                      </TableCell>

                      <TableCell className={styles.rowCell} >
                        {customer?.isOk ? "OK" : "NOT OK"}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ServiceNavigationBar>

      {clickedColumnName && (
        <Lookup
          filtersList={filters[clickedColumnName]}
          appliedFilters={appliedFilters[clickedColumnName]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedColumnName("")}
          clearAllFilters={() => setAppliedFilters({
            names: [],
            regions: [],
            statuses: []
          })}
          hasFilters={hasFilters}
        />
      )}
    </>
  );
}
