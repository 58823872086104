import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "./menuItem.style";

const tooltipProps: { placement: any; arrow: boolean } = {
  placement: "right",
  arrow: true
};

interface IMenuItemProps {
  Icon: any;
  name: string;
  path: string;
  disabled?: boolean;
  history: any;
}

const NavigationMenuItem = ({
  Icon,
  name,
  path,
  history,
  disabled = false
}: IMenuItemProps) => {
  const styles: any = useStyles();
  const { location } = history;
  const { pathname } = location;
  const lastItem = pathname.substring(pathname.lastIndexOf("/") + 1);
  const selected = `/${lastItem}` === path;

  const handleClick = () => {
    if (disabled) {
      return;
    }
    history.push(path);
  };

  return (
    <Button
      startIcon={Icon}
      onClick={handleClick}
      className={clsx(styles.buttonStyle, {
        [styles.selectedButton]: selected,
        [styles.disabledButton]: disabled
      })}
    >
      <Typography className={styles.buttonText}>{name}</Typography>
    </Button>
  );
};

export default NavigationMenuItem;
