import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({

  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "#fff",
    overflowY: "auto",
    overflowX: "auto"
  },
  
  viewBar: {
    background: "#a9a9a9",
    border: "1px gray solid",
    minWidth: "352px",
    textAlign: "center"
  },
  
  title: {
    borderBottom: "1px gray solid",
    marginBottom: "32px",
    width: "100%",
    lineHeight: "50px"
  },
  contentArea: {
    background: "white",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: "1 1"
  },
  
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "32px",
    fontWeight: "bold",
    padding: "32px",
    border: "1px solid #d3d3d3"
  },
  content: {
    backgroundColor: "#fff"
  },
  disabledContent: {
    backgroundColor: "#fff",
    cursor: "auto"
  },
  detailsSectionTitle: {
    fontSize: "32px",
    fontWeight: "bold",
    padding: "16px"
  },
  addButton: {
    minWidth: "192px",
    marginLeft: "16px",
    marginRight: "16px"
  },
  addNewDevice: {
    marginBottom: "16px",
    alignSelf: "flex-end",
    display: "flex"
  },
  searchBox: {
    margin: "10px 0"
  },
  siteDetailsPageContent: {
    display: "flex",
    height: "100%",
    maxHeight: "calc(100% - 100px)",
    width: "100%",
    padding: "20px 21px",
    flexFlow: "column nowrap",
    paddingTop: "0"
  },
  saveButtonStyle: {
    padding: "0",
    backgroundColor: "#331e38",
    minHeight: "40px",
    color: "#fdfdfe"
  },
  siteDetailsForm: {
    display: "flex",
    flexFlow: "column nowrap",
    boxSizing: "border-box"
  },
  siteDetailsContainer: {
    display: "flex",
    height: "100%",
    flexFlow: "column nowrap",
    width: "100%",
    maxWidth: "700px",
    backgroundColor: "white",
    minWidth: "500px",
    minHeight: "480px"
  },
  editSiteTitle: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold"
  },
  siteButtonsContainer: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "15px",
    marginLeft: "16px"
  },
  siteButtonsStyle: {
    width: "130px",
    padding: "0",
    backgroundColor: "#331e38",
    minHeight: "40px",
    color: "#fdfdfe"
  },
  space: {
    marginRight: "20px"
  },
  siteDetailsFields: {
    "&>input": {
      padding: "0",
      margin: "0"
    }
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  siteDetailsHeader: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "4px 20px",
    boxShadow: "0px 5px 5px 0px rgba(214, 206, 214, 1)",
    boxSizing: "border-box"
  },
  loader: {
    height: "25px",
    width: "25px",
    color: "#331e38"
  },
  siteDetailsIcons: {
    fontSize: "20px"
  },
  siteDetailsFieldsContainer: {
    width: "100%",
    boxSizing: "border-box",
    padding: "0 20px",
    overflow: "auto",
    height: "calc(100vh - 180px)",
    "& label": {
      marginTop: "-5px"
    }
  },
  flexBox: {
    display: "flex"
  },
  blueFilter: {
    fill: "#1976d2"
  },
  filterStyle: {
    marginLeft: "10px"
  },
  siteBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  noContentContainer: {
    display: "flex",
    flex: "1",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px"
  },
  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)"
  },
  arrow: {
    fontSize: "91px"
  },
  rotateArrow: { transform: "rotate(-90deg)" },
  headCells: {
    borderBottom: "none",
    padding: "5px 16px",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    fontFamily: theme.tables.headerCell.fontFamily,
    height: "45px",
    fontSize: "13px"
  },
  sortLabel: {
    color: theme.tables.headerCell.color
  },
  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding
  },
  root: {
    flexGrow: "1",
    maxWidth: "400px",
    maxHeight: "calc(100vh - 280px)",
    background: "#fff"
  },
  arrowContainer: {
    minWidth: "20px",
    height: "1px",
    borderBottom: "1px solid #d5d2d5",
    marginLeft: "-1px"
  },
  customerIconContainer: {
    alignItems: "center",
    width: "unset",
    marginRight: "13px",
    "& div": { display: "none" },
    marginLeft: "10px"
  },
  customerGroup: {
    borderLeft: "1px solid #d5d2d5",
    marginLeft: "21px"
  },
  label: {
    color: "#545964",
    padding: "0"
  },
  disabledLabel: {
    color: "#545964",
    padding: "0",
    "&:hover": {
      background: "none"
    }
  },
  itemRoot: {
    color: "#545964",
    fontSize: "14px",
    width: "100%",
    minHeight: "40px",
    "&:hover > $content": {
      backgroundColor: "transparent"
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, #fff)`,
      color: "var(--tree-view-color)"
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent"
    }
  },
  itemContent: {
    color: "#545964",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingRight: "10px",
    fontWeight: "400",
    "$expanded > &": {
      fontWeight: "400"
    }
  },
  group: {
    borderLeft: "1px solid #d5d2d5",
    marginLeft: "31px"
  },
  expanded: {},
  selected: {},
  ItemLabel: {
    fontWeight: "inherit",
    color: "inherit",
    padding: "0",
    display: "flex"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: "9px",
    paddingLeft: "0"
  },
  labelIcon: {
    marginRight: "10px"
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: "1",
    fontSize: "15px"
  },
  iconContainer: {
    alignItems: "center",
    width: "unset",
    height: "39px",
    margin: "0",
    paddingRight: "4px"
  },
  smallCheckbox: {
    marginRight: "10px",
    padding: "0",
    "& span": {
      width: "18px",
      height: "18px",
      borderRadius: "3px",
      backgroundColor: "#fff"
    }
  },
  flagsTreeContainer: {
    height: "250px"
  },
  pageContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-start"
  },
  errorMessage: {
    color: "red",
    lineHeight: "35px"
  },
  IconButton: {
    position: "absolute",
    zIndex: "99",
    top: "-15px",
    right: "0"
  },
  popoverTitle: {
    padding: "10px 10px 0px 10px",
    color: "#4b1c46",
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 10px",
  },
  red: {
    color: "red"
  },
  orange: {
    color: "orange"
  },
  green: {
    color: "green"
  },
  visuallyHidden: {
    border: "0",
    clip: "rect(0 0 0 0)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    top: "20px",
    width: "1px"
  },
  paperPadding: {
    padding: "12px 20px 0px 20px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "calc(100% - 130px)",
    flex: "1"
  },
  loaderContainer: {
    alignSelf: "center",
    display: "flex",
    flex: "1",
    alignItems: "center"
  }, 
  
}));
export default useStyles;
