import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({

  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    backgroundImage: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px"
  },

  section: {
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "92%",
    boxSizing: "border-box"
  },

  sectionTitle: {
    fontWeight: "bold",
    marginBottom: "10px",
    borderBottom: "1px rgb(87, 69, 92) solid"
  },
  fieldGridContainer: {
    "&>button": {
      width: "144px"
    },
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 0",
    marginBottom: "30px"
  },
  siteDetailsHeader: {
    padding: "4px 20px",
    boxShadow: "0px 5px 5px 0px rgba(214, 206, 214, 1)",
    boxSizing: "border-box",
    fontWeight: "bold",
    marginBottom: "10px"
  },
  saveButtonStyle: {
    width: "24%",
    color: "white",
    backgroundColor: "#331e38",
    marginLeft: "20px"
  },
  editSiteTitle: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold"
  },
  fieldTitleGrid: {
    alignSelf: "center"
  },

  fieldBtnGrid: {
    display: "flex",
    justifyContent: "space-evenly"
  },

  filedTitle: {
    fontWeight: "bold",
    fontSize: "16px"
  },

  textField: {
    width: "100%",
    height: "4rem",
    lineHeight: "0",
    "&>input": {
      border: "1px solid gray",
      borderRadius: "3px",
      fontSize: "20px"
    }
  },

  fieldInputGrid: {
    flexDirection: "unset"
  },

  textFieldValue: {
    width: "6rem"
  },

  selector: {
    width: "100%",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px 1rem"
  },

  bootTitle: {
    fontWeight: "bold",
    fontSize: "16px"
  },

  editDeviceWrapper: {
    height: "74vh",
    width: "100%"
  },

  bootWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around"
  },

  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "200px"
  },
  adormentsStyle: {
    fontSize: "20px"
  },
  searchBox: {
    marginBottom: "15px",
    width: "25%",
    "&>input": {
      fontSize: "16px"
    }
  },
  tableHead: {
    height: "45px",
    backgroundColor: "rgb(239, 239, 242, 0.48)"
  },
  headCells: {
    border: "none",
    padding: "0 15px",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    fontFamily: theme.tables.headerCell.fontFamily,
    height: 45,
    fontSize: 13
  },
  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding
  },

  consoleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "65%",
    height: "100%",
    "&>div": {
      maxWidth: "100%",
      maxHeight: "100%"
    },
    "&:first-child": {
      height: "auto",
      maxWidth: "100%",
      minHeight: "auto"
    }
  },

  siteDetailsForm: {
    width: "30%",
    height: "90%",
    "&>label": {
      minWidth: "125px",
      fontSize: "12px",
      fontWeight: "bold"
    },
    "&>div": {
      padding: "0 6px",
      boxSizing: "border-box",
      fontSize: "12px"
    }
  },
  deviceInfoTitle: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "8px 6px",
    boxShadow: "0px 6px 7px 0px rgba(214, 206, 214, 1)",
    marginBottom: "10px"
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "200px"
  },
  blueFilter: {
    fill: "#1976d2"
  },
  filterStyle: {
    marginLeft: "10px"
  },
  view: {
    display: "flex",
    height: "100%"
  },
  content: {
    // margin: '1rem',
    height: "100%",
    marginTop: 0
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
    height: "100vh"
  },
  container: {
    padding: "3rem"
  },
  tableContainer: {
    height: "calc(100% - 53px)",
    // padding: "1rem",
    width: "auto"
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progress: {
    height: "calc(100vh - 125px)",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  hidden: {
    display: "none"
  },
  tableHeadCell: {
    borderBottom: "none"
  },

  alertInfoContainer: {
    height: "100%"
  },
  selected: {
    backgroundColor: "#f4f4f4"
  },
  dateColumn: { minWidth: "135px" },
  tablePaper: {
    background: "#fefefe",
    padding: "20px",
    paddingTop: 0,
    borderRadius: "4px",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    border: "solid 1px #ebebeb",
    height: "100%"
  },
  tablePagesHeader: { display: "flex", justifyContent: "flex-end" },
  columnsTitles: {
    border: "none",
    padding: "0 15px",
    fontSize: "13px",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    height: 45
  },
  pagingStyle: { border: "none" },
  cellStyle: {
    fontSize: "13px",
    height: "35px",
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem",
    minHeight: "70px",
    background: theme.palette.background.default
  },
  contentHeaderContainer: {
    display: "flex",
    padding: "16px",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "70px"
  },
  shareButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
    borderRadius: "6px",
    background: theme.gradiants.button,
    "&:hover": {
      boxShadow: "none"
    },
    "&:focus": {
      boxShadow: "none"
    }
  },
  showWhiteSpace: {
    whiteSpace: "pre"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px"
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  searchIcon: {
    transform: "scale(0.7)"
  },
  exportBtn: {
    alignSelf: "flex-end",
    marginBottom: "20px"
  }
}));
export default useStyles;
