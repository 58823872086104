import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import Button from "../cool_widgets/Button";
import useStyles from "./ErrorBox.style";

interface ConfirmationDialogProps {
  error: string;
  title: string;
  closeText: string;
  onClose: Function;
}

export default function ErrorBox(props: any) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  const classes: any = useStyles();
  const missingFields = _.isArray(props.error)
    ? <ul className={classes.content}>{_.map(props.error, (error, index) => <li key={index}>{error}</li>)}</ul>
    : props.error;
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent className={classes.view}>
        <Typography>{missingFields}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} width={130} style={{ marginRight: "20px" }} white uppercase>
          {t`Cancel`}
        </Button>
        <Button onClick={props.onAccept} width={130} uppercase>
          {t`Ok`}
        </Button>
      </DialogActions>
    </Dialog >
  );
}
