import React from "react";
import styles from "./NavigationBar.module.css";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import { Grid, Paper, Typography } from "@mui/material";

const NavigationBar: React.FC<any> = (props: any) => {
  const pageTitle = props.pageTitle ? props.pageTitle : props.match.path;

  return (
    <div className={styles.navContainer}>
      <ServiceNavigationBar {...props} title={props.title}>
        {/* working area */}
        <Grid container className={styles.pageContainer}>
          <Grid item xs={12}>
            <Paper className={styles.pageHeader} elevation={0}>
              <Typography variant={"h6"}>{pageTitle}</Typography>
            </Paper>
          </Grid>
          {props.children}
        </Grid>
      </ServiceNavigationBar>
    </div>
  );
};

export default NavigationBar;
