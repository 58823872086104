import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { createStyles } from "@mui/styles";
import React from "react";
interface ITooltipProps {
    title: string;
    placement?: any;
    arrow?: boolean;
    classes?: any;
    children?: any;
    interactive?: any;
}

const LightTooltipStyle: any = makeStyles((theme: any) => createStyles({
    tooltip: {
        backgroundColor: theme.tooltip.box.backgroundColor,
        color: theme.tooltip.box.color,
        boxShadow: theme.shadows[1],
        fontSize: theme.tooltip.box.fontSize,
        paddingLeft: 12,
        paddingRight: 12,
        fontWeight: theme.tooltip.box.fontWeight
    },
    arrow: {
        color: theme.tooltip.arrow.color,
        filter: theme.tooltip.arrow.filter,
        marginRight: "10px !important"
    },
    popper: {}
}));

export default function LightTooltip(props: React.PropsWithChildren<ITooltipProps>) {
    const {
        tooltip,
        arrow,
        popper
    } = LightTooltipStyle();
    return (
        <Tooltip classes={{ tooltip, arrow, popper }} {...props} title={props.title}>
            {props.children}
        </Tooltip>
    );
}
