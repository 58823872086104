import {  makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  loading: {
    background: "white",
    MozFlexDirection: "column",
    flexDirection: "column",
    height: "100vh",
    display: "flex",
    fontSize: "1.5rem",
    color: "#29abe2"
  },

  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    MozFlex: "1",
    flex: 1,
    backgroundImage: "linear-gradient(rgb(58, 24, 58), rgb(22, 18, 32))",
    position: "relative"
    // backgroundImage: theme.gradiants.themeBackground
  },

  logos: {
    justifyContent: "center",
    display: "flex",
    MozFlexDirection: "column",
    flexDirection: "column",
    alignItems: "center"
  },

  logo: {
    height: "33rem",
    width: "35rem",
    marginBottom: "6rem"
  },

  logoTitle: {
    paddingBottom: "2rem"
  }
}));

export default useStyles;
