import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: any) => ({
  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)"
  },
  noContentContainer: {
    display: "flex",
    flex: "1",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  buttonStyle: {
    width: "250px",
    height: "40px",
    borderRadius: "4px",
    background: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  tableHead: {
    height: "45px"
  },
  headCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    fontFamily: theme.tables.headerCell.fontFamily,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    height: 56,
    fontSize: 13,
    whiteSpace: "nowrap"
  },
  redRow: {
    "&>th": {
      color: "red"
    }
  },
  orangeRow: {
    "&>th": {
      color: "#f5833c"
    }
  },
  rowCell: {
    fontFamily: theme.tables.tableCell.fontFamily,
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: theme.tables.tableCell.padding,
    height: 52
  },
  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
    marginBottom: "20px"
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
  },
  blueFilter: {
    fill: "#1976d2"
  },
  filterStyle: {
    marginLeft: "10px"
  }
}));
export default useStyles;
