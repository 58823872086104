
import React, { useState, useEffect } from 'react';
import {
  Button,
  InputLabel,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { t } from "ttag";
import useStyles from "./DeviceMigration.style";
import EditCustomer from "../EditCustomer/EditCustomer";
import { useStoreState } from "../../models/RootStore";
import clsx from "clsx";
import _ from "lodash";

import { useHistory } from "react-router-dom";

const CustomerDetection = (props: any) => {
  const { nextStep } = props;
  const styles: any = useStyles();
  const [customersOptions, setCustomersOptions] = useState<any>([]);
  const [customerId, setCustomerId] = useState<any>(null);
  const [customerMethod, setCustomerMethod] = useState<string>("select");
  const [error, setError] = useState<string>("");
  const customers = useStoreState((state) => state.customers.customersBasicList);
  const history = useHistory();


  const getCustomers = () => {
    const menuOptions: any[] = [];

    _.map(customers, (customer) => {
      menuOptions.push({ value: customer.id, label: customer.name });
    });

    return _.sortBy(menuOptions, [(option) => option.label?.toUpperCase()], ["asc"]);
  };

  useEffect(() => {
    setCustomersOptions(getCustomers());
  }, [customers]);


  const submit = (id: string) => {
    if (!id) {
      setError("Please select Customer or create one")
      return;
    }
    nextStep(id)
  }

  return (
    <div className={styles.customerPageContainer}>
      <Typography className={styles.errorMsg}>{error || ""}</Typography>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="monthSubUnit"
          name="customerMethod"
          value={customerMethod}
          onChange={(event: any) => setCustomerMethod(event.target.value)}
        >
          <FormControlLabel value="select" control={<Radio className={styles.radio} />} label="Select Existing Customer" />
          <FormControlLabel value="create" control={<Radio className={styles.radio} />} label="Create New Customer" />
        </RadioGroup>
      </FormControl>
      {customerMethod === "select" ? <div className={styles.existingCustomerContainer}>
        <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
          <Autocomplete
            options={customersOptions}
            id="customer-list"
            value={customerId}
            defaultValue={""}
            className={styles.autocomplete}
            onChange={(e: any, value: any) => setCustomerId(value)}
            getOptionLabel={(option: any) => option.label || ""}
            renderInput={(params: any) => (
              <TextField
                variant="filled"
                {...params}
                name={"test-random-name"}
                inputProps={{
                  ...params.inputProps,
                  disableunderline: "true",
                  autoComplete: "new-password",
                  form: {
                    autocomplete: 'off',
                  }
                }}
              />
            )}
          />

        </InputLabel>
        <Button
          title={t`save customer`}
          variant="contained"
          color="primary"
          type="submit"
          className={styles.nextBtn}
          onClick={() => submit(customerId?.value)}
        >
          {t`Next`}
        </Button>

      </div> : (
        <EditCustomer submit={submit} viewAsComponent={true} history={history} match={{ params: { customerId: null } }} />
      )}
    </div>

  );
};

export default CustomerDetection;