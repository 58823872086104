import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import clsx from "clsx";
import { t } from "ttag";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { EditIcon } from "../../iconImages";
import { ArrowDownO, CheckboxChecked } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IAccountModel } from "../../models/Account";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./EditAccount.style";

interface IAdminOption {
  value: any;
  label: string;
}

interface IPackages {
  [key: string]: boolean
}

const validationSchema = Yup.object({
  description: Yup.string(),
  region: Yup.string(),
  category: Yup.string(),
  enableAPI: Yup.boolean(),
  CRMReferenceID: Yup.string(),
  ownerCA: Yup.string(),
  ownerCS: Yup.string(),
  journyPhase: Yup.string(),
  advancedOperationsPackage: Yup.string(),
  predictiveMaintenancePackage: Yup.string()
});

const AccountDetails = (props: any) => {
  const styles: any = useStyles();
  const [account, setAccount] = useState<IAccountModel | null>(null);
  const [editable, setEditable] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { 
    getAccount, 
    updateAccount, 
    addMessage, 
    getCustomerCategories, 
    getAllAdminUsers 
  } = useStoreActions((actions) => ({
    getAccount: actions.accounts.getAccount,
    updateAccount: actions.accounts.updateAccount,
    addMessage: actions.errorMessage.addMessage,
    getCustomerCategories: actions.getCustomerCategories,
    getAllAdminUsers: actions.adminUsers.getAllAdminUsers,
  }));

  const { 
    customerCategories, 
    types 
  } = useStoreState((state) => ({
    customerCategories: state.customerCategories,
    types: state.types,
  }));

  const { regionTypes, accountPhases, customerLevelPackagesEnum } = types;
  const [advancedOperationsPackage, setAdvancedOperationsPackage] = useState<number>(customerLevelPackagesEnum.none.value);
  const [predictiveMaintenancePackage, setPredictiveMaintenancePackage] = useState<number>(customerLevelPackagesEnum.none.value);
  const [updatedPackageData, setUpdatedPackageData] = useState<IPackages>({});
  const [adminOptions, setAdminOptions] = useState<IAdminOption[]>([]);

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        const res = await getAllAdminUsers();
        setAdminOptions(Object.entries(res).map(([userId, { firstName = "", lastName = "" }]: any) => ({
          value: userId,
          label: `${firstName} ${lastName}`
        })));
      } catch (error) {
        // Handle error here
        console.error("Error fetching admin users:", error);
      }
    };
  
    fetchAdminUsers();

    if (!categories.length) { getCustomerCategories(); }

  }, []);
   

  const handlePackageChange = (value: number, type: string, packageFlag: string, setThisPackage?: any) => {
    const checked = value === customerLevelPackagesEnum.all.value;

    if (type === "professional") {
      if (checked) {
        setThisPackage(customerLevelPackagesEnum.none.value);
        setUpdatedPackageData({ ...updatedPackageData, [packageFlag]: false });
      } else {
        setThisPackage(customerLevelPackagesEnum.all.value);
        setUpdatedPackageData({
          ...updatedPackageData,
          [packageFlag]: true,
          "basePackageEnabled": false
        });
      }
    }

  };

  const regionOptions: any = useMemo(()=>{
    return Object.keys(regionTypes).sort().map((region: any) => {
      return {
        value: types.regionTypes[region],
        label: region,
        disabled: region === "unknown"
      };
    });
  }, [regionTypes, types]) 

  const accountPhasesOptions: any = useMemo(()=>{ 
    return Object.keys(accountPhases).map((phase: any) => {
    return {
      value: types.journyPhaseTypes[phase],
      label: phase,
      disabled: phase === "unknown"
    };
  });
  }, [accountPhases, types]) 

  const categories: any = useMemo(()=>{ 
    return Object.entries(customerCategories).map(([label, value]) => ({ value, label }));
  },[customerCategories]);
 
  const { match } = props;
  const { params } = match;
  const { accountId } = params;

  const fetchAccountData = async () => {
    if (!accountId) {
      return;
    }
  
    const data = await getAccount(accountId);
    setAccount(data);
    setAdvancedOperationsPackage(
      data.hvacAdvancedOperations ? customerLevelPackagesEnum.all.value : customerLevelPackagesEnum.none.value
    );
    setPredictiveMaintenancePackage(
      data.predictiveMaintenance ? customerLevelPackagesEnum.all.value : customerLevelPackagesEnum.none.value
    );
  };
  
  useEffect(() => {
    fetchAccountData();
  }, [getAccount, accountId]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setError("");
    setSubmitting(true);
    
    const {
      name,
      numberOfCustomers,
      numberOfSites,
      numberOfDevices,
      numberOfUsers,
      subscriptions,
      advancedOperationsPackage,
      predictiveMaintenancePackage,
      ...restValues
    } = values;
    
    const accountData = {
      hvacAdvancedOperations: !!advancedOperationsPackage,
      predictiveMaintenance: !!predictiveMaintenancePackage,
      ...restValues,
    };
    

    try {
      await updateAccount({
        id: accountId,
        data: accountData
      });
      
      setAccount({ ...account, ...accountData });
      setEditable(false);
    } catch (err: any) {
      addMessage({ message: err.message });
    } finally {
      setSubmitting(false);
    }
    

  };


  const Checkbox1 = (props: any) => {
    return <Checkbox
      color="default"
      edge="end"
      variant="outlined"
      disabled={!editable}
      onChange={() => { }}
      onClick={(event: any) => event.stopPropagation()}
      checkedIcon={<CheckboxChecked />}
      className={clsx(styles.smallCheckbox, { [props.className]: props.className })}
      {...props}
    />;
  };


  return (
    <ServiceNavigationBar title={account?.name} {...props}>
      <div className={styles.pageContainer}>

        <div className={styles.siteDetailsContainer}>
          <Formik
            initialValues={{
              description: account ? account?.description : "",
              name: account? account?.name : "",
              region: account?.region || regionTypes["unknown"],
              category: account ? account?.category : customerCategories.residential,
              enableAPI: account ? account?.enableAPI : false,
              CRMReferenceID: account ? account?.CRMReferenceID : "",
              ownerCA: account ? account?.ownerCA : "",
              ownerCS: account ? account?.ownerCS : "",
              journyPhase: account ? account.journyPhase : "",
              numberOfCustomers: account? account?.numberOfCustomers : 0,
              numberOfSites: account? account?.numberOfSites : 0,
              numberOfDevices: account? account?.numberOfDevices : 0,
              numberOfUsers: account? account?.numberOfUsers : 0,
              subscriptions: account? account?.subscriptions : 0,
              disableAccessToPro: account? account?.disableAccessToPro : false,
              advancedOperationsPackage: account? (account?.hvacAdvancedOperations ?
                 customerLevelPackagesEnum.all.value : customerLevelPackagesEnum.none.value) : customerLevelPackagesEnum.none.value,
              predictiveMaintenancePackage: account? (account?.predictiveMaintenance ?
                 customerLevelPackagesEnum.all.value : customerLevelPackagesEnum.none.value) : customerLevelPackagesEnum.none.value,
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleReset,
                dirty,
                isSubmitting
              } = props;
              return (
                <form onSubmit={handleSubmit} className={styles.siteDetailsForm}>
                  <div className={styles.siteDetailsHeader}>
                    <Typography className={styles.editSiteTitle}>
                      {t`Account Details`}
                    </Typography>
                    <Typography className={styles.errorMessage}>{error}</Typography>
                    {isSubmitting && (
                      <CircularProgress className={styles.loader} />
                    )}
                    {!editable && (
                      <IconButton onClick={() => setEditable(true)}>
                        <img src={EditIcon} alt="edit" />
                      </IconButton>
                    )}
                   
                    {accountId && editable && (
                    <div style={{ display: "flex" }}>
                      <IconButton type="submit" disabled={isSubmitting}>
                        <Check className={styles.customerDetailsIcons} />
                      </IconButton>
                      <IconButton
                        disabled={isSubmitting}
                        onClick={() => {
                          handleReset();
                          setEditable(false);
                        }}
                      >
                        <Close className={styles.customerDetailsIcons} />
                      </IconButton>
                    </div>
                  )}

                  </div>
                  <div className={styles.siteDetailsFieldsContainer}>
                    <AdvancedInputField
                      name="name"
                      label={t`Name`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="numberOfCustomers"
                      label={t`Number of customers`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="numberOfSites"
                      label={t`Sites`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="numberOfDevices"
                      label={t`Devices`}
                      type={"number"}
                      min={0}
                      editable={false}
                      {...props}
                    />   
                    <AdvancedInputField
                      name="numberOfUsers"
                      label={t`Users`}
                      type={"number"}
                      min={0}
                      editable={false}
                      {...props}
                    />   
                        <AdvancedInputField
                      name="subscriptions"
                      label={t`Subscriptions`}
                      type={"number"}
                      min={0}
                      editable={false}
                      {...props}
                    />   
                    <AdvancedInputField
                      name="description"
                      label={t`Description`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      name="CRMReferenceID"
                      label={t`CRM Reference ID`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      name="region"
                      options={regionOptions}
                      label={t`Sales Territory`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      name="ownerCA"
                      label={t`Sales account owner`}
                      options={adminOptions}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      name="ownerCS"
                      label={t`CS account owner`}
                      options={adminOptions}
                      editable={editable}
                      {...props}
                    />
                   
                    <AdvancedInputField
                      select
                      value={values.category}
                      name="category"
                      label={t`Customer Profile`}
                      options={categories}
                      editable={editable}
                      {...props}
                    />
                 
                    <AdvancedInputField
                      select
                      value={values.journyPhase}
                      name="journyPhase"
                      label={t`Account journey phase`}
                      options={accountPhasesOptions}
                      editable={editable}
                      {...props}
                    />
                     <AdvancedInputField
                      toggle
                      name="enableAPI"
                      label={t`Enable API`}
                      editable={editable}
                      {...props}
                    />

                    {accountId &&
                    <TreeView
                      disableSelection={!editable}
                      defaultExpanded={["Professional"]}
                      defaultCollapseIcon={<><div className={styles.arrowContainer} /> <ArrowDownO className={styles.arrow} /> </>}
                      defaultExpandIcon={<><div className={styles.arrowContainer} /> <ArrowDownO className={clsx(styles.arrow, styles.rotateArrow)} /></>}
                      defaultEndIcon={<div className={styles.arrowContainer} />}
                    >
                      <TreeItem nodeId="Professional"
                        label={
                          <div className={styles.labelRoot}>
                            <Typography variant="body2" className={styles.labelText}>
                              {t`Professional Packages`}
                            </Typography>
                          </div>
                        } >
                        <TreeItem nodeId="2"
                          label={
                            <div className={styles.labelRoot}>
                              <Checkbox1
                                indeterminate={advancedOperationsPackage === customerLevelPackagesEnum.some.value}
                                checked={advancedOperationsPackage === customerLevelPackagesEnum.all.value}
                                onChange={() => handlePackageChange(advancedOperationsPackage, "professional", "hvacAdvancedOperations", setAdvancedOperationsPackage)}
                              />
                              <Typography variant="body2" className={styles.labelText}>
                                {t`Advanced HVAC Operations`}
                              </Typography>
                            </div>
                          } />
                        <TreeItem nodeId="4"
                          label={
                            <div className={styles.labelRoot}>
                              <Checkbox1
                                indeterminate={predictiveMaintenancePackage === customerLevelPackagesEnum.some.value}
                                checked={predictiveMaintenancePackage === customerLevelPackagesEnum.all.value}
                                onChange={() => handlePackageChange(predictiveMaintenancePackage, "professional", "predictiveMaintenance", setPredictiveMaintenancePackage)}
                              />
                              <Typography variant="body2" className={styles.labelText}>
                                {t`Predictive Maintenance`}
                              </Typography>
                            </div>
                          } />
                      </TreeItem>

                    </TreeView>
                  }
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        
      </div>
    </ServiceNavigationBar>
  );
};

export default AccountDetails;
