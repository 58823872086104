import { Dialog, DialogContentText } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../widgets/Button/Button";
import { messageStyles } from "./ErrorStoreMessage.style";
import { makeStyles } from "@mui/styles";

const ErrorStoreMessage = (props: any) => {
  const [open, setOpen] = useState(false);
  const { message } = props;
  const {
    message: messageText,
    buttonText,
    onClick,
    textNotCentered
  } = message;

  const useStyles: any = makeStyles(messageStyles);
  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const onOkClick = () => {
    setOpen(false);
    onClick && onClick();
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogContentText
        className={clsx(classes.contentStyle, {
          [classes.textNotCentered]: textNotCentered
        })}
      >{messageText}</DialogContentText>
      <Button
        title={buttonText || t`Ok`}
        className={classes.saveButtonStyle}
        onClick={onOkClick}
      />
    </Dialog>
  );
};

export default ErrorStoreMessage;
