import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Terminal from "terminal-in-react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import NavigationBar from "../NavigationBar/NavigationBar";
import useStyles from "./DeviceManagement.style";
import ConfigComponent from "./ConfigComponent";

interface IDevice {
  serial: any;
  pin: any;
  isConnected: boolean;
  id: string;
  protocolVersion: any;
  firmwareVersion: any;
  buildDate: any;
  site?: any;
  deviceType?: any;
  type?:any;
}
export default function DeviceManager(props: any) {
  const styles: any = useStyles();
  const upgradeFirmware = useStoreActions((actions) => actions.devices.upgradeFirmware);
  const firmwareStatus = useStoreActions((actions) => actions.devices.firmwareStatus);
  const getDevicesTypesNames = useStoreState((state) => state.getDevicesTypesNames);
  const [error, setError] = useState<any>(null);
  const [showBootAlert, setShowBootAlert] = useState<boolean>(false);
  const [device, setDevice] = useState<IDevice>({
    serial: "",
    pin: "",
    isConnected: false,
    protocolVersion: "protocolVersion",
    firmwareVersion: "firmwareVersion",
    buildDate: "buildDate",
    id: ""
  });
  const [promptColor, setPromptColor] = useState<string>("#f05347");
  const [fmStatus, setFmStatus] = useState<string>("");
  const [fmPercent, setFmPercent] = useState<string>("");
  const [selectedDeviceToDelete, setDeviceToDelete] = useState<any>(null);
  const deleteDevice = useStoreActions((actions) => actions.devices.deleteDevice);
  const deleteSelectedDevice = () => {
    deleteDevice(selectedDeviceToDelete);
    setDeviceToDelete(null);
    props.history.push(`/devices`);
  };
  let checkStatus = true;
  const getSiteName = useStoreState((state) => state.sites.getSiteName);
  const deviceTypes = useStoreState((state) => state.types.deviceTypes);

  const getDevice = useStoreActions((actions) => actions.devices.getDeviceById);
  const controlDevice = useStoreActions(
    (actions) => actions.devices.controlDevice
  );

  const {
    match: {
      params: { deviceId }
    }
  } = props;

  // Called when entering the component.
  const checkStatusFMFc = async (device: any) => {
    const status = await firmwareStatus(device.id);

    // do nothing when no status
    if (!status.data || (status.data && status.data.status === "")) {
      setFmStatus("");
      return;
    }

    const statusToShow = status.data.status !== "UPDATING" ? status.data.status : `${status.data.status}...`;
    setFmStatus(statusToShow);
    setFmPercent(`${status.data.percent}% complete`);
    if (status.data.status !== "UPDATED") {
      checkStatusFM(device);
    }
  };

  // Using the state to clean the last timeout that is running
  const [checkStatusFM, setCheckStatusFM] = useState<any>(() => (deviceData: any) => setTimeout(async () => {
    // If already in the timeout, but exited the screen - don't do anything
    if (!checkStatus) {
      return;
    }
    const status = await firmwareStatus(deviceData.id);
    const statusToShow = status.data.status !== "UPDATING" ? status.data.status : `${status.data.status}...`;
    setFmStatus(statusToShow);
    setFmPercent(`${status.data.percent}% complete`);

    // As long as not UPDATED - will continue on checking status
    if (status.data.status !== "UPDATED") {
      checkStatusFM(deviceData);
    } else {
      setTimeout(() => {
        setFmStatus("");
      }, 23 * 1000);
    }
  }, 3000));

  useEffect(() => {
    if (!deviceId) {
      return;
    }

    getDevice(deviceId).then((res: any) => {
      setDevice(res);
      //Check if UPDATING when entering component
      checkStatusFMFc(res);
    });

    // Stop the query of FM update status when leaving component
    return () => {
      checkStatus = false;
      setCheckStatusFM(null);
    };

  }, [deviceId]);

  const onFileLoad = (event: any) => {
    if (event.target) {
      const file = event.target.files[0];
      const data = new FormData();
      data.append("file", file);

      upgradeFirmware({ deviceId: device.id, command: data });
      setFmStatus("Starting...");
      checkStatusFM(device);

      event.target.value = "";

    } else {
      console.log("something went wrong while loading the file", event);
    }
  };

  const { serial, isConnected, protocolVersion, firmwareVersion, buildDate, deviceType, type } = device;
  const isNGdevice = deviceType == 1 && type == 3;
  return (
    <NavigationBar
      navTitle={t`Device Management`}
      pageTitle={t`Device Management`}
      {...props}
    >

      <div className={styles.editDeviceWrapper}>
        <SimpleModal
          showModal={!!error}
          title={t`Error had happened`}
          contentText={error ? error : ""}
          actionName={t`OK`}
          onAction={() => {
            setError(null);
          }}
          closeModal={() => {
            setError(null);
          }}
        />

        <SimpleModal
          showModal={showBootAlert}
          title={t`Missing Boot alert`}
          contentText={t`Changes will not be set without booting the device. Are you sure you want to leave?`}
          actionName={t`Leave`}
          onAction={() => {
            props.onClose();
          }}
          closeModal={() => {
            setShowBootAlert(false);
          }}
        />
        <SimpleModal
          showModal={false}
          title={t`Booting, please wait...`}
          actionName={t`OK`}
          onAction={_.noop}
          closeModal={_.noop}
          hideBtns={true}
        >
          <div className={styles.bootWrapper}>
            <CircularProgress />
          </div>
        </SimpleModal>

        {!_.isEmpty(device) && (
          <Paper className={styles.section}>
            <Formik
              initialValues={{
                serialNumber: serial,
                deviceType: deviceType ? getDevicesTypesNames(deviceType, type) : "CA Device",
                connectionStatus: isConnected.toString(),
                protocolVersion,
                firmwareVersion,
                buildDate
              }}
              enableReinitialize={true}
              onSubmit={() => { }}
            >
              {(props) => {
                return (
                  <form className={styles.siteDetailsForm}>
                    <Typography
                      className={styles.deviceInfoTitle}
                    >{t`Device Information`}
                      <IconButton
                        onClick={() => {
                          setDeviceToDelete(device.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Typography>

                    <AdvancedInputField
                      name="serialNumber"
                      label={t`serial number`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="deviceType"
                      label={t`device type`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="connectionStatus"
                      label={t`connection status`}
                      editable={false}
                      {...props}
                    />
                    <AdvancedInputField
                      name="protocolVersion"
                      label={t`protocol version`}
                      editable={false}
                      {...props}
                    />
                    {firmwareVersion && (
                      <AdvancedInputField
                        name="firmwareVersion"
                        label={t`firmware version`}
                        editable={false}
                        {...props}
                      />
                    )}
                    {buildDate && (
                      <AdvancedInputField
                        name="buildDate"
                        label={t`build date`}
                        editable={false}
                        {...props}
                      />
                    )}
                    <div className={styles.addButtonContainer}>
                      <Button
                        className={styles.buttonStyle}
                        variant="contained"
                        component="label"
                      >
                        {t`Upload firmware update`}
                        <input
                          onChange={onFileLoad}
                          type="file"
                          style={{ display: "none" }}
                        />
                      </Button>
                    </div>
                    {fmStatus &&
                      <div>
                        <Typography
                          className={styles.deviceInfoTitle}
                        >{fmStatus}
                        </Typography>
                        <Typography>{fmPercent}</Typography>
                      </div>}
                  </form>
                );
              }}
            </Formik>

            <Grid className={styles.consoleContainer}>
              {!isNGdevice && protocolVersion >= 1 && isConnected && (
                <Terminal
                  startState="maximised"
                  color="#f05347"
                  outputColor="white"
                  prompt={promptColor}
                  backgroundColor="black"
                  barColor="black"
                  style={{
                    maxHeight: "100%",
                    fontSize: "1.125em",
                    width: "100%"
                  }}
                  commands={{
                    help: (cmd: any, print: any) => {
                      setPromptColor("black");
                      const command = { command: cmd.slice(0).join(" ") };
                      controlDevice({ deviceId, command })
                        .then((res: any) => {
                          print(res);
                          setPromptColor("#f05347");
                        })
                        .catch((err: any) => {

                          if (!_.isString(err)) {
                            err = JSON.stringify(err);
                          }

                          print(err);
                          setPromptColor("#f05347");
                        });
                    }
                  }}
                  commandPassThrough={(cmd: any, print: any) => {
                    setPromptColor("black");
                    const command = { command: cmd.slice(0).join(" ") };
                    controlDevice({ deviceId, command })
                      .then((res: any) => {
                        print(res);
                        setPromptColor("#f05347");
                      })
                      .catch((err: any) => {

                        if (!_.isString(err)) {
                          err = JSON.stringify(err);
                        }

                        print(err);
                        setPromptColor("#f05347");
                      });
                  }}
                  msg="DEVICE CONTROL"
                  allowTabs={false}
                  showActions={false}
                />
              )}
              {isNGdevice && <ConfigComponent deviceId={device.id} />}
            </Grid>
          </Paper>
        )
        }
      </div>
      <SimpleModal
        showModal={!!selectedDeviceToDelete}
        title={t`Are you sure?`}
        contentText={device.site ? t`Are you sure you want to delete device` + ` ${device.serial} ` + t`in site ` + `${getSiteName(device.site)}` + ` ?` :
          t`Are you sure you want to delete device ` + `${device.serial} ?`}
        actionName={t`OK`}
        onAction={deleteSelectedDevice}
        closeModal={() => setDeviceToDelete(null)}
      />
    </NavigationBar>
  );
}
