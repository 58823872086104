import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AddEditSubscription.style";

export const siteSubscriptionSchema = Yup.object().shape({
  numberOfUnits: Yup.number().moreThan(0, t`# of Units must be greater than 0`).required(t`Required`),
  durationInMonth: Yup.number().moreThan(0, t`Period in month must be greater than 0`).required(t`Required`),
  startDate: Yup.date().nullable().typeError(t`Invalid Date`),
  code: Yup.string().nullable().min(6).max(20).typeError(t`Must be min 6 max 20 chars`),
  endDate: Yup.date().when("startDate", {
    is: (val: any) => val !== null,
    then: Yup.date().nullable().typeError(t`Invalid Date`)
      .min(
        Yup.ref("startDate"),
        ({ min }) => t`End date should be after start date`
      ),
    otherwise: Yup.date().nullable().typeError(t`Invalid Date`)
  })
});

const AddEditSubscription = (props: any) => {
  const { isEdit, subscription, setOpenAddEdit, subscriptions, setSubscriptions, customer, toActivate, setToActivate } = props;
  const styles: any = useStyles();
  const { sitePackageEnums, sitePackagesNamesEnums = {}, sitePackagesFlagsEnums = {} } = useStoreState((state) => state.types);
  const [selectedPackages, setSelectedPackages] = useState<any>({});
  const [edited, setEdited] = useState<boolean | null>(null);
  const [multyCreation, setMultyCreation] = useState<any>(null);
  const [packagesError, setPackagesError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sitePackagesValues = Object.values(sitePackageEnums);
  const allSelectedPackages = sitePackagesValues.reduce((vals: any, item: any) => {
    if (+item === 1) {
      return vals;
    }
    return { ...vals, [item]: null };
  }, {});

  const createSubscription = useStoreActions((action) => action.subscriptions.createSubscription);
  const updateSubscription = useStoreActions((action) => action.subscriptions.updateSubscription);
  const activateSiteSubscription = useStoreActions((action) => action.subscriptions.activateSiteSubscription);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const sitePackageNameMapped: any = Object.keys(sitePackagesFlagsEnums).reduce((acc: any, cur: string) => {
    acc[sitePackagesFlagsEnums[+cur]] = sitePackagesNamesEnums[+cur];
    return acc;
  }, {});

  const sitePackageNameSorted: any = {
    hvacAdvancedOperations: sitePackageNameMapped["hvacAdvancedOperations"],
    predictiveMaintenance: sitePackageNameMapped["predictiveMaintenance"],
  }

  const onClose = () => {
    setOpenAddEdit(null);
    setToActivate(null);
  }
  const handleUpdate = (values: any) => {
    let data = { ...values };
    if (!!values.startDate) {
      data.startTimestamp = new Date(values.startDate).getTime();
    }
    if (!!values.endDate) {
      data.endTimestamp = new Date(values.endDate).getTime();
    }
    if (toActivate) {
      setIsLoading(true)
      return activateSiteSubscription({ id: toActivate, data: { startTimestamp: data.startTimestamp, endTimestamp: data.endTimestamp } })
        .then((res: any) => {
          setSubscriptions({ ...subscriptions, [subscription.id]: res });
          onClose();
        })
        .catch((err: any) => addMessage({ message: err.message }))
        .finaly(() => setIsLoading(false))
    }
    data.packages = Object.keys(selectedPackages).filter((ele: any) => selectedPackages[ele]).map(Number);
    if (packagesError) {
      return;
    }
    delete data.site;
    delete data.startDate;
    delete data.endDate;

    delete data.startTimestamp;
    delete data.endTimestamp;

    setIsLoading(true)
    updateSubscription({ id: subscription.id, data })
      .then((res: any) => {
        setSubscriptions({ ...subscriptions, [subscription.id]: res });
        onClose();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finaly(() => setIsLoading(false))
  };

  const handleCreate = (values: any) => {
    let data = values.values || values;
    data.packages = Object.keys(selectedPackages).filter((ele: any) => selectedPackages[ele]).map(Number);
    if (packagesError) {
      return;
    }

    data.customer = customer;
    delete data.startDate;
    delete data.endDate;
    setIsLoading(true)
    Promise.allSettled(Array(+values.multy || 1).fill(0).map(() => createSubscription(data)))
      .then((resArray) => {
        const newSubscriptions: any = {}
        resArray.forEach((res) => {
          if (res.status === "fulfilled") {
            newSubscriptions[res.value.id] = res.value
          }
        })
        setSubscriptions({ ...subscriptions, ...newSubscriptions });
        onClose();
      })
      .catch((err: any) => addMessage({ message: err.message }))
      .finally(() => setIsLoading(false))
  };

  useEffect(() => {
    if (!subscription) {
      setSelectedPackages(allSelectedPackages);
      return;
    }
    const packages: any = subscription?.packages.reduce((vals: any, item: any) => {
      return { ...vals, [item]: true };
    }, {});
    setSelectedPackages(packages);
  }, [subscription]);

  useEffect(() => {
    const selectedPackagesArr = Object.values(selectedPackages)
    if (selectedPackagesArr.filter((ele: any) => ele).length === 0) {
      if (selectedPackagesArr.filter((ele: any) => ele === false).length !== 0) {
        setPackagesError(t`Select at least one package`);
      }
    } else {
      packagesError && setPackagesError("");
    }

  }, [selectedPackages]);
  const supportedPackagesChange = (id: number, checked: any) => {
    setSelectedPackages({ ...selectedPackages, [id]: checked });
  };

  const DialogOFMultyCreation = () => <Dialog open={true} maxWidth="xs">
    <DialogTitle className={styles.dialogTitle}>{t`Create Multiple site Subscription`}</DialogTitle>
    <DialogContent>
      <InputLabel className={styles.fieldLabel}>{t`# Of Units`}</InputLabel>
      <TextField
        type="number"
        margin="normal"
        value={multyCreation?.multy}
        onChange={(e: any) => { e.target?.value <= 50 && setMultyCreation({ ...multyCreation, multy: (e.target?.value || 1) }) }}
        className={styles.inputField}
        inputProps={{ min: 1, max: 50 }}
      />
      <div className={styles.actionsContainer}>
        <Button onClick={() => setMultyCreation(null)} >
          {t`Cancel`}
        </Button>
        <Button onClick={() => { handleCreate(multyCreation) }} variant="contained" style={{ marginLeft: 10 }} >
          {t`Create Multiple`}
        </Button>
      </div>
    </DialogContent>
  </Dialog>

  return (<>
    <Dialog fullWidth open={true} maxWidth="xs">
      <DialogTitle className={styles.dialogTitle}>{isEdit ? (toActivate ? t`Activate Site Subscription` : t`Edit Site Subscription`) : t`Create site Subscription`}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            code: subscription?.code || "",
            numberOfUnits: subscription?.numberOfUnits || 1,
            durationInMonth: subscription?.durationInMonth || 12,
            packages: subscription?.packages || [],
            startDate: subscription?.startTimestamp ? moment(subscription?.startTimestamp) : null,
            endDate: subscription?.endTimestamp ? moment(subscription?.endTimestamp) : null
          }}
          enableReinitialize={true}
          onSubmit={isEdit ? handleUpdate : handleCreate}
          validationSchema={siteSubscriptionSchema}
        >
          {(props: any) => {
            const { values, touched, errors, handleChange, handleSubmit, setFieldValue, dirty } = props;
            const disabledButton = Object.values(selectedPackages).filter((ele: any) => ele).length === 0 || (!dirty && (!edited || !_.isEmpty(errors)))
            return (

              <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
                <InputLabel className={styles.fieldLabel}>{t`# Of Units`}</InputLabel>
                <TextField
                  type="number"
                  margin="normal"
                  value={values.numberOfUnits}
                  error={errors.numberOfUnits}
                  helperText={errors.numberOfUnits || " "}
                  name="numberOfUnits"
                  onChange={handleChange}
                  className={styles.inputField}
                  inputProps={{ min: 1 }}
                  disabled={toActivate}
                />

                <InputLabel className={styles.fieldLabel}>{t`Period in month`}</InputLabel>
                <TextField
                  type="number"
                  margin="normal"
                  value={values.durationInMonth}
                  error={errors.durationInMonth}
                  helperText={errors.durationInMonth || " "}
                  name="durationInMonth"
                  onChange={handleChange}
                  className={styles.inputField}
                  inputProps={{ min: 1 }}
                  disabled={toActivate}
                />

                <InputLabel className={styles.fieldLabel}>{t`Service provider customer code`}</InputLabel>
                <TextField
                  type="text"
                  margin="normal"
                  value={values.code}
                  error={errors.code}
                  helperText={errors.code || " "}
                  name="code"
                  onChange={handleChange}
                  className={styles.inputField}
                  disabled={toActivate}
                />

                {isEdit &&
                  <>
                    {toActivate && <div className={styles.datesContainer}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          showToolbar={false}
                          label={t`Start Date`}
                          value={values.startDate}
                          onChange={(date: any) => setFieldValue("startDate", date)}
                          renderInput={(params) => <TextField {...params} variant="standard" className={styles.datePicker} />}
                        />
                        <DatePicker
                          showToolbar={false}
                          label={t`End Date`}
                          value={values.endDate}
                          onChange={(date: any) => setFieldValue("endDate", date)}
                          renderInput={(params) => <TextField {...params} variant="standard" className={styles.datePicker} />}
                        />
                      </LocalizationProvider>
                      <Typography className={styles.errorMsg}>{toActivate && dirty && (!values.startDate || !values.endDate) ? `${!values.startDate ? "Start" : "End"} Date Required` : errors.startDate || errors.endDate || " "}</Typography>
                    </div>
                    }
                  </>
                }

                <div className={styles.optionsHolder}>
                  <InputLabel className={styles.fieldLabel}>{t`Packages`}</InputLabel>
                  <List disablePadding={true} className={styles.filterPopupList} style={{ opacity: toActivate ? 0.6 : 1 }}>
                    {Object.keys(sitePackageNameSorted).map((key: any) => {
                      const sitePackage = sitePackageEnums[key];
                      if (+sitePackage === 1) {
                        return null
                      }
                      return (
                        <ListItem key={sitePackage} disableGutters dense autoFocus >
                          <Checkbox
                            onClick={(event: any) => {
                              setEdited(true)
                              supportedPackagesChange(sitePackage, event.target.checked)
                            }}
                            color="default"
                            edge="end"
                            checked={!!selectedPackages[sitePackage]}
                            inputProps={{ "aria-labelledby": sitePackage }}
                            classes={{ root: styles.grayCheckbox }}
                            disabled={toActivate}
                          />
                          <ListItemText id={sitePackage} primary={sitePackageNameSorted[key]} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
                <div className={styles.container}>
                  <Typography className={styles.errorMsg}>{packagesError || " "}</Typography>
                  <div className={styles.actionsContainer}>
                    <Button onClick={onClose} className={styles.sharedButtonStyle}>
                      {t`Cancel`}
                    </Button>
                    <Button
                      type="submit"
                      disabled={disabledButton}
                      className={clsx(
                        styles.sharedButtonStyle,
                        styles.submitButton,
                        disabledButton && styles.disabledButton
                      )}
                    >
                      {isEdit ? (toActivate ? t`Activate` : t`Update`) : t`Create One`}
                    </Button>
                  </div>
                  {!isEdit && <>
                    <Typography className={styles.textOR}>Or</Typography>
                    <div>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => { setMultyCreation({ multy: 1, values }) }}
                        className={styles.tertiaryButtonStyle}
                        disabled={disabledButton}
                      >
                        {t`Create Multiple`}
                      </Button>
                    </div>
                  </>
                  }
                </div>
              </form>
            );
          }}
        </Formik>
      </DialogContent >
    </Dialog >
    {multyCreation && DialogOFMultyCreation()}
    {isLoading && <div className={styles.loaderContainer}>
      <CircularProgress className={styles.loader} />
    </div>
    }
  </>
  );
};

export default AddEditSubscription;
