import React, { useState, useRef } from 'react';
import {
    Button,
    Typography,
    Input
} from "@mui/material";
import { t } from "ttag";
import useStyles from "./DeviceMigration.style";
import { useStoreActions } from '../../models/RootStore';
import CustomerSelection from './CustomerSelection';
const ExcelJS = require('exceljs');

const keys = ['name', 'timezone', 'country', 'city', 'address', 'postalCode', 'region', 'serial'];


const UserDataImport = (props: any) => {
    const styles: any = useStyles();
    const formRef = useRef<HTMLFormElement>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const createSites = useStoreActions((action) => action.adminUsers.createSites);
    const { addMessage } = useStoreActions((action) => action.errorMessage);


    const importUserData = async (event: any) => {
        event.preventDefault();
        const devicesArray: any[] = [];
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader()
        reader.readAsArrayBuffer(selectedFile)
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then((workbook: any) => {
                workbook.eachSheet((sheet: any, id: any) => {
                    sheet.eachRow((row: any, rowIndex: any) => {
                        const device = row.values;
                        device.shift();
                        devicesArray.push(device);
                    })
                })
                devicesArray.shift();
                const mappedArray = devicesArray.map(x => {
                    return keys.reduce((acc: any, curr: any, index: any) => {
                        acc[curr] = x[index] || '';
                        const { region, serial, ...rest } = acc;
                        return rest;
                    }, {});
                });
                createSites({ customer: selectedCustomer.value, sites: mappedArray })
                    .then(() => {
                        setSelectedFile(null);
                        formRef.current?.reset();
                        addMessage({ message: "Import success" });
                    })
                    .catch((error: any) => {
                        addMessage({ message: error.message })
                    })
            }).catch((error: any) => {
                addMessage({ message: "Failed to read file!" })
            })
        }

        reader.onerror = () => {
            addMessage({ message: "Failed to read file!" })
        };
    }

    return (
        <div className={styles.customerPageContainer}>
            <Typography className={styles.headerTitle}>{t`Import CR data`}</Typography>
            <form ref={formRef} onSubmit={importUserData} className={styles.existingCustomerContainer}>
                <CustomerSelection nextStep={setSelectedCustomer} />
                <Input
                    id="filled-basic"
                    className={styles.autocomplete}
                    inputProps={{
                        type: "file",
                        hidden: true,
                        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    }}
                    onChange={(event) => {
                        const target = event.target as HTMLInputElement;
                        const file: File = (target.files as FileList)[0];
                        setSelectedFile(file)
                    }}
                />
                <Button
                    title={t`submit`}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={styles.nextBtn}
                    disabled={!(selectedFile && selectedCustomer)}
                >
                    {t`submit`}
                </Button>

            </form>
        </div>
    );
};

export default UserDataImport;