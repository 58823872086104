import "./index.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import React from "react";
import { StoreProvider, createStore } from "easy-peasy";
import Routes from "./screens/Routes/Routes";
import storeModel from "./models/RootStore";
import { localXhr } from "./services/localXhr";
import { xhr } from "coolremote-sdk";


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createRoot } from 'react-dom/client';
// locale.init();
interface NewType {
  children: React.ReactNode;
  store: any;
}

const Store = (props: NewType) => <StoreProvider {...props} />;
const store = createStore(storeModel);

xhr.setToken(localXhr.getToken());
//React 18 --> start using this syntax -- easy peasy not supporting this version of react yet
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element')
const root = createRoot(container);
root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Store store={store}>
      <Routes />
    </Store>
  </LocalizationProvider>
);
