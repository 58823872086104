import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  item: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "50px",
    position: "relative",
    borderTop: theme.header.unitsList.item.borderTop,
    color: "white",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    fontSize: theme.header.unitsList.item.fontSize,
    background: theme.header.unitsList.item.background
  },

  onClick: {
    flexGrow: 9,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center"
  },

  locationTitle: {
    cursor: "pointer",
    display: "inline",
    lineHeight: "50px",
    verticalAlign: "middle",
    marginRight: "1rem",
    fontSize: "0.9375rem"
  },

  collapsibleIcon: {
    fontSize: "0.875rem"
  },
  lengthTitle: {
    background: "rgba(236, 235, 239, 0.15)",
    borderRadius: "9px",
    padding: "2px 8px",
    width: "fit-content",
    justifySelf: "center",
    fontSize: "0.875rem"
  },
  unitsList: {
    backgroundColor: theme.header.unitsList.backgroundColor,
    maxHeight: "40vh",
    overflowY: "auto"
  }
}));

export default useStyles;
