import { Paper } from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { t } from 'ttag';
import ServiceNavigationBar from '../../widgets/Menu/ServiceNavigationBar';
import useStyles from './styles';
import { useStoreActions } from '../../models/RootStore';

const Monitoring = (props) => {
	const classes = useStyles();
	const initMonitoring = useStoreActions((action) => action.initMonitoring);
	const [dashbords, setDashbords] = useState([]);
	const [selectedFrame, setSelectedFrame] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await initMonitoring();
				setDashbords(data);
				if (data.length > 0) {
					setSelectedFrame(data[0].name);
				}
			} catch (error) {
				console.error('Error fetching monitoring data:', error);
			}
		};
		fetchData();
	}, []);

	const handleChange = (event) => {
		setSelectedFrame(event.target.value);
	};

	return (
		<ServiceNavigationBar title={t`Monitoring`} {...props}>
			<Paper elevation={0} className={classes.paper}>
				<div className={classes.container}>
					<select value={selectedFrame} onChange={handleChange} className={classes.select}>
						{dashbords.map((frame, index) => (
							<option key={index} value={frame.name} className={classes.option}>
								{frame.name}
							</option>
						))}
					</select>
					<iframe
						src={dashbords.find((frame) => frame.name === selectedFrame)?.URL || ''}
						className={classes.iframe}
						title='Dashboard Frame'
					/>
				</div>
			</Paper>
		</ServiceNavigationBar>
	);
};

export default Monitoring;
