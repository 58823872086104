import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({

  paperPadding: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tabBtn: {
    flex: 1,
    maxWidth: 'unset'
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%",
    marginBottom: '20px'
  },
  noContentContainer: {
    display: "flex",
    flex: 1,
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100% - 130px)",
    alignSelf: "center",
    marginTop: "30px",
  },
  arrowIcon: {
    fontSize: "91px",
    transform: "rotate(90deg)",
  },
  tab: {
    display: 'flex',
    flex: 1
  },
  selectedTab: {
    color: '#fff !important',
    backgroundColor: 'rgba(66, 26, 64, 1)'
  }

}));
export default useStyles;
