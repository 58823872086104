
import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { t } from "ttag";
import useStyles from "./DeviceMigration.style";
import _ from "lodash";
import OneUser from '../UserManagement/OneUser';
import { useStoreActions, useStoreState } from "../../models/RootStore";

const UserDetection = (props: any) => {
  const { customers, nextStep, goBack, customerId } = props;
  const styles: any = useStyles();
  const [userMethod, setUserMethod] = useState<string>("create");
  const [errorOnCreate, setErrorOnCreate] = useState<string>("");
  const createUser = useStoreActions((actions) => actions.users.createUser);

  const onCreateUser = async (id: string, data: any) => {
    setErrorOnCreate("");
    const res = await createUser({ id, data });

    if (res.err) {
      setErrorOnCreate(res.err);
      return;
    }

    nextStep(res?.id)
  };
  return (
    <div className={styles.customerPageContainer}>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="monthSubUnit"
          name="userMethod"
          value={userMethod}
          onChange={(event: any) => setUserMethod(event.target.value)}
        >
          <FormControlLabel value="create" control={<Radio className={styles.radio} />} label="Create New User" />
          <FormControlLabel value="skip" control={<Radio className={styles.radio} />} label="Skip Creating User" />
        </RadioGroup>
      </FormControl>
      {userMethod === "create" ?
        <OneUser
          withPasswordField
          onSave={onCreateUser}
          onClose={() => goBack()}
          mainButtonLabel={t`Next`}
          customers={customers}
          errorText={errorOnCreate}
          customerIdLockedto={{ value: customerId, label: customers[customerId]?.name }}
          viewAsComponent
        />
        : (
          <div className={styles.existingCustomerContainer}>
            <div className={styles.actionsContainer}>
              <Button
                variant="contained"
                color="primary"
                className={styles.backBtn}
                onClick={() => goBack()}
              >
                {t`Back`}
              </Button>
              <Button
                title={t`create user`}
                variant="contained"
                color="primary"
                type="submit"
                className={styles.nextBtn}
                onClick={() => nextStep()}
              >
                {t`Next`}
              </Button>
            </div>
          </div>
        )}
    </div>

  );
};

export default UserDetection;