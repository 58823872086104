import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Check, Close, FilterList, Search } from "@mui/icons-material";
import clsx from "clsx";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DeviceTests.style";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import exportDataToExcel from "../../utils/ExportDataToExcel";

export default function NewDeviceTests(props: any) {

  const classes: any = useStyles();
  const [searchValue, setSearchValue] = useState<any>("");
  const [filteredTests, setFilteredTests] = useState<any>([]);
  const [tests, setTests] = useState<any>([]);
  const [lookupAnchor, setAnchor] = useState(null);
  const [selectedTest, setSelectedTest] = useState<any>(null);
  const [testsSummary, setTestsSummary] = useState<any>(null);
  const [filters, setFilters] = useState<any>({
    device_id: [],
    result: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    device_id: [],
    result: []
  });
  const [clickedColumnName, setClickedColumnName] = useState<any>("");

  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const selections = useStoreState((state) => state.selections.selections);

  useEffect(() => {
    if (!!selections?.dateRange?.endDate && !!selections?.dateRange?.startDate) {
      return;
    }
    const range = {
      startDate: new Date(moment().subtract(7, 'days').format()),
      endDate: new Date()
    }
    setSelections({ dateRange: range });
  }, []);

  useEffect(() => {
    if (!selections?.dateRange?.startDate || !selections?.dateRange?.endDate) {
      return
    }
    let startTime = new Date(selections.dateRange.startDate).getTime();
    let endTime = new Date(selections.dateRange.endDate).getTime();

    const interval = setInterval(() => {
      getTests(startTime, endTime);
    }, 600000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (!selections?.dateRange?.startDate || !selections?.dateRange?.endDate) {
      return
    }
    let startTime = new Date(selections.dateRange.startDate).getTime();
    let endTime = new Date(selections.dateRange.endDate).getTime();

    getTests(startTime, endTime);

    setAppliedFilters({
      device_id: [],
      result: []
    });
  }, [selections.dateRange]);

  useEffect(() => {
    if (!tests) {
      return;
    }
    if (_.isEmpty(appliedFilters) && !searchValue?.length) {
      const sorted = _.orderBy(tests, "createdAt", "desc");
      setFilteredTests(sorted);
      return;
    }
    const filteredTests = _(tests)
      .filter((test) => {
        return appliedFilters.device_id.length
          ? appliedFilters.device_id.includes(test.device_id)
          : true;
      })
      .filter((test) => {
        return appliedFilters.result.length
          ? appliedFilters.result.includes(test.result)
          : true;
      })
      .filter((test) => {
        return searchValue?.length
          ? (test.device_id.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
          : true;
      })
      .value();
    const sortedFiltered = _.orderBy(filteredTests, "createdAt", "desc");
    setFilteredTests(sortedFiltered);
  }, [appliedFilters, tests, searchValue]);

  const getTests = (startTime: any, endTime: any) => {
    sdkGlobalAdmin.getDeviceTests({
      startTime,
      endTime
    })
      .then((testRes: any) => {
        setTests(testRes);
      });
  };

  const getTestsSummary = () => {
    console.log("getDeviceTestsSummary");
    if (!selections?.dateRange?.startDate || !selections?.dateRange?.endDate) {
      return
    }
    let startTime = new Date(selections.dateRange.startDate).getTime();
    let endTime = new Date(selections.dateRange.endDate).getTime();

    sdkGlobalAdmin.getDeviceTestsSummary({
      startTime,
      endTime
    })
      .then((res: any) => {
        console.log("getDeviceTestsSummary", res);
        setTestsSummary(res);
      });
  };

  useEffect(() => {

    if (!tests) {
      return;
    }

    const lists: any = Object.values(tests).reduce((listsVal: any, test: any) => {
      const { device_id, result } = test;

      if (device_id && listsVal["device_id"]?.indexOf(device_id) === -1) {
        listsVal["device_id"].push(device_id);
      }

      if (result && listsVal["results"]?.indexOf(result) === -1) {
        listsVal["results"].push(result);
      }

      return listsVal;

    }, { device_id: [], results: [] });
    setFilters({
      device_id: lists.device_id,
      result: lists.results
    });

  }, [tests]);

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;

  const exportToExcel = () => {
    const headers = [
      {header: "DEVICE SERIAL", key: "device_id"},
      {header: "WIFI", key: "wifi", nested: "attributes", format: (value: any) => value ? "True" : "False"},
      {header: "Firmware Version", key: "firmware_version", nested: "attributes"},
      {header: "Model", key: "model", nested: "attributes"},
      {header: "Time", key: "createdAt", format: (timestamp: any) => moment(timestamp)?.format("DD/MM/YY HH:mm")},
      {header: "RESULT", key: "result"},
      {header: "IP", key: "clientIp"}

    ];

    const data = filteredTests.map((test: any) => {
      return headers.reduce((acc: any, { key, format, nested }) => {
        let value = nested? test[nested]?.[key] : test[key];
        acc[key] = format ? format(value) : value;
        return acc;
      }, {})
    })
    exportDataToExcel({ columns: headers, data, fileName: "Device tests", defaultWidth: 27 })
  }

  return (
    <ServiceNavigationBar
      filters={<SelectionsMenu
        showDateRangePicker
        hideCustomerSelection
        hideSiteSelection
        hideSystemSelection
        hideUnitSelection
      />}
      searchComponent={
        <>
          <Input
            placeholder={t`Search (Serial or Source)`}
            value={searchValue}
            onChange={(event: any) => setSearchValue(event.target.value)}
            disableUnderline={true}
            classes={{ root: classes.inputRoot }}
            startAdornment={
              <InputAdornment position="start" className={classes.searchAdorment}>
                <Search className={classes.searchIcon} />
              </InputAdornment>
            }
            endAdornment={
              searchValue && (
                <IconButton
                  onClick={() => setSearchValue("")}
                  className={classes.closeIconStyle}
                >
                  <Close />
                </IconButton>
              )
            }
          />
          <Button variant="contained" onClick={getTestsSummary}>{t`Result summary`}</Button>
        </>

      }
      {...props}
    >
      <Button variant="contained" onClick={exportToExcel} className={classes.exportBtn}>
        {t`Export to Excel`}
      </Button>

      <Paper elevation={0} className={classes.paperPadding}>
        <TableContainer>
          <Table stickyHeader className="" aria-label="customized table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell
                  className={classes.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "device_id")}

                >
                  <div className={classes.headContainer}>
                    {t`DEVICE SERIAL`}
                    <FilterList
                      className={clsx(classes.filterStyle, {
                        [classes.blueFilter]: appliedFilters?.device_id?.length
                      })}
                    />
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="center"
                >
                  <div className={classes.headContainer}>
                    {t`WIFI`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="center"
                >
                  <div className={classes.headContainer}>
                    {t`Firmware Version`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="center"
                >
                  <div className={classes.headContainer}>
                    {t`Model`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="left"
                >
                  <div className={classes.headContainer}>
                    {t`Time`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "result")}
                > <div className={classes.headContainer}>
                    {t`RESULT`}
                    <FilterList
                      className={clsx(classes.filterStyle, {
                        [classes.blueFilter]: appliedFilters?.result?.length
                      })}
                    />
                  </div>
                </TableCell>
                <TableCell
                  className={classes.headCells}
                  align="left"
                >
                  <div className={classes.headContainer}>
                    {t`IP`}
                  </div>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTests.map((test: any, index: any) => {
                const { id, device_id, result, createdAt, attributes, clientIp } = test;
                return (
                  <TableRow hover tabIndex={-1} key={`${id}-${index}`} onClick={() => setSelectedTest(test)}>
                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {device_id}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {attributes?.wifi ? <Check /> : <Close />}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {attributes?.firmware_version}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {attributes?.model}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {moment(createdAt)?.format("DD/MM/YY HH:mm")}
                    </TableCell>
                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {result}
                    </TableCell>
                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {clientIp}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {clickedColumnName && (
        <Lookup
          filtersList={filters[clickedColumnName]}
          appliedFilters={appliedFilters[clickedColumnName]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedColumnName("")}
          clearAllFilters={() => setAppliedFilters({
            device_id: [],
            result: []
          })}
          hasFilters={hasFilters}
        />
      )}

      {
        selectedTest ? (
          <Dialog
            open={selectedTest}
            onClose={() => setSelectedTest(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xl"}
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{`Device ${selectedTest.device_id} test details`}</DialogTitle>
            <DialogContent>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "15%" }}>{t`Test Name`}</TableCell>
                    <TableCell style={{ width: "15%" }}>{t`Status`}</TableCell>
                    <TableCell >{t`Errors`}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    selectedTest?.tests && Object.keys(selectedTest?.tests)?.map((testName: any) => {
                      const testObj = selectedTest?.tests[testName];
                      return (
                        <TableRow key={`${selectedTest._id}-${testName}`}>
                          <TableCell>{testName}</TableCell>
                          <TableCell>{testObj.status}</TableCell>
                          <TableCell>
                            {!_.isEmpty(testObj.errors) && <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell >{t`Code`}</TableCell>
                                  <TableCell >{t`Error`}</TableCell>
                                  <TableCell >{t`Details`}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {testObj.errors?.map((err: any, index: any) => {
                                  return (
                                    <TableRow>
                                      <TableCell>{`${err?.code}`}</TableCell>
                                      <TableCell>{`${err?.err_str}`}</TableCell>
                                      <TableCell>{`${err?.details}`}</TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedTest(null)} color="primary">
                {t`Close`}
              </Button>
            </DialogActions>
          </Dialog>
        )
          : <></>
      }

      {testsSummary &&
        <Dialog
          open={!!testsSummary}
          onClose={() => setTestsSummary(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"xl"}
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">{`Tests summary details`}</DialogTitle>
          <DialogContent>
            <Table aria-label="simple table" stickyHeader>
              <TableBody>
                {
                  Object.keys(testsSummary).map((key: any) => {
                    if (typeof testsSummary[key] !== 'object') {
                      return (
                        <TableRow key={key}>
                          <TableCell style={{ width: "15%" }}>{key}</TableCell>
                          <TableCell style={{ width: "15%" }}>{testsSummary[key]}</TableCell>
                        </TableRow>
                      )
                    }
                  })
                }
              </TableBody>
            </Table>
            {
              !_.isEmpty(testsSummary["failed_tests"]) && <>
                <Typography variant="h6">{t`Failed tests`}</Typography>
                <Table aria-label="simple table" stickyHeader>
                  <TableBody>
                    {
                      Object.keys(testsSummary["failed_tests"]).map((key: any) => {
                        return (
                          <TableRow key={key}>
                            <TableCell style={{ width: "15%" }}>{key}</TableCell>
                            <TableCell style={{ width: "15%" }}>
                              {Object.keys(testsSummary["failed_tests"][key]).map((subKey: any) => {
                                return (
                                  <div key={subKey}>
                                    {subKey} {testsSummary["failed_tests"][key][subKey]}
                                  </div>
                                )
                              })}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTestsSummary(null)} color="primary">
              {t`Close`}
            </Button>
          </DialogActions>
        </Dialog>
      }

    </ServiceNavigationBar>
  );
}
