import {
  Button,
  Collapse,
  List,
  Typography,
} from "@mui/material";
import {
  AssignmentTurnedIn,
  Dashboard,
  Devices,
  PeopleAlt,
  Room,
  Search,
  Settings,
  Biotech,
  SupervisedUserCircle,
  DashboardCustomize
} from "@mui/icons-material";
import React from "react";
import { t } from "ttag";
import packageJSON from "../../../package.json";
import { useStoreState } from "../../models/RootStore";
import styles from "./menu.style";
import MenuItem from "./MenuItem";
import SubMenuHeader from "./SubMenuHeader";
import { Audit } from "../../icons";
import assetsService from "../../services/assetsService";

export let menuOptionsNames: any;

export const Menu: React.FC<any> = ({ history }) => {
  const classes: any = styles();

  const mySupportedPages = useStoreState((s) => s.users.mySupportedPages);
  const [open, setOpen] = React.useState(true);

  const showAllPages = mySupportedPages === "all";

  const menuOptions = [
    {
      src: <Dashboard className={classes.iconSize} />,
      text: t`Dashboard`,
      path: "/dashboard",
      id: "dashboard",
      show: showAllPages || !!mySupportedPages["dashboard"]
    },

    {
      src: <PeopleAlt className={classes.iconSize} />,
      text: t`Customers`,
      path: "/customers",
      id: "customers",
      show: showAllPages || !!mySupportedPages["customers"]
    },
    {
      src: <SupervisedUserCircle className={classes.iconSize} />,
      text: t`Users`,
      path: "/users2",
      id: "users2",
      show: showAllPages || !!mySupportedPages["users2"]
    },
    {
      src: <SupervisedUserCircle className={classes.iconSize} />,
      text: t`Admin Users`,
      path: "/admin-users",
      id: "admin-users",
      show: showAllPages || !!mySupportedPages["admin-users"]
    },
    {
      src: <Devices className={classes.iconSize} />,
      text: t`Direct Devices`,
      path: "/direct-devices",
      id: "direct-devices",
      show: showAllPages || !!mySupportedPages["direct-devices"]
    },
    {
      src: <SupervisedUserCircle className={classes.iconSize} />,
      text: t`Direct Users`,
      path: "/direct-users",
      id: "direct-users",
      show: showAllPages || !!mySupportedPages["direct-users"]
    },
    {
      src: <Biotech className={classes.iconSize} />,
      text: t`Direct Tests`,
      path: "/direct-tests",
      id: "direct-tests",
      show: showAllPages || !!mySupportedPages["device-tests"]
    },
    // {
    //   src: <Room className={classes.iconSize} />,
    //   text: t`Direct Sites`,
    //   path: "/direct-sites",
    //   id: "direct-sites",
    //   show: showAllPages || !!mySupportedPages["direct-sites"]
    // },
    {
      src: <Room className={classes.iconSize} />,
      text: t`Sites`,
      path: "/sites",
      id: "sites",
      show: showAllPages || !!mySupportedPages["sites"]
    },
    {
      src: <Settings className={classes.iconSize} />,
      text: t`Support`,
      path: "/SupportView",
      id: "support",
      show: showAllPages || !!mySupportedPages["support"]
    },
    // {
    //   src: <PeopleAlt className={classes.iconSize} />,
    //   text: t`Subscriptions`,
    //   path: "/subscriptions",
    //   id: "subscriptions",
    //   show: showAllPages || !!mySupportedPages["subscriptions"]
    // },
    // {
    //   src: <SupervisedUserCircleOutlined className={classes.iconSize} />,
    //   text: t`Subscriptions - Customers`,
    //   path: "/subscriptions-customers",
    //   id: "subscriptions-customers",
    //   show: showAllPages || !!mySupportedPages["subscriptions-customers"]
    // },
    // {
    //   src: <SupervisedUserCircleOutlined className={classes.iconSize} />,
    //   text: t`Subscription - Customer sites`,
    //   path: "/subscriptions-customer-sites",
    //   id: "subscriptions-customer-sites",
    //   show: showAllPages || !!mySupportedPages["subscriptions-customer-sites"]
    // },
    {
      src: <Room className={classes.iconSize} />,
      text: t`Site Subscriptions`,
      path: "/subscriptions-site",
      id: "subscriptions-site",
      show: showAllPages || !!mySupportedPages["subscriptions-site"]
    },
    {
      src: <Room className={classes.iconSize} />,
      text: t`Availability`,
      path: "/availability",
      id: "availability",
      show: showAllPages || !!mySupportedPages["availability"]
    },
    {
      src: <SupervisedUserCircle className={classes.iconSize} />,
      text: t`Accounts`,
      path: "/accounts",
      id: "accounts",
      show: showAllPages || !!mySupportedPages["accounts"]
    },
    {
      src: <Devices className={classes.iconSize} />,
      text: t`Devices`,
      path: "/devices/registered",
      id: "devices",
      show: showAllPages || !!mySupportedPages["devices"]
    },
    {
      src: <Search className={classes.iconSize} />,
      text: t`Search Tools`,
      path: "/search",
      id: "search",
      show: showAllPages || !!mySupportedPages["search"]
    },
    {
      src: <Devices className={classes.iconSize} />,
      text: t`Device packages`,
      path: "/device-packages",
      id: "device-packages",
      // show: !!mySupportedPages["device-packages"]
      show: false
    },
    {
      src: <AssignmentTurnedIn className={classes.iconSize} />,
      text: t`Device tests`,
      path: "/device-tests",
      id: "device-tests",
      show: showAllPages || !!mySupportedPages["device-tests"]
    },
    {
      src: <Devices className={classes.iconSize} />,
      text: t`CR migration`,
      path: "/device-migration",
      id: "device-migration",
      show: showAllPages || !!mySupportedPages["device-migration"]
    },
    {
      text: t`Production`,
      path: "/Production",
      id: "Production",
      // show: showAllPages || !!mySupportedPages["device-family"] || !!mySupportedPages["device-records"],
      show: false,
      src: <Devices className={classes.iconSize} />,
      subOptions: [
        {
          text: t`Device Family`,
          path: "/device-family",
          id: "device-family",
          // show: showAllPages || !!mySupportedPages["device-family"]
          show: false,
        },
        {
          text: t`Device Records`,
          path: "/device-records",
          id: "device-records",
          // show: showAllPages || !!mySupportedPages["device-records"]
          show: false,
        }
      ]
    },
    {
      src: <Devices className={classes.iconSize} />,
      text: t`Service Parameters`,
      path: "/service-parameters",
      id: "service-parameters",
      show: showAllPages || !!mySupportedPages["service-parameters"]
    },
    {
      src: <Devices className={classes.iconSize} />,
      text: t`Service Parameters Enums`,
      path: "/service-parameters-enums",
      id: "service-parameters-enums",
      show: showAllPages || !!mySupportedPages["service-parameters-enums"]
    },
    {
      src: <Audit className={classes.iconSize} />,
      text: t`Audits`,
      path: "/audits",
      id: "audits",
      // show: showAllPages || !!mySupportedPages["audits"],
      show: false,
    },
    {
      src: <DashboardCustomize className={classes.iconSize} />,
      text: t`Monitoring`,
      path: "/monitoring",
      id: "monitoring",
      show: showAllPages || !!mySupportedPages["monitoring"]
    },
  ];
  menuOptionsNames = menuOptions.map((item: any) => { return { label: item.text, id: item.id }; });

  return (
    <List className={classes.nav} component="nav" aria-labelledby="nested-list-subheader">
      <div>
        <Button
          className={classes.logoButton}
          onClick={() => history.push("/dashboard")}
        >
          <img src={assetsService.getResourcePath("fullLogo.png")} className={classes.logoStyle} />
        </Button>
        {menuOptions.map((menuOption, index) => {
          const { src, text, path, show, subOptions } = menuOption;
          if (subOptions?.length) {

            return (
              show && <>
                <SubMenuHeader
                  key={`nav-option-${index}`}
                  Icon={src}
                  name={text}
                  open={open}
                  handleClick={() => setOpen(!open)}
                />
                <Collapse in={open} timeout="auto" unmountOnExit >
                  <List key={`nav-option-${index}`} component="div" disablePadding>
                    {subOptions.map((subOption: any, index) => {
                      return (subOption?.show && <MenuItem
                        key={`sub-option-${index}`}
                        Icon={subOption?.src}
                        name={subOption?.text}
                        path={subOption?.path}
                        history={history}
                      />)
                    })}
                  </List>
                </Collapse>
              </>
            )
          } else {
            return (
              show && <MenuItem
                key={`nav-option-${index}`}
                Icon={src}
                name={text}
                path={path}
                history={history}
              />
            );
          }
        })}
      </div>
      <Typography variant="caption" className={classes.versionText}>
        {t`Version`} {packageJSON.version}
      </Typography>
    </List >
  );
};
