import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: any) => ({
  fieldStyle: {
    width: "100%"
  },
  fieldLabel: {
    width: "100%",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#7f7182",
    marginBottom: "10px"
  },
  error: {
    fontSize: "12px",
    color: "red",
    border: "none"
  },

  helper: {
    fontSize: "12px",
    color: "#7f7182"
  },

  fieldsContainer: {
    display: "flex",
    width: "100%",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "hidden",
    marginTop: "20px"
  },

  smallField: {
    width: "48%"
  },

  twoFieldsRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    width: "100%"
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end"
  },
  sharedButtonStyle: {
    width: "90px",
    height: "40px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    margin: "15px 10px",
    marginBottom: "15px",
    padding: "0"
  },
  submitButton: {
    backgroundImage: theme.gradiants.button || "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    color: "#fdfdfe"
  },
  filterPopupList: {
    width: "260px",
    height: "350px",
    overflowY: "auto",
    paddingLeft: 10
  },
  grayCheckbox: {
    marginRight: "10px",
    "&$checked": {
      color: "#736973"
    }
  },
  optionsHolder: {
    display: "flex",
    marginTop: "15px",
    textTransform: "capitalize"
  }

}));

export default useStyles;
