import clsx from "clsx";
import React from "react";
import { ChevronRightOutlined } from "@mui/icons-material";
import _ from "lodash";
import useStyles from "./MenuItemIcon.style";

interface IMenuItemIconProps {
  Icon?: any;
  title: string;
  onClick?: (unitId: string) => void;
  unit: string;
  isSelected: boolean;
}

const MenuItemIcon: React.FC<IMenuItemIconProps & any> = props => {
  const classes: any = useStyles();
  const onClick = () => {
    if (_.isFunction(props.onClick)) {
      props.onClick(props.unit);
    }
  };
  return (
    <header>
      <div
        className={clsx(classes.menuHeader, {
          [classes.selectedItem]: props.isSelected
        })}
        onClick={onClick}
      >
        <span className={classes.menuTitle}>{props.title}</span>
        {props.isSelected && <ChevronRightOutlined className={classes.icon} />}
      </div>
    </header>
  );
};
export default MenuItemIcon;
