import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px",
    overflow: "auto",
    marginBottom: "20px",
  },
  content: {
    display: "flex",
    height: "100%",
    padding: "20px",
  },
  customerPageContainer: {
    width: "100%",
  },
  existingCustomerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  autocomplete: {
    width: "500px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  nextBtn: {
    marginTop: "20px",
    width: "7rem",
    display: "flex",
    alignSelf: "flex-end",
    marginLeft: "20px",
  },
  backBtn: {
    marginTop: "20px",
    color: "#4b1c46",
    width: "7rem",
    display: "flex",
    alignSelf: "flex-end",
    backgroundColor: "#ffffff",
    marginRight: "1rem",
  },
  errorMsg: {
    height: "20px",
    fontSize: "14px",
    color: "red",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  dataIndicatorContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "20px",
  },
  loaderContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: "25px",
    width: "25px",
    color: "#331e38",
  },
  headerTitle: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.dialogs.title.color,
    marginBottom: "1rem",
  },
}));
export default useStyles;
