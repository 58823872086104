import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import sdk from "coolremote-sdk";
import clsx from "clsx";
import _, { assign } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import ErrorBox from "../../components/WarnningBox/ErrorBox";
import { EditIcon } from "../../iconImages";
import { Delete } from "../../icons";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import AddEditSubscription from "./AddEditSubscription";
import useStyles from "./Subscriptions.style";
import { Lock } from "@mui/icons-material";

export default function SubscriptionsSite(props: any) {
  const styles: any = useStyles();
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getSites = useStoreActions((actions) => actions.customers.getCustomerSites);
  const getSubscriptions = useStoreActions((actions) => actions.subscriptions.getSubscriptions);
  const getSubscriptionsBySite = useStoreActions((actions) => actions.subscriptions.getSubscriptionsBySite);
  const deleteSubscription = useStoreActions((actions) => actions.subscriptions.deleteSubscription);
  const siteSubscriptionStatusMirror = useStoreState((state) => state.siteSubscriptionStatusMirror);
  const { siteSubscriptionStatusEnums = {} } = useStoreState((state) => state.types);
  const selections = useStoreState((state) => state.selections.selections);
  const [customerSites, setCustomerSites] = useState<any>({});
  const sitePackageMirror = useStoreState((s) => s.sitePackageMirror);
  const { dateFormat } = useStoreState((state) => state.users);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [activateId, setActivateId] = useState<any>(null);
  const [subscriptions, setSubscriptions] = useState<any>({});
  const [openAddEdit, setOpenAddEdit] = useState<any>(null);
  const [assignSub, setAssignSub] = useState<any>(null);
  const [targetSite, setTargetSite] = useState<any>("");

  const defaultDateFormat = dateFormat || "DD/MM/YYYY";

  useEffect(() => {
    if (!selections?.customerId) {
      return;
    }
    if (selections?.siteId && selections?.customerId) {
      getSubscriptionsBySite(selections.siteId)
        .then((data: any) => {
          setSubscriptions(data);
        }).catch(({ message }: any) => {
          addMessage({ message });
        });

    }
    else {
      getSubscriptions(selections.customerId)
        .then((data: any) => {
          setSubscriptions(data);
        }).catch(({ message }: any) => {
          addMessage({ message });
        });

      getSites(selections.customerId)
        .then((res: any) => {
          setCustomerSites(res);
        });
    }
  }, [selections]);

  const handleDelete = () => {
    deleteSubscription(deleteId)
      .then(() => {
        const { [deleteId]: toDelete, ...resetSubscriptions } = subscriptions;
        setSubscriptions(resetSubscriptions);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
    setDeleteId(null);
  };

  const closeAssignSub = () => {
    setAssignSub(null);
    setTargetSite("");
  }

  const assignSubToSite = () => {

    sdk.GlobalAdmin.assignSiteToSubscription(assignSub.id, targetSite)
      .then((resp: any) => {
        setSubscriptions({ ...subscriptions, [resp.id]: resp })
        closeAssignSub();
      })

  }

  const getPackagesNames = (packages: any) => {
    return packages.map((packageType: any) => <Typography key={packageType}> {sitePackageMirror[packageType]} </Typography>);
  };

  return (
    <>
      <ServiceNavigationBar
        filters={<SelectionsMenu hideUnitSelection hideSystemSelection />}
        {...props} >

        <div className={styles.addButtonContainer}>
          {selections?.customerId && <Button className={styles.buttonStyle} onClick={() => setOpenAddEdit("new")} >
            {t`Add new site subscription`}
          </Button>}
        </div>

        {!selections.customerId ?
          (<Grid container direction={"column"} className={styles.noContentContainer}>
            <div className={styles.grayOval}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
            <Typography>
              {t`Please select a customer and site using the above filters.`}
            </Typography>
          </Grid>
          )
          : <>
            <Paper elevation={0} className={styles.paperPadding}>
              <TableContainer>
                <Table stickyHeader className="" aria-label="customized table">
                  <TableHead className={styles.tableHead}>
                    <TableRow>
                      <TableCell className={styles.headCells}>{t`Site`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Customer`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Status`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Start date`}</TableCell>
                      <TableCell className={styles.headCells}>{t`End date`}</TableCell>
                      <TableCell className={styles.headCells}>{t`# Of units`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Code`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Packages`}</TableCell>
                      <TableCell className={styles.headCells}>{t`Device Serial`}</TableCell>
                      <TableCell align="center" className={styles.headCells} >{t`Edit`}</TableCell>
                      <TableCell align="center" className={styles.headCells} >{t`Delete`}</TableCell>
                      <TableCell align="center" className={styles.headCells} >{t`Action`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(subscriptions)?.map((subscription: any) => {
                      const { id, status, startTimestamp, endTimestamp, numberOfUnits, packages, deviceSerial, site, code, customer } = subscription;
                      const isOverdue = status === siteSubscriptionStatusMirror.overdue;
                      const isFrozen = status === siteSubscriptionStatusMirror.frozen;
                      return (
                        <TableRow className={clsx({ [styles.redRow]: isOverdue, [styles.orangeRow]: isFrozen })} hover tabIndex={-1} key={id}>
                          <TableCell className={styles.rowCell} > {site || '-'} </TableCell>
                          <TableCell className={styles.rowCell} > {customer || '-'} </TableCell>
                          <TableCell className={styles.rowCell} > {siteSubscriptionStatusEnums[status]} </TableCell>

                          <TableCell className={styles.rowCell} >
                            {startTimestamp ? moment(startTimestamp).format(defaultDateFormat) : "-"}
                          </TableCell>

                          <TableCell className={styles.rowCell} >
                            {endTimestamp ? moment(endTimestamp).format(defaultDateFormat) : "-"}
                          </TableCell>

                          <TableCell align="center" className={styles.rowCell} > {numberOfUnits} </TableCell>
                          <TableCell align="center" className={styles.rowCell} > {code} </TableCell>
                          <TableCell className={styles.rowCell} > {getPackagesNames(packages)} </TableCell>
                          <TableCell className={styles.rowCell} > {deviceSerial || "-"} </TableCell>

                          <TableCell style={{ width: 40 }} align="center" className={styles.rowCell} >
                            {
                              !!site ?
                                <Lock /> :
                                <IconButton onClick={() => setOpenAddEdit(id)}>
                                  <img src={EditIcon} alt="edit" />
                                </IconButton>
                            }
                          </TableCell>
                          <TableCell style={{ width: 40 }} align="center" className={styles.rowCell} >
                            <IconButton onClick={() => setDeleteId(id)} >
                              <Delete />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center" className={styles.rowCell} >
                            {
                              !!site ?
                                <Typography>{t`Assigned`}</Typography> :
                                <Button
                                  variant="contained"
                                  onClick={() => setAssignSub(subscription)}
                                >
                                  {t`Assign to site`}
                                </Button>
                            }
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {deleteId && <ErrorBox
              title={t`Delete Site Subscription`}
              error={t`Are you sure you want to delete this site subscription`}
              onAccept={() => handleDelete()}
              onClose={() => setDeleteId(null)}
            />}
            {openAddEdit &&
              <AddEditSubscription
                isEdit={openAddEdit !== "new"}
                subscription={subscriptions[openAddEdit]}
                setOpenAddEdit={setOpenAddEdit}
                setSubscriptions={setSubscriptions}
                subscriptions={subscriptions}
                customerSites={customerSites}
                customer={selections.customerId}
                toActivate={activateId}
                setToActivate={setActivateId}
              />}

            {
              assignSub &&
              <Dialog
                open={assignSub}
                onClose={closeAssignSub}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Assign subscription to a site"}
                </DialogTitle>
                <DialogContent>
                  <Select
                    value={targetSite}
                    onChange={(e: any) => setTargetSite(e.target.value)}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem disabled value={""}>{t`Select a site`}</MenuItem>
                    {
                      Object.values(customerSites).map((site: any) => <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>)
                    }
                  </Select>

                </DialogContent>
                <DialogActions>
                  <Button onClick={closeAssignSub} variant={'contained'}>{t`Cancel`}</Button>
                  <Button onClick={assignSubToSite} autoFocus>
                    {t`Assign`}
                  </Button>
                </DialogActions>
              </Dialog>
            }
          </>
        }
      </ServiceNavigationBar >
    </>
  );
}
