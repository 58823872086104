import { makeStyles } from "@mui/styles";

const styles: any = makeStyles((theme: any) =>
({
  buttonStyle: {
    color: "rgb(255, 255, 255)",
    height: "40px",
    fontSize: "16px",
    minWidth: "150px",
    background: "linear-gradient(to left, rgb(66, 26, 64) 100%, rgb(41, 19, 46) 0%)",
    fontWeight: "normal",
    lineHeight: "normal",
    borderRadius: "4px",
    textTransform: "capitalize",
    margin: "20px",
    marginRight: 0
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  tableHead: {
    height: "45px"
  },
  headCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    fontFamily: theme.tables.headerCell.fontFamily,
    height: 45,
    fontSize: 13
  },
  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: theme.tables.tableCell.padding,
    fontFamily: theme.tables.tableCell.fontFamily
  },
  smsWarning: {
    color: "red"
  },
  adormentsStyle: {
    fontsize: "20px"
  },
  searchBox: {
    marginBottom: "15px",
    width: "25%",
    "&>input": {
      fontSize: "16px"
    }
  },
  textFieldDialogStyle: {
    width: "70%",
    "&>input": {
      fontSize: "16px"
    },
    "&>label": {
      fontSize: "16px"
    },
    "&>textarea": {
      fontSize: "16px"
    }
  },
  createClinetModalContainer: {
    width: "500px"
  },
  paperPadding: {
    padding: "20px 20px 20px 20px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },
  pageContent: {
    display: "flex",
    maxHeight: "calc(100% - 100px)",
    flexFlow: "column nowrap",
    width: "100%",
    padding: "0px 30px"
  },

  headContainer: {
    display: "flex",
    alignItems: "center",
  },
  selectField: {
    width: "70%",
    "&>label": {
      marginTop: "-20px"
    }
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px"
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px",
    color: "#fff"
  },
  searchIcon: {
    transform: "scale(0.7)"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },
  blueFilter: {
    fill: "#1976d2"
  },
  filterStyle: {
    marginLeft: "10px",
    cursor: "pointer"
  },
  rowFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& div": {
      marginRight: "20px",
    },
    "& label": {
      marginTop: "-5px",
    }
  },
  dialogPaper: {
    minWidth: "750px"
  },
  cancelStyle: {
    color: "rgb(41, 19, 46)",
    border: "1px solid rgb(41, 19, 46)",
    height: "40px",
    fontSsize: "16px",
    minWidth: "150px",
    background: "rgb(255, 255, 255)",
    fontWeight: 500,
    lineHeight: "normal",
    marginRight: "20px",
    borderRadius: "4px",
    textTransform: "capitalize",
    margin: "20px"
  },
  dialogHeader: {
    backgroundColor: "#f6f6f7",
    paddingLeft: "20px",
    borderBottom: "1px solid #d5d2d5"
  },
  sortIconBase: {
    marginRight: "5px"
  }

}));
export default styles;
