import { Unit as UnitSDK } from "coolremote-sdk";
import {
  Action,
  action,
  ActionOn,
  actionOn,
  Computed,
  computed,
  // memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface IUnit {
  id: string;
  type: number;
  name: string;
  ambientTemperature?: number | 0;
  activeOperationMode: number | -1;
  activeOperationStatus: number | -1;
  activeSetpoint?: number | 0;
  activeFanMode?: number | -1;
  activeLouverModes?: number | -1;
  device: string;
  zone: string | null;
  system: string | null;
  internalId: string;
  proId: string | null;
  line: number /* from the first 2 chars of unit name: L1, L2, etc */;
  privateId: string /* second part of unit name, after '.': '000', '003' etc */;
  role: any;
  isConnected?: boolean;
  supportedFanModes: number[];
  supportedSwingModes: number[];
  supportedOperationModes: number[];
  supportedOperationStatuses: number[];
  temperatureLimits: any;
  compressors?: string[];
  task: number;
  capacity?: number;
  capacityMeasurementUnits?: number;
  airnet?: number;
  model?: string;
  serialNumber?: string;
  outdoorType?: number;
  outdoorTypeFamily?: number;
}

export interface IUnitMap {
  [key: string]: IUnit;
}

export type IEntityType = "customer" | "site" | "system";
export interface IUnitAffiliation {
  customerId?: string;
  siteId?: string;
  systemId?: string;
  deviceId?: string;
}
interface IUpdatePayload {
  unitId: string;
  data: object | number;
}

export interface IUnitsModel {
  allUnits: any;
  updateUnit: Thunk<IUnitsModel, IUpdatePayload>;
  getAllUnits: Thunk<IUnitsModel>;
  getUnitById: Thunk<IUnitsModel, string>;
  refreshUnit: Thunk<IUnitsModel, string>;
  _storeUpdateUnit: Action<IUnitsModel, { id: string; data: Partial<IUnit> }>;
  initialize: Action<IUnitsModel, any>;
  onInitialized: ActionOn<IUnitsModel, IRootStoreModel>;
  countUnitsFor: Computed<
    IUnitsModel,
    (entityType: IEntityType, entityId: string) => number,
    IRootStoreModel
  >;
  requestStatsRefresh: Thunk<IUnitsModel, { id: string }>;
  getUnitAffiliation: Computed<
    IUnitsModel,
    (unitId: string) => IUnitAffiliation,
    IRootStoreModel
  >;
  cleanFilterUnit: Thunk<IUnitsModel, { id: string }>;
  setActiveSetpoint: Thunk<IUnitsModel, { id: string; setpoint: any }>;
  setActiveOperationMode: Thunk<IUnitsModel, { id: string; mode: any }>;
  togglePower: Thunk<IUnitsModel, { id: string; activeOperationStatus: any }>;

}
export const unitsModel: IUnitsModel = {
  allUnits: {},
  getAllUnits: thunk(async (actions) => {
    return UnitSDK.getUnits();
  }),
  getUnitById: thunk(async (actions, payload) => {
    return UnitSDK.getUnitById(payload);
  }),
  refreshUnit: thunk(async (actions, payload) => {
    return UnitSDK.refreshUnit(payload);
  }),
  initialize: action((state, payload) => {
    const newUnits: IUnitMap = _(Object.values(payload))
      .map((unit: any) => {
        const compressors = unit.compressors
          ? Object.keys(unit.compressors)
          : undefined;
        // const airnet = unit.airnet ? unit.airnet : undefined;
        const newUnit: IUnit = { ...unit, compressors };
        return newUnit;
      })
      .keyBy("id")
      .value();
    state.allUnits = newUnits;
  }),
  updateUnit: thunk((actions, payload, { injections }) => {
    const { unitId, data } = payload;
    return UnitSDK.update(unitId, data);
  }),
  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => { }
  ),
  cleanFilterUnit: thunk(async (actions, payload) => {
    await UnitSDK.clearFilter(payload.id);
  }),
  _storeUpdateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]) {
      _.assign(state.allUnits[payload.id], payload.data);
    }
  }),
  requestStatsRefresh: thunk(async (actions, payload) => {
    await UnitSDK.requestStatsRefresh(payload.id);
  }),
  countUnitsFor: computed(
    [(state) => state.allUnits, (state) => state.getUnitAffiliation],
    (allUnits, getUnitAffiliation) => (entityType, entityId) => Object.values(allUnits).filter((unit: any) => {
          const unitAff = getUnitAffiliation(unit.id);
          switch (entityType) {
            case "customer":
              return entityId === unitAff.customerId;
            case "site":
              return entityId === unitAff.siteId;
            case "system":
              return entityId === unitAff.systemId;
          }
        }).length
      // }, 100)

        // memo((entityType, entityId) => {
          // return

  ),
  getUnitAffiliation: computed(
    [
      (state) => state.allUnits,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices
    ],
    (allUnits, allSites, allDevices) =>
      // memo(
        (unitId: any) => {
        const aff = {} as IUnitAffiliation;
        if (unitId) {
          const unit = allUnits[unitId];
          aff.systemId = unit.system || undefined;

          const unitDevice = allDevices[unit.device];
          if (_.isUndefined(unitDevice)) return aff;
          aff.deviceId = unitDevice.id;

          const unitSite = allSites[unitDevice.site];
          if (!_.isUndefined(unitSite)) {
            aff.siteId = unitSite.id;
            aff.customerId = unitSite.customer;
          }
        }
        return aff; }
      // }, 100)
  ),
  setActiveSetpoint: thunk(async (actions, payload) => {
    UnitSDK.setActiveSetpoint(payload.id, payload.setpoint)
      .then(() => {
        actions._storeUpdateUnit({
          id: payload.id,
          data: { activeSetpoint: payload.setpoint }
        });
      });
  }),

  setActiveOperationMode: thunk(async (actions, payload) => {
    await UnitSDK.setActiveOperationMode(payload.id, payload.mode);
    actions._storeUpdateUnit({
      id: payload.id,
      data: { activeOperationMode: payload.mode }
    });
  }),

  togglePower: thunk(async (actions, payload) => {
    const toggleStates = [1, 2, 1];
    const status = toggleStates[payload.activeOperationStatus];
    await UnitSDK.setActiveOperationStatus(payload.id, status);
    actions._storeUpdateUnit({ id: payload.id, data: { activeOperationStatus: status } });
  })
};
