import { System as SystemSdk } from "coolremote-sdk";
import { GlobalAdmin } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  // memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface ISystem {
  id: string;
  name: string;
  brand?: string;
  description?: string;
  type?: string;
  series?: string;
  capacity?: number;
  capacityMeasurementUnits?: number;
  line: number;
  device: string;
  units: string[];
  role: any;
  types: any;
  capacityMeasurement: any;
  brandNum?: number;
  site: string;
  customer: string;
}

export interface ISystemMap {
  [key: string]: ISystem;
}

export interface ISystemsModel {
  allSystems: ISystemMap;
  initialize: Action<ISystemsModel, any>;
  onInitialized: ActionOn<ISystemsModel, IRootStoreModel>;
  updateSystem: Thunk<ISystemsModel, { systemId: string; updatedData: any }>;
  systemTogglePower: Thunk<ISystemsModel, { id: string; data: any }>;
  getSystems: Thunk<ISystemsModel>;
  changeSystemOperationMode: Thunk<ISystemsModel, { id: string; data: any }>;
  _storeUpdateSystem: Action<
    ISystemsModel,
    { systemId: string; updatedSystemData: any }
  >;
  getSystem: Computed<
    ISystemsModel,
    (id?: string | null) => ISystem | undefined
  >;
  getSystemFirstUnitId: Computed<
    ISystemsModel,
    (id: string, unitType: number | undefined) => string | null,
    IRootStoreModel
  >;
  getSystemById: Thunk<ISystemsModel, any>;
  deleteSystem: Thunk<ISystemsModel, any>;
  getSystemUnits: Thunk<ISystemsModel, any>;
}

export const systemsModel: ISystemsModel = {
  allSystems: {},

  initialize: action((state, payload) => {
    const newSystems: ISystemMap = _(Object.values(payload))
      .map((system: any) => {
        const newSystem: ISystem = { ...system };
        return newSystem;
      })
      .keyBy("id")
      .value();
    state.allSystems = newSystems;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log("Got systems: ", debug(state.allSystems));
    }
  ),
  getSystem: computed([(state) => state.allSystems], (allSystems) => (id) =>
  // memo((id) => {
  {
    if (_.isNil(id)) {
      return undefined;
    }
    return allSystems[id];
  }
    // }, 100)
  ),
  updateSystem: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.update(
      payload.systemId,
      payload.updatedData
    );

    actions._storeUpdateSystem({
      systemId: payload.systemId,
      updatedSystemData
    });
  }),

  systemTogglePower: thunk(async (actions, payload) => {
    await SystemSdk.changeSystemPower(payload.id, payload.data);
  }),
  getSystems: thunk(async (actions) => {
    return SystemSdk.getSystems();
  }),

  changeSystemOperationMode: thunk(async (actions, payload) => {
    await SystemSdk.changeSystemOperationMode(payload.id, payload.data);
  }),

  _storeUpdateSystem: action((state, payload) => {
    if (state.allSystems[payload.systemId]) {
      state.allSystems[payload.systemId] = payload.updatedSystemData;
    }
  }),

  getSystemFirstUnitId: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id, unitType) => {
      let systemFilteredUnits = Object.values(allUnits).filter((unit: any) => {
        // Filter out wrong typed units only if unit-type is specified.
        if (!_.isNil(unitType) && unitType !== unit.type) {
          return false;
        }

        // Filter in units with system assigned and it is the system requested.
        if (!_.isNil(unit.system) && unit.system === id) {
          return true;
        }

        // No match.
        return false;
      });

      // Return the first filtered unit's ID.
      //rand
      // if (systemFilteredUnits.length) {
      //   // Outdoor units are sorted by line ID.
      //   if (unitType === 2) {
      //     systemFilteredUnits = _.sortBy(
      //       systemFilteredUnits,
      //       unit => unit.line
      //     );
      //   }

      //   return systemFilteredUnits[0].id;
      // }
      //rand
      // No unit found.
      return null;
    }
  ),
  getSystemById: thunk(async (actions, payload) => {
    return SystemSdk.getSystemById(payload);
  }),
  deleteSystem: thunk(async (actions, payload) => {
    return SystemSdk.delete(payload);
  }),
  getSystemUnits: thunk(async (actions, payload) => {
    return GlobalAdmin.getSystemUnits(payload);
  }),

};
