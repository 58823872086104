import React from "react";
import _ from "lodash";
import { t } from "ttag";
import { IServiceTypes } from "../../models/RootStore";
import { makeFullAddressForSite, makeFullName } from "../../utils/CommonUtils";
import { ISite } from "../../models/SitesModel";

interface INameVal {
  name: string;
  value?: string;
}

const showValues = (rows: INameVal[]) => {
  return (
    <div>
      {rows.map((row, i) => (
        <div key={i}>
          {row.name}:{" "}
          <span style={{ fontWeight: 500 }}>
            {_.isUndefined(row.value) ? "-" : row.value}
          </span>
        </div>
      ))}
    </div>
  );
};

export const showAuditAction: React.FC<any> = (audit: any) => {
  const ret = auditActionMap[audit.action];
  if (!ret) return <span>{audit.action}</span>;
  return <span>{ret.name}</span>;
};

interface IAuditActionMap {
  [action: string]: {
    name: string;
    description: (params: {
      audit: any;
      types?: IServiceTypes;
    }) => JSX.Element;
  };
}

export const auditActionMap: IAuditActionMap = {
  rootCreateCustomer: {
    name: t`Created Root Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Customer`, value: audit?.data?.name || null },
        { name: t`Description`, value: audit?.data?.description }
      ])
  },
  createCustomer: {
    name: t`Created Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.name },
        { name: t`Fullname`, value: audit?.data?.description },
        {
          name: t`Email`,
          value: makeFullAddressForSite(audit.data as ISite)
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateCustomer: {
    name: t`Updated Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteCustomer: {
    name: t`Deleted Customer`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createCustomerUser: {
    name: t`Created Customer User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.name },
        { name: t`Fullname`, value: audit?.data?.description },
        {
          name: t`Email`,
          value: makeFullAddressForSite(audit.data as ISite)
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  rootCreateCustomerUser: {
    name: t`Created Root Customer User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.name },
        { name: t`Fullname`, value: audit?.data?.description },
        {
          name: t`Email`,
          value: makeFullAddressForSite(audit.data as ISite)
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createSite: {
    name: t`Created Site`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.data?.name },
        { name: t`Description`, value: audit?.data?.description },
        {
          name: t`Address`,
          value: makeFullAddressForSite(audit.data as ISite)
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateSite: {
    name: t`Updated Site`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.data?.name },
        { name: t`Description`, value: audit?.data?.description },
        {
          name: t`Address`,
          value: makeFullAddressForSite(audit.data as ISite)
        }
      ])
  },
  deleteSite: {
    name: t`Deleted Site`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.siteName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  createSiteUser: {
    name: t`Created Site User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Site`, value: audit?.data?.name },
        { name: t`Description`, value: audit?.data?.description },
        {
          name: t`Address`,
          value: makeFullAddressForSite(audit.data as ISite)
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createDevice: {
    name: t`Created Device`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.data?.name },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Customer`, value: audit?.customerName },
        { name: t`Serial`, value: audit?.data?.serial },
        { name: t`Connected`, value: audit?.data?.isConnected ? t`yes` : t`no` }
      ])
  },

  updateDevice: {
    name: t`Updated Device`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.data?.name },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Customer`, value: audit?.customerName },
        { name: t`Serial`, value: audit?.data?.serial },
        { name: t`Connected`, value: audit?.data?.isConnected ? t`yes` : t`no` }
      ])
  },

  deleteDevice: {
    name: t`Deleted Device`,
    description: ({ audit }) =>
      showValues([
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createGroup: {
    name: t`Created Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Type`, value: audit?.data?.type },
        { name: t`Description`, value: audit?.data?.description },
        { name: t`Customer`, value: audit?.customerName },
        { name: t`Site`, value: audit?.siteName }
      ])
  },
  updateGroup: {
    name: t`Updated Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteGroup: {
    name: t`Deleted Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  setGroupSetpoint: {
    name: t`Set Group Setpoint`,
    description: ({ audit }) =>
      showValues([
        { name: t`Setpoint`, value: audit?.data?.value },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  setGroupOperationStatus: {
    name: t`Set Group Operation Status`,
    description: ({ audit }) =>
      showValues([
        { name: t`Operation Status`, value: audit?.data?.value },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  setGroupOperationMode: {
    name: t`Set Group Operation Mode`,
    description: ({ audit }) =>
      showValues([
        { name: t`Operation Mode`, value: audit?.data?.value },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  setGroupFanMode: {
    name: t`Set Group Fan Mode`,
    description: ({ audit }) =>
      showValues([
        { name: t`Fan Mode`, value: audit?.data?.value },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  setGroupSwingMode: {
    name: t`Set Group Swing Mode`,
    description: ({ audit }) =>
      showValues([
        { name: t`Swing Mode`, value: audit?.data?.value },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createAlertGroup: {
    name: t`Created Alert Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateAlertGroup: {
    name: t`Updated Alert Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteAlertGroup: {
    name: t`Deleted Alert Group`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createAlertGroupUser: {
    name: t`Created Alert Group User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateAlertGroupUser: {
    name: t`Updated Alert Group User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteAlertGroupUser: {
    name: t`Deleted Alert Group User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  updateUnit: {
    name: t`Updated Unit`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteUnit: {
    name: t`Deleted Unit`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  setUnitSetpoint: {
    name: t`Set Unit Setpoint`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Setpoint`, value: audit?.data.value },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  setUnitOperationStatus: {
    name: t`Set Unit Operation Status`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        {
          name: t`Operation`,
          value: types?.operationStatuses[audit?.data.value]
        },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  setUnitOperationMode: {
    name: t`Set Unit Operation Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        {
          name: t`Operation`,
          value: types?.operationModes[audit?.data?.value]
        },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  setUnitFanMode: {
    name: t`Set Unit Fan Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Fan`, value: types?.fanModes[audit?.data?.value] },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  setUnitSwingMode: {
    name: t`Set Unit Swing Mode`,
    description: ({ audit, types }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        {
          name: t`Swing`,
          value: types ? types?.swingModes[audit?.data?.value] : ""
        },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  createSchedule: {
    name: t`Created Schedule`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateSchedule: {
    name: t`Updated Schedule`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteSchedule: {
    name: t`Deleted Schedule`,
    description: ({ audit }) =>
      showValues([
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createInvite: {
    name: t`Created Invite`,
    description: ({ audit }) =>
      showValues([
        { name: t`Customer`, value: audit?.customerName },
        { name: t`User Fullname`, value: makeFullName(audit?.data) }
      ])
  },
  acceptInvite: {
    name: t`Accepted Invite`,
    description: ({ audit }) =>
      showValues([
        { name: t`Customer`, value: audit?.customerName },
        { name: t`Site`, value: audit?.siteName }
      ])
  },
  deleteInvite: {
    name: t`Deleted Invite`,
    description: ({ audit }) =>
      showValues([
        { name: t`Customer`, value: audit?.customerName },
        { name: t`Site`, value: audit?.siteName }
      ])
  },

  updateUser: {
    name: t`Updated User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.username },
        {
          name: t`Fullname`,
          value: audit?.data ? makeFullName(audit?.data) : ""
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteUser: {
    name: t`Deleted User`,
    description: ({ audit }) =>
      showValues([
        { name: t`Username`, value: audit?.data?.name },
        {
          name: t`Fullname`,
          value: audit?.data ? makeFullName(audit?.data) : ""
        },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  updateUserPassword: {
    name: t`Updated User Password`,
    description: ({ audit }) =>
      showValues([
        { name: t`User`, value: audit?.user },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createTrigger: {
    name: t`Created Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Enabled`, value: audit?.data?.isEnabled },
        { name: t`Template`, value: audit?.data?.template },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateTrigger: {
    name: t`Updated Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteTrigger: {
    name: t`Deleted Anomaly Trigger`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },

  createTriggerTemplate: {
    name: t`Created Trigger Template`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  updateTriggerTemplate: {
    name: t`Updated Trigger Template`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  },
  deleteTriggerTemplate: {
    name: t`Deleted Trigger Template`,
    description: ({ audit }) =>
      showValues([
        { name: t`Name`, value: audit?.data?.name },
        { name: t`Unit`, value: audit?.unitName },
        { name: t`Site`, value: audit?.siteName },
        { name: t`Device`, value: audit?.deviceName },
        { name: t`Customer`, value: audit?.customerName }
      ])
  }
};
