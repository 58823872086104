import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import { FilterList } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
// @ts-ignore
import { IDeviceModel } from "../../models/DeviceModel";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DeviceManagement.style";
import EditDevice from "./EditDevice";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import ArrowIcon from "../../icons/ArrowLong";

// The main screen
export default function DeviceManager(props: any) {
  const styles: any = useStyles();

  const [selectedDevice, setSelectedDevice] = useState<IDeviceModel | null>(null);
  const [filteredDevices, setFilteredDevices] = useState<any>([]);
  const [devices, setDevices] = useState<any>([]);
  const [lookupAnchor, setAnchor] = useState(null);
  const deviceTypes = useStoreState((state) => state.types.deviceTypes);
  const { dateFormat, timeFormat } = useStoreState((state) => state.users);

  const [filters, setFilters] = useState<any>({
    versionList: [],
    deviceSerialList: [],
    siteNames: [],
    isConnected: [],
    isRegistered: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    versionList: [],
    deviceSerialList: [],
    siteNames: [],
    isConnected: [],
    isRegistered: []
  });
  const [clickedColumnName, setClickedColumnName] = useState<any>("");
  const getCustomerDevices = useStoreActions((actions) => actions.customers.getCustomerDevices);
  const getSiteDevices = useStoreActions((actions) => actions.sites.getSiteDevices);
  const selections = useStoreState((state) => state.selections.selections);
  const { siteId, customerId } = selections;
  const { addMessage } = useStoreActions((action) => action.errorMessage);

  useEffect(() => {
    if (!customerId) {
      return
    }

    if (siteId) {
      getSiteDevices(siteId)
        .then((res: any) => {
          setDevices(res)
        })
        .catch((err: any) => addMessage({ message: err.message }))
    } else {
      getCustomerDevices(customerId)
        .then((res: any) => {
          setDevices(res)
        })
        .catch((err: any) => addMessage({ message: err.message }))
    }

    const pageRoute = props.location.pathname;
    setAppliedFilters({
      versionList: [],
      deviceSerialList: [],
      siteNames: [],
      isConnected: pageRoute.includes("disconnected") ? ["not connected"] : (pageRoute.includes("connected") ? ["connected"] : []),
      isRegistered: pageRoute.includes("registered") ? ["registered"] : []
    });

  }, [customerId, siteId]);

  useEffect(() => {
    if (_.isEmpty(appliedFilters)) {
      setFilteredDevices(devices);
      return;
    }

    const filteredData = _(devices)
      .filter((device) => {
        return appliedFilters.versionList.length
          ? appliedFilters.versionList.includes(device.firmwareVersion)
          : true;
      })
      .filter((device) => {
        return appliedFilters.deviceSerialList.length
          ? appliedFilters.deviceSerialList.includes(device.serial)
          : true;
      })
      .filter((device) => {
        return appliedFilters.siteNames.length
          ? appliedFilters.siteNames.includes(device.site)
          : true;
      })
      .filter((device) => {
        const isConnected = device.isConnected ? "connected" : "not connected";

        return appliedFilters.isConnected.length
          ? appliedFilters.isConnected.includes(isConnected)
          : true;
      })
      .filter((device) => {
        const isRegistered = device.isRegistered ? "registered" : "not registered";

        return appliedFilters.isRegistered.length
          ? appliedFilters.isRegistered.includes(isRegistered)
          : true;
      })
      .value();

    setFilteredDevices(filteredData);
  }, [appliedFilters, devices]);

  useEffect(() => {
    if (_.isEmpty(devices)) {
      setFilters({
        versionList: [],
        deviceSerialList: [],
        siteNames: [],
        isConnected: [],
        isRegistered: []
      });
      return;
    }

    const versionList: any[] = [];
    const deviceSerialList: any = [];
    const siteNames: any = [];
    const isConnectedList: any = [];
    const isRegisteredList: any = [];
    Object.values(devices).map((device: any) => {
      const { firmwareVersion, serial, site } = device;
      const isConnected = device.isConnected ? "connected" : "not connected";
      const isRegistered = device.isRegistered ? "registered" : "not registered";
      if (serial && serial !== "-" && deviceSerialList.indexOf(serial) === -1) {
        deviceSerialList.push(serial);
      }

      if (firmwareVersion && firmwareVersion !== "-" && versionList.indexOf(firmwareVersion) === -1) {
        versionList.push(firmwareVersion);
      }

      if (site && site !== "-" && siteNames.indexOf(site) === -1) {
        siteNames.push(site);
      }

      if (isConnectedList.indexOf(isConnected) === -1) {
        isConnectedList.push(isConnected);
      }
      if (isRegisteredList.indexOf(isRegistered) === -1) {
        isRegisteredList.push(isRegistered);
      }
      return true;

    });
    setFilters({ versionList, deviceSerialList, siteNames, isRegistered: isRegisteredList, isConnected: isConnectedList });

  }, [filteredDevices, devices, appliedFilters]);


  if (selectedDevice) {
    return (
      <EditDevice
        onClose={() => setSelectedDevice(null)}
        selectedDevice={selectedDevice}
        {...props}
      />
    );
  }
  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;



  return (
    <>
      <ServiceNavigationBar title={t`Device Management`}
        filters={<SelectionsMenu hideUnitSelection hideSystemSelection onSiteSelect={() => { }} />}
        {...props}
      >
        {!selections.customerId ? (
          <Grid container direction={"column"} className={styles.noContentContainer}>
            <div className={styles.grayOval}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
            <Typography>
              {t`Please select a customer using the above filters.`}
            </Typography>
          </Grid>
        ) : (
          <Paper elevation={0} className={styles.paperPadding}>
            <TableContainer>
              <Table className="" aria-label="customized table">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell
                      className={styles.headCells}
                      align="left"
                      onClick={(e: any) => handleFilterPopup(e, "deviceSerialList")}
                    >
                      <div className={styles.headContainer}>
                        {t`DEVICE SERIAL`}
                        <FilterList
                          className={clsx(styles.filterStyle, {
                            [styles.blueFilter]: appliedFilters.deviceSerialList.length
                          })}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={styles.headCells}
                      align="left"
                    >
                      <div className={styles.headContainer}>
                        {t`DEVICE TYPE`}
                      </div>
                    </TableCell>
                    <TableCell
                      className={styles.headCells}
                      align="left"
                      onClick={(e: any) => handleFilterPopup(e, "siteNames")}
                    > <div className={styles.headContainer}>
                        {t`SITE`}
                        <FilterList
                          className={clsx(styles.filterStyle, {
                            [styles.blueFilter]: appliedFilters.siteNames.length
                          })}
                        />
                      </div></TableCell>
                    <TableCell
                      className={styles.headCells}
                      align="center"
                      onClick={(e: any) => handleFilterPopup(e, "versionList")}

                    ><div className={styles.headContainer}>
                        {t`Firmware Version`}
                        <FilterList
                          className={clsx(styles.filterStyle, {
                            [styles.blueFilter]: appliedFilters.versionList.length
                          })}
                        />
                      </div></TableCell>
                    <TableCell
                      className={styles.headCells}
                      align="center"

                    >
                      {t`Disconnected Date`}
                    </TableCell>
                    <TableCell
                      className={styles.headCells}
                      align="center"
                      onClick={(e: any) => handleFilterPopup(e, "isConnected")}

                    >
                      <div className={styles.headContainer}>
                        {t`CONNECTED`}
                        <FilterList
                          className={clsx(styles.filterStyle, {
                            [styles.blueFilter]: appliedFilters.isConnected.length
                          })}
                        />
                      </div></TableCell>
                    <TableCell
                      className={styles.headCells}
                      align="center"
                      onClick={(e: any) => handleFilterPopup(e, "isRegistered")}

                    >
                      <div className={styles.headContainer}>
                        {t`REGISTERED`}
                        <FilterList
                          className={clsx(styles.filterStyle, {
                            [styles.blueFilter]: appliedFilters.isRegistered.length
                          })}
                        />
                      </div></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDevices.map((device: IDeviceModel) => {
                    return (
                      <TableRow hover tabIndex={-1} key={device.id} onDoubleClick={() => {
                        setSelectedDevice(device);
                        props.history.push(`/devices/${device.id}`);
                      }}>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {device.serial}
                        </TableCell>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {device?.deviceType ? deviceTypes[device?.deviceType]?.shortName : ""}
                        </TableCell>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {device.site}
                        </TableCell>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {device.firmwareVersion}
                        </TableCell>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {device.disconnectedTimestamp ? moment(device.disconnectedTimestamp).format(`${dateFormat} ${timeFormat}`) : ""}
                        </TableCell>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {device.isConnected ? (
                            <i className="fas fa-check"></i>
                          ) : (
                            <i className="fas fa-times"></i>
                          )}
                        </TableCell>
                        <TableCell
                          className={styles.rowCell}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {device.isRegistered ? (
                            <i className="fas fa-check"></i>
                          ) : (
                            <i className="fas fa-times"></i>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {clickedColumnName && (
          <Lookup
            filtersList={filters[clickedColumnName]}
            appliedFilters={appliedFilters[clickedColumnName]}
            onApply={onApply}
            lookupAnchor={lookupAnchor}
            onClose={() => setClickedColumnName("")}
            clearAllFilters={() => setAppliedFilters({
              versionList: [],
              deviceSerialList: [],
              siteNames: [],
              isConnected: [],
              isRegistered: []
            })}
            hasFilters={hasFilters}
          />
        )}
      </ServiceNavigationBar>

    </>
  );
}
