import { makeStyles } from "@mui/styles";

const styles: any = makeStyles({
  menuStyle: {

    "& ul": {
      display: "flex",
      flexFlow: "column nowrap",
      padding: "8px",
      boxSizing: "border-box"
    }
  },
  menuHeader: {
    // width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "9px",
    marginBottom: "10px"
  },
  menuItemStyle: { padding: 0, width: "100%" },
  applyBtn: { margin: "15px" },
  optionsContainer: { width: "100%", maxHeight: "150px", overflow: "auto" },
  textField: {
    marginRight: "14px",
    marginLeft: "5px",
    "& div": { height: "40px", marginBottom: "5px" }
  },
  optionStyle: { minHeight: "37px" },
  optionTextStyle: {
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  },
  displayMoreOptions: {
    "& hover": {
      backgroundColor: "unset"
    }
  }
});

export const paperStyle: any = {
  marginTop: "43px",
  borderRadius: "6px",
  boxShadow: "rgba(0, 0, 0, 0.46) 0px 6px 17px 3px",
  backgroundColor: "#FFF",
  padding: "5px",
  minWidth: "260px",
  maxWidth: "200px",
  minHeight: "150px",
  overflow: "hidden"
};
export default styles;
