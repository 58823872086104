import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px",
  },

  searchIcon: {
    transform: "scale(0.7)",
  },

  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "200px",
  },

  tableHead: {
    height: "45px",
    backgroundColor: "rgb(239, 239, 242, 0.48)",
  },

  headCells: {
    border: "none",
    padding: "0 15px",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    fontFamily: theme.tables.headerCell.fontFamily,
    height: 45,
    fontSize: 13,
  },

  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: theme.tables.tableCell.padding,
    fontFamily: theme.tables.tableCell.fontFamily,
  },

  rowCellHyperLink: {
    color: "blue",
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding,
    textDecoration: "underline",
    cursor: "pointer",
  },

  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    backgroundImage: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0,
  },
  ExportButtonStyle: {
    height: "40px",
    borderRadius: "4px",
    backgroundImage: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    marginRight: 5,
    marginBottom: "15px",
    padding: '0 10px',
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  arrowBackIcon: {
    fontSize: "40px",
    color: theme.palette.primary.main,
  },
}));
export default useStyles;
