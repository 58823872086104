import {
    Button,
    TextField
} from "@mui/material";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import useStyles from "./Device.style";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";

interface IDevice {
    startSerial: string;
    numOfDevices: number;
    deviceFamily: string;
}

const DeviceSchema = Yup.object().shape({
    numOfDevices: Yup.number().required(t`Required`),
    deviceFamily: Yup.string().required(t`Required`),
    startSerial: Yup.string().required(t`Required`),
})

export default function CreateRecord(props: any) {
    const { onCancel, onApply, families } = props;
    const styles: any = useStyles();
    const [error, setError] = useState<any>(null);

    const familiesOptions: any = Object.values(families).map((family: any) => {
        return {
            value: family.id,
            label: family.name,
            disabled: family.name === "unknown"
        };
    });

    const onSubmit = (values: any) => {
        const data: IDevice = {
            ...values,
            productionDate: new Date().getTime(),
        }
        sdkGlobalAdmin.createDeviceRecords(data)
            .catch((err: any) => { setError(err) })
            .finally(onApply)
    }

    return (
        <div>
            <SimpleModal
                showModal={!!error}
                title={t`Error had happened`}
                contentText={error ? error : ""}
                actionName={t`OK`}
                onAction={() => {
                    setError(null);
                }}
                closeModal={() => {
                    setError(null);
                }}
            />

            <Formik
                initialValues={{
                    numOfDevices: 1,
                }}
                enableReinitialize={true}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={DeviceSchema}
                autoComplete="off"
            >
                {(props) => {
                    const { values, errors, handleChange, handleSubmit, setFieldValue }: any = props;
                    return (
                        <form className={styles.fieldsContainer} onSubmit={handleSubmit}>

                            <div className={styles.twoFieldsRow}>

                                <TextField
                                    label={t`start Serial`}
                                    id="startSerial"
                                    helperText={errors.startSerial}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.startSerial || ""}
                                    error={errors.startSerial}
                                    {...props}
                                />
                                <TextField
                                    label={t`num Of Devices`}
                                    id="numOfDevices"
                                    helperText={errors.numOfDevices}
                                    className={styles.smallField}
                                    variant={'standard'}
                                    onChange={handleChange}
                                    value={values.numOfDevices || ""}
                                    error={errors.numOfDevices}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    {...props}
                                />
                            </div>

                            <div className={styles.twoFieldsRow}>
                                <AdvancedInputField
                                    select
                                    name="deviceFamily"
                                    options={familiesOptions}
                                    label={t`Device Family`}
                                    editable={true}
                                    {...props}
                                />

                            </div>
                            <div className={styles.actionsContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onCancel}
                                    className={styles.cancelBtn}
                                >
                                    {t`Cancel`}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={styles.submitButton}
                                >
                                    {t`Create`}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
}
