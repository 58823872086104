import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
    dialogStyle: { width: 500, height: 250, padding: 10 },
    view: {
        minWidth: "400px",
        "& p": {
            fontSize: "18px"
        }
    },
    checkbox: {
        marginRight: "5px"
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: "center"
    },
    selectContainer: {
        marginTop: "10px"
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));
export default useStyles;
