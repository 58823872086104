
import { GlobalAdmin as sdkAdmin } from "coolremote-sdk";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import moment from "moment-timezone";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LineChart, Line } from 'recharts';
import { Checkbox, Typography } from "@mui/material";
import { differenceInDays } from "date-fns";
import styles from "./Dashboard.module.css";

export default function Dashboard(props: any) {
  const history = useHistory();
  const [stats, setStats] = useState<any>([]);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const selections = useStoreState((state) => state.selections.selections);

  const [visibleLines, setVisibleLines] = useState<any>([
    "numberOfRegisteredNotConnectedDevices",
    "numberOfRegisteredConnectedDevices",
    "numberOfRegisteredDevices",
    "numberOfConnectedDevices",
    "numberOfDevices",
    "numberOfProfSites",
    "numberOfBaseSites",
    "numberOfSites",
    "numberOfCustomers"
  ]);

  useEffect(() => {
    if (!!selections?.dateRange?.endDate && !!selections?.dateRange?.startDate) {
      return;
    }
    const range = {
      startDate: new Date(moment().subtract(1, 'months').format()),
      endDate: new Date()
    }
    setSelections({ dateRange: range });
  }, []);

  useEffect(() => {
    if (selections?.dateRange?.endDate && selections?.dateRange?.startDate) {
      const startTime = new Date(selections.dateRange.startDate).getTime();
      const endTime = new Date(selections.dateRange.endDate).getTime();
      sdkAdmin.getGeneralStats({ startTimeUTC: startTime, endTimeUTC: endTime })
        .then((resp: any) => {
          setStats(resp);
        });
    }
  }, [selections.dateRange]);

  const goTo = (path: string) => {
    switch (path) {
      case "numberOfSites":
      case "numberOfBaseSites":
      case "numberOfProfSites":
        history.push("/sites");
        break;
      case "numberOfDevices":
      case "numberOfConnectedDevices":
      case "numberOfRegisteredDevices":
      case "numberOfRegisteredConnectedDevices":
      case "numberOfRegisteredNotConnectedDevices":
        history.push("/devices");
        break;

      case "numberOfCustomers":
        history.push("/customers");
        break;
      default:
        break;
    }
  };


  const translatedLabel: any = {
    numberOfCustomers: t`Customers`,
    numberOfSites: t`Sites`,
    numberOfBaseSites: t`Base sites`,
    numberOfProfSites: t`Prof sites`,
    numberOfDevices: t`Devices`,
    numberOfConnectedDevices: t`Connected devices`,
    numberOfRegisteredDevices: t`Registered devices`,
    numberOfRegisteredConnectedDevices: t`Registered connected devices`,
    numberOfRegisteredNotConnectedDevices: t`Registered not connected devices`,
  }

  const daysBetween = (current: Date) => {
    if (selections?.dateRange?.startDate) {
      return differenceInDays(current, selections?.dateRange?.startDate)
    }
  }

  const isVisible = (key: string) => {
    return visibleLines.includes(key)
  }

  const CustomTooltip = (props: any) => {
    const { payload, label } = props;
    if (!payload || !payload[0]) {
      return null;
    }
    const numOfDays = daysBetween(label);
    return (
      <div className={styles.tooltipbox}>
        <Typography className="label">
          <strong>{`Date/Time: ${moment.utc(label).format("llll")}`}</strong>
        </Typography>
        <div className={styles.tooltipContainer}>
          {
            payload.map((param: any, index: number) => {
              const { name, value, unit: enums, fill } = param;
              return isVisible(name) && <div className={styles.tooltipHolder} key={`Tooltip - ${index}`}>
                <div className={styles.tooltipColor} style={{ backgroundColor: fill }}></div>
                <Typography className={styles.tooltipName}>{translatedLabel[name]}: {!enums ? value : enums[value]} ({numOfDays} {t`Days`})</Typography>
              </div>
            })
          }
        </div>
      </div>
    );
  }

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload: { value } } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} fill="#666" fontSize={"13px"}>
          <tspan textAnchor={"middle"} x={0} y={3} dy={20} fontSize={"13px"} fontFamily="Roboto">
            {moment.utc(value).format("DD/MMM")}
          </tspan>
        </text>
      </g>
    );
  }

  const handleCheck = (event: any) => {
    const arr = Array.from(visibleLines);
    const index = arr.indexOf(event.target.name);
    if (index !== -1) {
      arr.splice(index, 1); // Remove the string
    } else {
      arr.push(event.target.name); // Add the string
    }
    setVisibleLines(arr);
  };

  const CustomizedLegend = ({ payload }: any) => {
    return (
      <div className={styles.legendsContainer}>
        {payload.map((legend: any, index: any) =>
          <div className={styles.legendHolder} key={`legend - ${index}`}>
            <Checkbox className={styles.legendColor} style={{ color: legend?.payload?.fill || "white" }} checked={isVisible(legend.dataKey)} onChange={handleCheck} name={legend.dataKey} />
            <span className={styles.legendName} onClick={() => goTo(legend.dataKey)} >{translatedLabel[legend.dataKey]}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <ServiceNavigationBar
      filters={<SelectionsMenu showDateRangePicker hideCustomerSelection hideSiteSelection hideUnitSelection hideSystemSelection />}
      {...props}
    >
      <div className={styles.dashboardContainer}>
        <ResponsiveContainer width={"50%"} height={400}>
          <LineChart
            data={stats}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tick={<CustomXAxisTick />}
            />
            <YAxis />
            <Legend
              content={<CustomizedLegend />}
            />
            <Tooltip
              content={<CustomTooltip />}
            />
            <Line hide={!isVisible("numberOfCustomers")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfCustomers" stroke="#53bced" fill="#53bced" />
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer width={"50%"} height={400}>
          <LineChart
            data={stats}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tick={<CustomXAxisTick />}
            />
            <YAxis />
            <Legend
              content={<CustomizedLegend />}
            />
            <Tooltip
              content={<CustomTooltip />}
            />
            <Line hide={!isVisible("numberOfSites")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfSites" stroke="#8e20f5" fill="#8e20f5" />
            <Line hide={!isVisible("numberOfBaseSites")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfBaseSites" stroke="#a54bfa" fill="#a54bfa" />
            <Line hide={!isVisible("numberOfProfSites")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfProfSites" stroke="#b98ae6" fill="#b98ae6" />
          </LineChart>
        </ResponsiveContainer>
        <ResponsiveContainer width={"100%"} height={400}>
          <LineChart
            data={stats}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tick={<CustomXAxisTick />}
            />
            <YAxis />
            <Legend
              content={<CustomizedLegend />}
            />
            <Tooltip
              content={<CustomTooltip />}
            />
            <Line hide={!isVisible("numberOfDevices")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfDevices" stroke="#c0eb3f" fill="#c0eb3f" />
            <Line hide={!isVisible("numberOfConnectedDevices")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfConnectedDevices" stroke="#a2fa8e" fill="#a2fa8e" />
            <Line hide={!isVisible("numberOfRegisteredDevices")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfRegisteredDevices" stroke="#51f5b3" fill="#51f5b3" />
            <Line hide={!isVisible("numberOfRegisteredConnectedDevices")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfRegisteredConnectedDevices" stroke="#349941" fill="#349941" />
            <Line hide={!isVisible("numberOfRegisteredNotConnectedDevices")} strokeWidth={2} type="monotone" dot={false} dataKey="numberOfRegisteredNotConnectedDevices" stroke="#f7c072" fill="#f7c072" />
          </LineChart>
        </ResponsiveContainer>
      </div>

    </ServiceNavigationBar >
  );
}
