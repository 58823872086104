import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    SelectChangeEvent,
    CircularProgress
} from "@mui/material";
import { t } from "ttag";
import { filter } from "lodash";
import useStyles from "./ExportPopup.style";
import Button from "../../../cool_widgets/Button";
import Checkbox from "../../../cool_widgets/CoolCheckbox";
import { saveAs } from "file-saver";
import { useStoreActions } from "../../../models/RootStore";
const ExcelJS = require('exceljs');

/**
  * Exports user data to an Excel file.
  * 
  * @param {string} fileName - The name of the file to be created.
  * @param {Array<Object>} result - The array of user data objects to be exported.
  * @param {string} result[].name - The name of the user.
  * @param {string} result[].email - The email of the user.
  * @param {string} result[].phoneNumber - The phone number of the user.
  * @param {string} result[].description - The description of the user.
  * @param {number} result[].numOfUsers - The number of users.
  * @param {number} result[].numOfSites - The number of sites.
  * @param {number} result[].numOfDevices - The number of devices.
  * @returns A promise that resolves when the Excel file is created and saved.
*/
const exportUserDataToExcel = async (fileName: string, result: any[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    worksheet.columns = [
        { header: 'Name', key: 'name', width: 30 },
        { header: 'Email', key: 'email', width: 30 },
        { header: 'Phone Number', key: 'phoneNumber', width: 30 },
        { header: 'Description', key: 'description', width: 30 },
        { header: 'Number Of Users', key: 'numOfUsers', width: 30 },
        { header: 'Number Of Sites', key: 'numOfSites', width: 30 },
        { header: 'Number Of Devices', key: 'numOfDevices', width: 30 },
    ];
    result.forEach((customer: any) => {
        worksheet.addRow(customer)
    });
    const buffer = await workbook["xlsx"].writeBuffer();
    const blob = new Blob([buffer], { type: "applicationi/xlsx" });
    saveAs(blob, `${fileName}.xlsx`);
}

/**
  * Represents an option for an admin.
  * 
  * @typedef {Object} AdminOption
  * @property {string} value - The value of the option.
  * @property {string} label - The label of the option.
*/
type AdminOption = {
    value: string;
    label: string;
};

/**
  * Props for the ExportPopup component.
*/
interface ExportPopupProps {
    open: boolean; // Indicates whether the dialog is open
    onClose: () => void; // Function to handle dialog close event
}

/**
  * Component for exporting user data.
*/
const ExportPopup: React.FC<ExportPopupProps> = ({ open, onClose }) => {
    const classes: any = useStyles();
    const getCustomersByCustomerName = useStoreActions((actions) => actions.customers.getCustomersByCustomerName);
    const { addMessage } = useStoreActions((action) => action.errorMessage);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isExportInProgress, setIsExportInProgress] = useState(false);
    const getAllAdminUsers = useStoreActions((action) => action.adminUsers.getAllAdminUsers);
    const [csOptions, setCsOptions] = useState<AdminOption[]>([]);

    useEffect(() => {
        const fetchAdminUsers = async () => {
            try {
                const res: any = await getAllAdminUsers();
                setCsOptions(Object.entries(res).map(([userId, { firstName = "", lastName = "" }]: any) => ({
                    value: userId,
                    label: `${firstName} ${lastName}`
                })));
            } catch (error) {
                console.error("Error fetching admin users:", error);
            }
        };
        fetchAdminUsers();
    }, []);

    /**
      * Handles change event of the Select component.
      * @param {SelectChangeEvent<string>} event - The change event
    */
    const handleSelectionChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value as string);
    };

    /**
      * Handles change event of the Checkbox component.
    */
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    /**
      * Handles form submission.
    */
    const handleSubmit = () => {
        if (!isChecked) {
            handleExport();
            return;
        }
        const ownerCS = csOptions.find(option => option.value === selectedOption);
        handleExport(ownerCS);
    };

    /**
     * Handles the export of user data to an Excel file.
     * 
     * @param {AdminOption | null} selectedCS - The selected customer service.
     * @returns A promise that resolves when the export process is completed.
    */
    const handleExport = async (selectedCS?: AdminOption) => {
        setIsExportInProgress(true);
        try {
            const allCustomers = await getCustomersByCustomerName();
            const allCustomersArray = Object.values(allCustomers);
            if (selectedCS) {
                const filteredCustomers = filter(allCustomersArray, { ownerCS: selectedCS.value });
                await exportUserDataToExcel(`Customers - ${selectedCS.label}`, filteredCustomers);
            } else {
                await exportUserDataToExcel('All Customers', allCustomersArray);
            }
        } catch (error: any) {
            addMessage({ message: `Error exporting data: ${error.message}` });
        } finally {
            setIsExportInProgress(false);
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
            classes={{ paper: classes.dialogStyle }}
        >
            <DialogTitle id="form-dialog-title">{t`Export customers`}</DialogTitle>
            <DialogContent className={classes.view}>
                <div className={classes.checkboxContainer}>
                    <Checkbox
                        className={classes.checkbox}
                        color="default"
                        edge="end"
                        onClick={handleCheckboxChange}
                        checked={isChecked}
                    />
                    <span>{t`Filter by owner CS`}</span>
                </div>
                {isChecked && <div className={classes.selectContainer}>
                    <FormControl fullWidth>
                        <InputLabel id="admin-select-label">{t`Select the owner cs`}</InputLabel>
                        <Select
                            labelId="admin-select-label"
                            value={selectedOption}
                            onChange={handleSelectionChange}
                            label={t`Select the owner cs`}
                        >
                            {csOptions.map((option: AdminOption) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>}
            </DialogContent>
            {isExportInProgress ? <div className={classes.loader} >< CircularProgress /></div>
                : <DialogActions>
                    <Button onClick={onClose} width={130} style={{ marginRight: "20px" }} white uppercase>
                        {t`Cancel`}
                    </Button>
                    <Button disabled={isChecked && !selectedOption} onClick={handleSubmit} width={130} uppercase>
                        {t`Submit`}
                    </Button>
                </DialogActions>}
        </Dialog>
    );
};

export default ExportPopup;
