import {
  Button,
  Checkbox,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField
} from "@mui/material";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { menuOptionsNames } from "../../components";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import useStyles from "./AddEditAdminUser.style";

export const AdminUserSchema = Yup.object().shape({
  firstName: Yup.string().required(t`Required`).max(20, t`Too long!`),
  lastName: Yup.string().required(t`Required`).max(20, t`Too long!`),
  email: Yup.string().email(t`Invalid email`).required(t`Required`)
});

export const NewAdminUserSchema = Yup.object().shape({
  firstName: Yup.string().required(t`Required`).max(20, t`Too long!`),
  lastName: Yup.string().required(t`Required`).max(20, t`Too long!`),
  email: Yup.string().email(t`Invalid email`).required(t`Required`),
  username: Yup.string()
    .min(6, t`Too short!`)
    .max(50, t`Too long!`)
    .required(t`Required`),
  password: Yup.string()
    .matches(
      /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      t`must be at least 8+ characters, 1 capital, 1 number`
    )
    .required(t`Required`)
});

const AddEditAdminUser = (props: any) => {

  const { isEdit, adminUser, setOpenAddEdit, AdminUsers, setAdminUsers } = props;
  const styles: any = useStyles();
  const createAdminUser = useStoreActions((action) => action.adminUsers.createAdminUser);
  const updateUserAdmin = useStoreActions((action) => action.adminUsers.updateUserAdmin);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const regionTypes = useStoreState((s) => s.types.regionTypes);
  const [supportedRegions, setSupportedRegions] = useState<any>("all");
  const [supportedPages, setSupportedPages] = useState<any>("all");

  useEffect(() => {
    if (!isEdit) {
      return;
    }
    if (isEdit && !adminUser.options) {
      return;
    }

    let regions: any;
    let pages: any;

    if (Array.isArray(adminUser?.options?.regions)) {
      regions = adminUser?.options?.regions.reduce((vals: any, item: any) => {
        return { ...vals, [item]: true };
      }, {});
    } else {
      regions = adminUser?.options?.regions;
    }

    if (Array.isArray(adminUser?.options?.pages)) {
      pages = adminUser?.options?.pages.reduce((vals: any, item: any) => {
        return { ...vals, [item.toLowerCase()]: true };
      }, {});
    } else {
      pages = adminUser?.options?.pages;
    }

    setSupportedPages(pages);
    setSupportedRegions(regions);
  }, []);

  const CustomizedTextField = ({
    label,
    handleChange,
    errors,
    name,
    helperText,
    inputProps,
    values,
    small
  }: any) => (
    <TextField
      label={label}
      name={name}
      helperText={errors[name] || helperText}
      className={clsx(styles.fieldStyle, { [styles.smallField]: small })}
      variant={'standard'}
      onChange={handleChange}
      value={values[name] || ""}
      error={errors[name]}
      {...props}
      inputProps={inputProps}
    />
  );

  const handleUpdate = (values: any) => {
    delete values.password;
    delete values.username;
    const options = {
      pages: supportedPages === "all" ? "all" : Object.keys(supportedPages).filter((page: any) => supportedPages[page]),
      regions: supportedRegions === "all" ? "all" : Object.keys(supportedRegions).filter((region: any) => supportedRegions[region]).map(Number)
    };
    values.options = options;
    updateUserAdmin({ id: adminUser.id, data: values })
      .then((res: any) => {
        setAdminUsers({ ...AdminUsers, [adminUser.id]: res });
        setOpenAddEdit(null);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };

  const handleCreate = (values: any) => {
    let data: any = values;
    const options = {
      pages: supportedPages === "all" ? "all" : Object.keys(supportedPages).filter((page: any) => supportedPages[page]),
      regions: supportedRegions === "all" ? "all" : Object.keys(supportedRegions).filter((region: any) => supportedRegions[region]).map(Number)
    };
    values.options = options;
    createAdminUser(data)
      .then((res: any) => {
        setAdminUsers({ ...AdminUsers, [res.id]: res });
        setOpenAddEdit(null);
      })
      .catch((err: any) => {
        addMessage({ message: err.message });
      });
  };

  const supportedPagesChange = (id: string, checked: any) => {
    if (supportedPages === "all") {
      setSupportedPages({ [id]: !checked });
    } else {
      setSupportedPages({ ...supportedPages, [id]: checked });
    }
  };

  const supportedRegionsChange = (id: string, checked: boolean) => {
    if (supportedRegions === "all") {
      setSupportedRegions({ [id]: !checked });
    } else {
      setSupportedRegions({ ...supportedRegions, [id]: checked });
    }
  };

  return (
    <SimpleModal showModal={true} title={isEdit ? t`Edit Admin User` : t`Create Admin User`} hideBtns>
      <Formik
        initialValues={{
          firstName: isEdit ? adminUser.firstName : "",
          lastName: isEdit ? adminUser.lastName : "",
          email: isEdit ? adminUser.email : "",
          username: isEdit ? adminUser.username : "",
          password: ""
        }}
        enableReinitialize={true}
        // validateOnBlur={false}
        validateOnChange={false}
        onSubmit={isEdit ? handleUpdate : handleCreate}
        validationSchema={isEdit ? AdminUserSchema : NewAdminUserSchema}
      >
        {(props: any) => {
          const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props;
          return (
            <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
              <div className={styles.twoFieldsRow}>
                <CustomizedTextField
                  label={t`First name`}
                  name="firstName"
                  helperText={t`up to 20 characters`}
                  inputProps={{ maxLength: 20 }}
                  small
                  {...props}
                />
                <CustomizedTextField
                  label={t`Last name`}
                  name="lastName"
                  helperText={t`up to 20 characters`}
                  inputProps={{ maxLength: 20 }}
                  small
                  {...props}
                />
              </div>
              <CustomizedTextField
                label={t`Email Address`}
                name="email"
                helperText={t`valid email address`}
                {...props}
              />
              {!isEdit && <CustomizedTextField
                label={t`Username`}
                name="username"
                helperText={t`6-20 characters, must be unique`}
                {...props}
              />}
              {!isEdit && <CustomizedTextField
                label={t`Password`}
                name="password"
                helperText={t`8+ characters, 1 capital, 1 number`}
                {...props}
              />}
              <div className={styles.optionsHolder}>
                <div>
                  <InputLabel className={styles.fieldLabel}>{t`Pages supported`}</InputLabel>
                  <List id={`pages`} disablePadding={true} className={styles.filterPopupList}>
                    <ListItem key={"all"} disableGutters dense autoFocus>
                      <Checkbox
                        onClick={() => supportedPages === "all" ? setSupportedPages({}) : setSupportedPages("all")}
                        color="default"
                        checked={supportedPages === "all"}
                        classes={{ root: styles.grayCheckbox }}
                      />
                      <ListItemText id={`pages-all`} primary={t`All`} />
                    </ListItem>
                    {menuOptionsNames.map((page: any) => {
                      return (
                        <ListItem key={page.id} disableGutters dense autoFocus >
                          <Checkbox
                            onClick={(event: any) => supportedPagesChange(page.id, event.target.checked)}
                            color="default"
                            edge="end"
                            checked={!!supportedPages[page.id] || supportedPages === "all"}
                            inputProps={{ "aria-labelledby": page.label }}
                            classes={{ root: styles.grayCheckbox }}
                          />
                          <ListItemText id={page.id} primary={page.label} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>

                <div>
                  <InputLabel className={styles.fieldLabel}>{t`Regions supported`}</InputLabel>
                  <List id={`regions`} disablePadding={true} className={styles.filterPopupList}>
                    <ListItem key={"all"} disableGutters dense autoFocus >
                      <Checkbox
                        onClick={() => supportedRegions === "all" ? setSupportedRegions({}) : setSupportedRegions("all")}
                        color="default"
                        checked={supportedRegions === "all"}
                        classes={{ root: styles.grayCheckbox }}
                      />
                      <ListItemText id={`regions-all`} primary={t`All`} />
                    </ListItem>
                    {Object.keys(regionTypes).map((region: any) => {
                      return (
                        <ListItem key={regionTypes[region]} disableGutters dense autoFocus >
                          <Checkbox
                            onClick={(event: any) => supportedRegionsChange(regionTypes[region], event.target.checked)}
                            color="default"
                            edge="end"
                            checked={!!supportedRegions[regionTypes[region]] || supportedRegions === "all"}
                            inputProps={{ "aria-labelledby": region }}
                            classes={{ root: styles.grayCheckbox }}
                          />
                          <ListItemText id={regionTypes[region]} primary={region} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
              <div className={styles.actionsContainer}>
                <Button onClick={() => setOpenAddEdit(null)} className={styles.sharedButtonStyle}>
                  {t`Cancel`}
                </Button>
                <Button
                  type="submit"
                  className={clsx(
                    styles.sharedButtonStyle,
                    styles.submitButton
                  )}
                >
                  {isEdit ? t`Update` : t`Create`}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>

    </SimpleModal>
  );
};

export default AddEditAdminUser;
