import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: theme.dialogs.title.color
  },
  dialog: {
    borderRadius: "0.5rem",
    boxShadow: theme.dialogs.paper.boxShadow,
    border: theme.dialogs.border
  },
  fullWidth: {
    width: "450px"
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    marginTop: "15px"
  },
  requiredError: {
    color: "#FF0000",
    marginTop: "-9px",
    height: "20px",
    margin: "-9px 0 1px 0px"
  },
  cancelBtn: {
    backgroundColor: "#ffffff",
    width: "100px",
    marginRight: "20px"
  }
}));
export default useStyles;
