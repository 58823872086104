import { sub } from "date-fns";
import { Paper } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../cool_widgets/Button";
import { IDateRange } from "../../models/Selections";
import useStyles from "./CoolDateRangePicker.style";
import { DateRange as DateRangePicker } from 'react-date-range';

type IHumanDateRange = "last hour" | "last 24 hours" | "last 7 days" | "last 30 days" | "last year";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export interface ICoolDateRangePickerProps {
  initialRange: IDateRange | IHumanDateRange | null;
  handleSubmit: (range: IDateRange | null) => void;
  options?: ICoolDateRangePickerOptions;
  customRange?: string;
  clearCustomRange?: any;
  onlyPastAllowed?: boolean;
  minDate?: any;
  noRangeLimit?: boolean;
}

export interface ICoolDateRangePickerOptions {
  classNameFor?: string;
}
const defaultCoolDateRangePickerOptions = {};

const getRangeFromHDR = (hdr: string) => {
  const range = { startDate: new Date(), endDate: new Date(), key: "selection" };
  switch (hdr) {
    case "last hour":
      range.startDate = new Date(new Date().getTime() - 1000 * 60 * 60);
      range.endDate = new Date();
      break;
    case "last 24 hours":
      range.startDate = new Date(new Date().setHours(0, 0, 0) - 24 * 60 * 60 * 1000);
      range.endDate = new Date();
      break;
    case "last 7 days":
      range.startDate = new Date(new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000);
      range.endDate = new Date();
      break;
    case "last 30 days":
      range.startDate = new Date(new Date().setHours(0, 0, 0) - 30 * 24 * 60 * 60 * 1000);
      range.endDate = new Date();
      break;
    case "last year":
      range.startDate = new Date(new Date().setHours(0, 0, 0) - 364 * 24 * 60 * 60 * 1000);
      range.endDate = new Date();
      break;
  }

  return [range];
};

export default function CoolDateRangePicker({
  initialRange,
  handleSubmit,
  options,
  customRange = "",
  clearCustomRange,
  onlyPastAllowed,
  minDate,
  noRangeLimit = false
}: ICoolDateRangePickerProps) {
  const classes = useStyles();
  const [range, setRange] = useState<any>(
    initialRange === null
      ? getRangeFromHDR("last 24 hours")
      : typeof initialRange === "string"
        ? getRangeFromHDR(initialRange)
        : [{ startDate: initialRange.startDate, endDate: initialRange.endDate, key: "selection" }]
  );

  options = { ...defaultCoolDateRangePickerOptions, ...options };

  useEffect(() => {
    if (!customRange) {
      return;
    }

    const newRange = getRangeFromHDR(customRange);
    setRange(newRange);

  }, [customRange]);


  const handleUserSubmit = () => {
    const dataRange = range[0];
    // Make sure the range conver whole days
    handleSubmit({
      startDate: (dataRange && dataRange.startDate) ? new Date(new Date(dataRange.startDate.getTime()).setHours(0, 0, 0)) :
        new Date(dataRange.startDate as Date),
      endDate: (dataRange && dataRange.endDate) ? new Date(new Date(dataRange.endDate?.getTime()).setHours(23, 59, 59)) :
        new Date(dataRange.endDate as Date)
    });
  };


  return (
    <Paper className={classes.drpContainer}>
      <DateRangePicker
        rangeColors={"#421A40"}
        color={"#421A40"}
        editableDateInputs={false}
        onChange={(item: any) => {
          setRange([item.selection]);
          if (customRange && clearCustomRange) {
            clearCustomRange();
          }
        }}
        moveRangeOnFirstSelection={true}
        showMonthAndYearPickers={false}
        ranges={range}
        maxDate={onlyPastAllowed ? sub(new Date(), { days: 1 }) : noRangeLimit ? new Date("2999-01-01") : new Date()}
        minDate={minDate || ""}
      />
      <div className={classes.drpButtons}>
        <Button marginRight white width={90} onClick={() => handleSubmit(null)}>{t`CANCEL`}</Button>
        <Button width={70} onClick={handleUserSubmit}>{t`OK`}</Button>
      </div>
    </Paper>
  );
}