import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  IconButton,
  CircularProgress,
  Button
} from "@mui/material";
import { Search, ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./Customers.style";
import { ICustomerModel } from "../../models/CustomerModel";
import CustomSiteManagement from "./CustomSiteManagement";
import CustomEditCustomer from "./EditCustomer";
import CustomUserManagement from "./CustomUserManagement";
import CustomDeviceManager from "./CustomDeviceManagement";
import AsyncSelect from "../../widgets/AsyncSelect/AsyncSelect";
import { DeleteIcon } from "../../iconImages";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import { useHistory } from "react-router";
import ExportPopup from "./ExportPopup";

export default function Customers(props: any) {
  const styles: any = useStyles();
  const history = useHistory();
  const [filteredCustomers, setfilteredCustomers] = useState<any>([]);
  const [searchVal, setSearchVal] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);
  const getCustomersByCustomerName = useStoreActions((actions) => actions.customers.getCustomersByCustomerName);
  const delCustomer = useStoreActions((actions) => actions.customers.deleteCustomer);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [screen, setScreen] = useState<string>("");
  const getCustomerCategories = useStoreActions((actions) => actions.getCustomerCategories);
  const customerCategoriesMirror = useStoreState((state) => state.customerCategoriesMirror);
  const customerCategoriesOptions = useStoreState((state) => state.customerCategoriesOptions);
  const updateCustomer = useStoreActions((actions) => actions.customers.updateCustomer);
  const regionTypesMirror = useStoreState((s) => s.regionTypesMirror);
  const [delModal, setDelModal] = useState(false);
  const [chosenCustomer, setChoseCustomer] = useState<ICustomerModel | null>(null);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);

  useEffect(() => {
    if (!customerCategoriesOptions.length) { getCustomerCategories(); }
  }, []);


  useEffect(() => {
    (async () => {
      if (submit && searchVal) {
        setLoading(true);
        getCustomersByCustomerName(searchVal)
          .then((res: any) => {
            setfilteredCustomers(Object.values(res));
          })
          .catch((err: any) => addMessage({ message: err.message }))
          .finally(() => {
            setSubmit(false);
            setLoading(false);
          })
      }
    })();
  }, [submit]);


  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && searchVal) {
      setSelectedCustomer({});
      setfilteredCustomers([])
      setScreen("")
      setSubmit(true)
    }
  };

  const HandleBack = () => {
    setSelectedCustomer({});
    setfilteredCustomers([])
    setScreen("")
    setSubmit(true)
  }

  const renderScreen = () => {
    switch (screen) {
      case "customer":
        return <> <ArrowBack className={styles.arrowBackIcon} onClick={HandleBack} /><CustomEditCustomer HandleBack={HandleBack} history={history} selectedCustomer={selectedCustomer} /></>;
      case "sites":
        return <CustomSiteManagement HandleBack={HandleBack} customerId={selectedCustomer?.id} />;
      case "users":
        return <CustomUserManagement HandleBack={HandleBack} customerId={selectedCustomer?.id} />;
      case "devices":
        return <CustomDeviceManager HandleBack={HandleBack} customerId={selectedCustomer?.id} />;
      default:
        return;
    }
  };


  const onDelete = async () => {
    if (!chosenCustomer) {
      setDelModal(false);
      setChoseCustomer(null);
      return;
    }
    const success = await delCustomer(chosenCustomer.id);
    if (!success) {
      addMessage("something went wrong while deleting customer");
    }
    setDelModal(false);
    setChoseCustomer(null);
    setTimeout(() => setSubmit(true), 250)
  };

  return (
    <ServiceNavigationBar
      title={t`Customers`}
      searchComponent={
        <Input
          placeholder={t`Customer Name`}
          value={searchVal}
          onChange={(event: any) => setSearchVal(event.target.value)}
          disableUnderline={true}
          onKeyDown={handleKeyDown}
          classes={{ root: styles.inputRoot }}
          endAdornment={
            searchVal && (
              <IconButton
                onClick={() => {
                  setSelectedCustomer({});
                  setfilteredCustomers([])
                  setScreen("")
                  setSubmit(true)
                }}
                className={styles.searchIcon}
              >
                <Search />
              </IconButton>
            )
          }
        />
      }
      {...props}
    >

      {loading ? <div className={styles.loader} >< CircularProgress /></div> :

        <div>
          {selectedCustomer?.id ? renderScreen() :
            <>
              <div className={styles.addButtonContainer}>
                <Button
                  className={styles.ExportButtonStyle}
                  onClick={() => setExportDialogOpen(true)}
                >
                  {t`Export`}
                </Button>
                <Button
                  className={styles.buttonStyle}
                  onClick={() => history.push(`/customers/new`)}
                >{t`ADD NEW CUSTOMER`}</Button>
              </div>
              <Paper elevation={0} className={styles.paperPadding}>
                <TableContainer>
                  <Table className="" aria-label="customized table">
                    <TableHead className={styles.tableHead}>
                      <TableRow>
                        <TableCell className={styles.headCells} align="left" >
                          {t`NAME`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="left" >
                          {t`ID`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center" >
                          {t`USERS`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center" >
                          {t`SITES`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center" >
                          {t`DEVICES`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center" >
                          {t`DESCRIPTION`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center" >
                          {t`SALES TERRITORY`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center" >
                          {t`CUSTOMER PROFILE`}
                        </TableCell>
                        <TableCell className={styles.headCells} align="center">
                          {t`DELETE`}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredCustomers.map((customer: any) => {
                        return (
                          <TableRow hover tabIndex={-1} key={customer?.id} >
                            <TableCell className={styles.rowCellHyperLink} component="th" scope="row" align="left" onClick={() => {
                              setSelectedCustomer(customer);
                              setScreen("customer")
                            }}>
                              {customer?.name}
                            </TableCell>
                            <TableCell className={styles.rowCell} component="th" scope="row" align="left">
                              {customer?.id}
                            </TableCell>
                            <TableCell className={styles.rowCellHyperLink} component="th" scope="row" align="center" onClick={() => {
                              setSelectedCustomer(customer);
                              setScreen("users")
                            }}>
                              {t`USERS`}
                            </TableCell>
                            <TableCell className={styles.rowCellHyperLink} component="th" scope="row" align="center" onClick={() => {
                              setSelectedCustomer(customer);
                              setScreen("sites")
                            }}>
                              {customer?.numOfSites}
                            </TableCell>
                            <TableCell className={styles.rowCellHyperLink} component="th" scope="row" align="center" onClick={() => {
                              setSelectedCustomer(customer);
                              setScreen("devices")
                            }}>
                              {customer?.numOfDevices}
                            </TableCell>

                            <TableCell className={styles.rowCell} component="th" scope="row" align="center" >
                              {customer.description || ""}
                            </TableCell>

                            <TableCell className={styles.rowCell} component="th" scope="row" align="center" >
                              {customer?.region ? regionTypesMirror[customer.region] : ""}
                            </TableCell>

                            <TableCell component="th" scope="row" align="center" >
                              <AsyncSelect
                                value={customerCategoriesMirror[customer?.category]}
                                options={customerCategoriesOptions}
                                update={(value: any) => updateCustomer({ id: customer.id, data: { category: value } }).finally(setTimeout(() => setSubmit(true), 250))}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={styles.rowCell}
                            >
                              <IconButton
                                onClick={() => {
                                  setDelModal(true);
                                  setChoseCustomer(customer);
                                }}
                              >
                                <img src={DeleteIcon} alt="delete user" />
                              </IconButton>
                            </TableCell>

                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper></>}
        </div>
      }

      <SimpleModal
        showModal={delModal}
        title={t`Delete Customer`}
        contentText={t`are you sure you want to delete this customer ` + `${chosenCustomer?.name}`}
        actionName={t`Delete`}
        onAction={onDelete}
        closeModal={() => {
          setDelModal(false);
          setChoseCustomer(null);
        }}
      />

      <ExportPopup
        open={exportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
      />

    </ServiceNavigationBar >
  );
}
