import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  gridContainer: {
    backgroundColor: "#ecebef",
  },

  leftCard: {
    padding: "15px",
    marginBottom: "15px",
    marginLeft: "15px",
    marginTop: "15px",
  },

  rightCard: {
    paddingBottom: "25px",
    marginRight: "15px",
    height: " 86%",
  },
  customerTitle: {
    display: "flex",
    justifyContent: "center",
  },

  customerTitleText: {
    fontSize: "1.7rem",
  },

  fullRow: {
    gridColumn: "1/3",
    alignSelf: "center",
    "&>input": {
      fontSize: "2rem",
    },
    "&<label": {
      fontSize: "1.7rem",
    },
    "&>p": {
      fontSize: "0.9rem",
    },
  },
  textStyle: {
    "&>input": {
      fontSize: "2rem",
    },
    "&<label": {
      fontSize: "1.7rem",
    },
    "&>p": {
      fontSize: "0.9rem",
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "0.3rem",
    gridRowGap: "1rem",
  },

  tempSlider: {
    flexWrap: "unset",
  },

  SelectorOptions: {
    width: "100%",
    fontSize: "20px",
  },

  SelectorItem: {
    fontSize: "18px",
  },

  customTextStyle: {
    fontSize: "1.5rem",
  },

  customInputWraper: {
    width: "200%",
  },

  customInputStyle: {
    width: "100%",
    "&::after": {
      borderBottomColor: "#ffffff !important",
    },
    "&>input": {
      fontSize: "1.5rem",
      "&::after": {
        borderBottomColor: "#ffffff !important",
      },
    },
  },

  errInput: {
    color: "red",
  },

  fieldStyle: {
    width: "100%",
    height: "45px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    "&:hover": {
      borderBottom: "solid 2px #4b1c46",
    },
    "&>input": {
      padding: "9px",
      color: "#545964",
    },
  },
  fieldLabel: {
    width: "100%",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#7f7182",
    marginBottom: "10px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    border: "none",
  },

  helper: {
    fontSize: "12px",
    color: "#7f7182",
  },

  fieldsContainer: {
    display: "flex",
    width: "100%",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
  },

  phoneStyle: {
    padding: "4px 0",
  },

  smallField: {
    width: "48%",
  },
  selectStyle: {
    "&>dev": {
      "&:first-child": {
        padding: "9px 24px 9px 9px",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
  },

  tempField: {
    maxWidth: "140px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  appsField: {
    maxWidth: "210px",
  },
  twoFieldsRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    width: "100%",
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
  },
  sharedButtonStyle: {
    width: "90px",
    height: "40px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    margin: "15px 10px",
    marginBottom: "15px",
    padding: "0",
  },
  submitButton: {
    marginTop: "20px",
    display: "flex",
    alignSelf: "flex-end",
    marginLeft: "20px",
  },
  autoCompleteStyle: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    "&:*": {
      top: 0,
    },
    "&::nth-child(1)": {
      padding: 0,
      height: "45px",
    },
  },

  formatContainer: {
    width: "100%",
  },

  formats: {
    display: "block",
    "&>div": {
      paddingRight: "0 !important",
    },
  },
  PasswordChangeContent: {
    padding: 20,
    paddingBottom: 20,
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    minHeight: 60,
  },
  headerTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    fontSize: 18,
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  iconBtnStyle: {
    position: "absolute",
    right: "24px",
    top: "16px",
  },
  dialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden",
  },
  thirdButtonTitle: {
    display: "flex",
    alignItems: "center",
  },
  thirdButton: {
    width: "fit-content",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    "&>svg": {
      transform: "rotate(-90deg)",
      width: "35px",
      height: "35px",
    },
  },
  divider: {
    marginBottom: "15px",
  },
  newBlock: {
    width: "100%",
    marginTop: "10px",
  },
  tableHead: {
    height: "45px",
  },
  headCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    fontFamily: theme.tables.headerCell.fontFamily,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    height: 56,
    fontSize: 13,
    whiteSpace: "nowrap",
  },
  rowCell: {
    fontFamily: theme.tables.tableCell.fontFamily,
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: theme.tables.tableCell.padding,
    height: 52,
  },
  packagesCell: {
    width: "270px",
  },
  cancelBtn: {
    marginTop: "20px",
    color: "#4b1c46",
    width: "7rem",
    display: "flex",
    alignSelf: "flex-end",
    backgroundColor: "#ffffff",
    marginRight: "1rem",
  },
  authField: {
    maxWidth: "250px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
