import _ from "lodash";
import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";
import { Trigger } from "coolremote-sdk";
import { IRootStoreModel } from "./RootStore";
import { t } from "ttag";

interface ITemplate {
  action: any[];
  requiredActionText: string;
}

interface IRule {
  conditions: any;
  event: any;
}

export interface ITriggerModel {
  isEnabled: any;
  id: string;
  name: string | null;
  sendOnce: boolean;
  resources?: string[];
  resourceIds: string[];
  rule: any;
  template?: string | null;
  templateData?: any;
  triggerTemplateId?: string;
  site?: string;
  customer?: string;
}

export interface ITriggerMap {
  [key: string]: ITriggerModel;
}

export interface ITriggersModel {
  triggers: ITriggerMap;
  setTriggers: Action<ITriggersModel, { triggers: any }>;
  getTriggers: Thunk<ITriggersModel>;
  delTrigger: Thunk<ITriggersModel, string>;
  removeTrigger: Action<ITriggersModel, string>;
  addTrigger: Action<ITriggersModel, ITriggerModel>;
  updateTrigger: Thunk<ITriggersModel, { id: string; data: any }>;
  getTriggerParsedData: Computed<
    ITriggersModel,
    (id: string) => any,
    IRootStoreModel
  >;
  deleteCustomerTrigger: Thunk<IRootStoreModel, { id: string }>;
}

export const TriggersModel: ITriggersModel = {
  triggers: {},
  setTriggers: action((state, payload) => {
    const fetchedTriggers: ITriggerMap = _(Object.values(payload))
      .map((trigger: any) => {
        const addedTrigger: ITriggerModel = { ...trigger };

        return addedTrigger;
      })
      .keyBy("id")
      .value();

    state.triggers = fetchedTriggers;
  }),

  addTrigger: action((state, payload) => {
    state.triggers[payload.id] = payload;
  }),

  removeTrigger: action((state, payload) => {
    delete state.triggers[payload];
  }),
  deleteCustomerTrigger: thunk(async (actions, payload) => {
    await Trigger.delete(payload.id);

    // actions._storeDeleteCustomerTrigger({ id: payload.id });
  }),
  getTriggers: thunk(async actions => {
    return Trigger.getTriggers()
      .then((data: any) => {
        actions.setTriggers(data);
        return true;
      })
      .catch(() => false);
  }),
  getTriggerParsedData: computed([state => state.triggers], triggers => id => {
    const trigger: ITriggerModel = triggers[id];

    let triggerParsedData = {
      typeText: ""
    };

    if (!trigger.template) {
      return triggerParsedData;
    }

    if (trigger.template.indexOf("UNIT_ERROR") !== -1) {
      triggerParsedData = {
        typeText: t`Unit error`
      };
    } else if (trigger.template.indexOf("UNIT_LIMITS") !== -1) {
      triggerParsedData = {
        typeText: t`Safety limits exceeded`
      };
    } else if (trigger.template.indexOf("UNIT_EXCEEDED") !== -1) {
      triggerParsedData = {
        typeText: t`Threshold exceeded`
      };
    } else if (trigger.template.indexOf("UNIT_CLEAN_FILTER") !== -1) {
      triggerParsedData = {
        typeText: t`Clean filter`
      };
    } else if (trigger.template.indexOf("SCHEDULED_EVENT") !== -1) {
      triggerParsedData = {
        typeText: t`Scheduled maintenance`
      };
    } else if (trigger.template.indexOf("DEVICE_DISCONNECT") !== -1) {
      triggerParsedData = {
        typeText: t`Device disconnected`
      };
    } else if (
      trigger.template.indexOf("ISTAT_BREACH") !== -1 ||
      trigger.template.indexOf("OSTAT_BREACH") !== -1
    ) {
      if (
        !_.isNil(trigger.templateData.biggerThan) &&
        _.isNil(trigger.templateData.smallerThan)
      ) {
        triggerParsedData = {
          typeText: t`System Parameter > Value`
        };
      } else if (
        _.isNil(trigger.templateData.biggerThan) &&
        !_.isNil(trigger.templateData.smallerThan)
      ) {
        triggerParsedData = {
          typeText: t`System Parameter < Value`
        };
      } else if (
        !_.isNil(trigger.templateData.biggerThan) &&
        !_.isNil(trigger.templateData.smallerThan)
      ) {
        triggerParsedData = {
          typeText: t`System Parameter < Value1 OR system Parameter > Value2`
        };
      }
    } else if (
      trigger.template.indexOf("ISTAT_EQUALS") !== -1 ||
      trigger.template.indexOf("OSTAT_EQUALS") !== -1
    ) {
      triggerParsedData = {
        typeText: t`System Parameter = Value`
      };
    } else if (
      trigger.template.indexOf("ISTAT_THRESHOLD_EXCEEDED") !== -1 ||
      trigger.template.indexOf("OSTAT_THRESHOLD_EXCEEDED") !== -1
    ) {
      triggerParsedData = {
        typeText: t`Difference between system-Parameter-1 and system-Parameter-2 > Value`
      };
    }

    return triggerParsedData;
  }),
  updateTrigger: thunk(async (actions, payload) => {
    return Trigger.update(payload.id, payload.data)
      .then((data: ITriggerModel) => {
        actions.addTrigger(data);
        return true;
      })
      .catch(() => false);
  }),

  delTrigger: thunk(async (actions, payload) => {
    return Trigger.delete(payload)
      .then(() => {
        actions.removeTrigger(payload);
        return true;
      })
      .catch(() => false);
  })
};
