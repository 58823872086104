import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  view: {
    display: "flex",
    height: "-webkit-fill-available",
    width: "100%",
    background: "#fff",
    overflowY: "auto",
    overflowX: "auto",
  },
  contentArea: {
    background: "white",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: "1 1",
  },
  content: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  blueFilter: {
    fill: "#1976d2",
  },
  filterStyle: {
    marginLeft: "10px",
  },
  headCells: {
    borderBottom: "none",
    padding: "0 15px",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    fontFamily: theme.tables.headerCell.fontFamily,
    height: 45,
    fontSize: 13,
  },
  rowCell: {
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    fontFamily: theme.tables.tableCell.fontFamily,
    padding: theme.tables.tableCell.padding,
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    background: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0,
  },
  submitButton: {
    backgroundImage: theme.gradiants.button || theme.gradiants.button || "linear-gradient(to left, #421a40 100%, #29132e 0%)",
    color: "#fdfdfe",
  },
  disabledButton: {
    color: theme.palette.common.white + "!important",
    opacity: "0.6",
  },
  sharedButtonStyle: {
    width: "90px",
    height: "40px",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    margin: "15px 10px",
    marginBottom: "15px",
    padding: "0",
  },
  actionsContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
  },
  dialogPaper: {
    minWidth: "400px",
  },
  inputField: {
    padding: "5px",
  },

  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px",
  },
  searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px",
  },
  searchIcon: {
    transform: "scale(0.7)",
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px",
    },
  },
}));
export default useStyles;
