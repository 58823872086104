import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  CircularProgress,
  TablePagination,
  Paper
} from "@mui/material/";
import { FilterList, Settings } from "@mui/icons-material";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { Lookup } from "../../components/Lookup";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./SiteManagement.style";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from "moment-timezone";
import { countries } from "./countryList";
import exportDataToExcel from "../../utils/ExportDataToExcel";

type Order = "asc" | "desc";
const packagesMirrore: any = {
  hvacAdvancedOperations: "Advanced HVAC Operations",
  predictiveMaintenance: "Predictive Maintenance"
};
interface ITableColumn {
  label: string;
  columnId: string;
  align?: "left" | "center" | "right";
  withFilter?: boolean;
  sortable?: boolean;
  withSum?: boolean;
}

interface ITableColumns {
  [key: string]: ITableColumn
}

let tableColumns: ITableColumns = {
  "customerName": { label: "CUSTOMER NAME", columnId: "customerName", align: "left", withFilter: true },
  "_id": { label: "SITE ID", columnId: "_id", align: "left", withFilter: false },
  "customerRegion": { label: "CUSTOMER REGION", columnId: "customerRegion", align: "left", withFilter: true },
  "name": { label: "SITE NAME", columnId: "name", align: "left", withFilter: true },
  "category": { label: "FACILITY TYPE", columnId: "category", align: "left", withFilter: true },
  "ownerCA": { label: "OWNER CA", columnId: "ownerCA", align: "left", withFilter: true },
  "ownerCS": { label: "OWNER CS", columnId: "ownerCS", align: "left", withFilter: true },
  "country": { label: "COUNTRY", columnId: "country", align: "left", withFilter: true },
  "enabledPackages": { label: "SAAS PACKAGES", columnId: "enabledPackages", align: "left" },
  "onBoardingStatus": { label: "ONBOARDING STATUS", columnId: "onBoardingStatus", align: "left", withFilter: true },
  "phase": { label: "SITE JOURNEY PHASE", columnId: "phase", align: "left" },
  "daysToRenewal": { label: "DAYS TO RENEWAL", columnId: "daysToRenewal", align: "center", sortable: true },
  "connectedIDUs": { label: "# OF IDU CONNECTED", columnId: "connectedIDUs", align: "center", withSum: true, sortable: true },
  "creatingDate": { label: "SERVICE START DATE", columnId: "creatingDate", sortable: true },
  "yearsInService": { label: "YEARS IN SERVICE", columnId: "yearsInService", align: "center", withSum: true, sortable: true },
  "devicesNum": { label: "# OF DEVICES", columnId: "devicesNum", align: "center", withSum: true, sortable: true },
  "invoiceNum": { label: "INVOICE#", columnId: "invoiceNum", align: "left", sortable: true },
  "priorityID": { label: "PRIORITY ID#", columnId: "priorityID" },
  "yearlyInvoice": { label: "YEARLY INVOICE", columnId: "yearlyInvoice", align: "center" },
};
let allColumnsIDs: string[] = Object.keys(tableColumns).sort((a, b) => tableColumns[a].label > tableColumns[b].label ? 1 : -1)

const SiteManagement = (props: any) => {
  const styles: any = useStyles();
  const [sites, setSites] = useState<any>({});
  const [filteredSites, setFilteredSites] = useState<any>([]);
  const [showFilterPopup, setFilterPopup] = useState<string>("");
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<Order>("asc");
  const [lookupAnchor, setAnchor] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [filters, setFilters] = useState<any>({});
  const [summation, setSummation] = useState<any>({});

  const [visibleColumns, setVisibleColumns] = useState<any>({});
  const [openSettingsMenu, setOpenSettingsMenu] = useState<boolean>(false);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);

  const history = useHistory();
  const selections = useStoreState((state) => state.selections.selections);
  const setSelections = useStoreActions((a) => a.selections.setSelections);

  const {
    regionTypes = {},
    invoicingPeriods = {},
    siteCategories = {},
    currencyTypes = {},
    sitePhases = {},
    siteOnboardingStatus = {}
  } = useStoreState((s) => s.types);
  const siteOnboardingStatusMirror = Object.entries(siteOnboardingStatus).reduce((acc: any, [label, value]: any) => { acc[+value] = label; return acc }, {})
  const currencyTypesMirror = Object.entries(currencyTypes).reduce((acc: any, [label, value]: any) => { acc[+value] = label; return acc }, {})
  const sitePhasesMirror = Object.entries(sitePhases).reduce((acc: any, [label, value]: any) => { acc[+value] = label; return acc }, {})
  const invoicingPeriodsMirror = Object.entries(invoicingPeriods).reduce((acc: any, [label, value]: any) => { acc[+value] = label; return acc }, {})
  const siteCategoriesMirror = Object.entries(siteCategories).reduce((acc: any, [label, value]: any) => { acc[+value] = label; return acc }, {})
  const regionTypesMirror = Object.keys(regionTypes).reduce((acc: any, cur: string) => { acc[regionTypes[cur]] = cur; return acc }, {})
  const countriesMirror = countries.reduce((acc: any, { code, name }) => { acc[code] = name; return acc; }, {});
  const getAllSites = useStoreActions((actions) => actions.sites.getAllSites);

  let { customerId } = selections;

  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const getAllAdminUsers = useStoreActions((action) => action.adminUsers.getAllAdminUsers);
  const [adminOptionsMirror, setAdminOptionsMirror] = useState<any>({});
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(100);

  useEffect(() => {
    getAllAdminUsers()
      .then((res: any) => {
        setAdminOptionsMirror(Object.entries(res).reduce((acc: any, [userId, { firstName = "", lastName = "" }]: any) => {
          acc[userId] = `${firstName} ${lastName}`
          return acc
        }, {}))
      });

  }, []);
  useEffect(() => {
    if (!columnOrder?.length) {
      return;
    }
    setVisibleColumns(columnOrder?.reduce((acc: any, cur: string) => {
      acc[cur] = true;
      return acc
    }, {}))

    localStorage.setItem("columnOrder", JSON.stringify(columnOrder.filter(col => !!tableColumns[col])))
  }, [columnOrder])

  useEffect(() => {
    const newOrder = JSON.parse(localStorage.getItem("columnOrder") || "[]")
    if (newOrder?.length) {
      setColumnOrder(newOrder);
    }
    else {
      setColumnOrder(allColumnsIDs)
    }
  }, [])

  const handleColumnReorder = (result: any) => {
    if (!result.destination) return; // Return if the column was not dropped in a valid location
    const newOrder = Array.from(columnOrder);
    const [removed] = newOrder.splice(result.source.index, 1); // Remove the dragged column
    newOrder.splice(result.destination.index, 0, removed); // Insert the column in the new position
    setColumnOrder(newOrder);
  };

  const handleColumnDisplay = (event: any, value: boolean) => {
    const columnId = event?.target?.name

    if (value)
      setColumnOrder([...columnOrder, columnId]);
    else if (columnOrder?.length > 1) {
      setColumnOrder(columnOrder.filter((col: string) => col !== columnId));
    }
  };

  useEffect(() => {
    if(props.match.params.customerId && customerId !== props.match.params.customerId) {
      setSelections({
        customerId: props.match.params.customerId,
        siteId: null,
        systemId: null,
        unitId: null
      });
      history.push(`/customers/${props.match.params.customerId}/sites`);
      return;
    }

    if(customerId && !props.match.params.customerId) {
      history.push(`/customers/${customerId}/sites`);
    }
  }, [])
  useEffect(() => {
    if(props.match.params.customerId && customerId !== props.match.params.customerId)
        return;

    setLoading(true);
    setSites({});
    setFilteredSites({});
    setPage(0);

    getAllSites(customerId ? customerId : "").then((data: any) => {
      setSites(data);
      setFilteredSites(data);
    })
    .finally(() => setLoading(false))

  }, [customerId]);

  useEffect(() => {
    const newFilters: any = {}
    Object.values(sites).forEach(
      (site: any) => {
        allColumnsIDs.forEach(columnId => {
          if (!newFilters[columnId]) {
            newFilters[columnId] = {}
          }
          newFilters[columnId][getValue({ columnId, site }) || ""] = true
        })
      }
    );
    setFilters(allColumnsIDs.reduce((acc: any, cur) => {
      acc[cur] = Object.keys(newFilters[cur] || {})
      return acc
    }, {}));
  }, [appliedFilters, sites]);

  const handleFilterPopup = (event: any, column: string) => {
    setFilterPopup(column);
    setAnchor(event.currentTarget);
  };

  useEffect(() => {
    const columnsFilter = Object.keys(appliedFilters).filter(filters => appliedFilters[filters]?.length)
    const columnsFilterLength = columnsFilter.length
    const filterCode = (site: any) => {
      return columnsFilter.map(columnId => {
        return appliedFilters[columnId]?.includes(getValue({ columnId, site }) || "")
      }).filter(Boolean).length === columnsFilterLength
    }

    setFilteredSites(Object.values(sites).filter(filterCode));
  }, [appliedFilters, sites]);

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({ ...appliedFilters, [showFilterPopup]: selectedFilters });
    setFilterPopup("");
  };

  const getValue = (props: { columnId: string, site: any, text?: boolean, number?: boolean }) => {
    const { columnId, site, text = false, number = false } = props

    switch (columnId) {
      case "customerRegion":
        return regionTypesMirror[site.customerRegion]
      case "country":
        return countriesMirror[site.country]
      case "renewalDate":
        if (number) {
          return site.renewalDate
        }
        return site.renewalDate ? moment(site.renewalDate).format("YYYY/MMM/DD") : ""
      case "onBoardingStatus":
        return siteOnboardingStatusMirror[site.onBoardingStatus]
      case "currency":
        return currencyTypesMirror[site.currency]
      case "invoicingPeriod":
        return invoicingPeriodsMirror[site.invoicingPeriod]
      case "phase":
        return sitePhasesMirror[site.phase]
      case "discountPercent":
        if (number) {
          return site[columnId]
        }
        return site.discountPercent ? (site.discountPercent + "%") : ""
      case "category":
        return siteCategoriesMirror[site.category]
      case "ownerCA":
      case "ownerCS":
        return adminOptionsMirror[site[columnId]]
      case "creatingDate":
        if(!site.creatingDate) {
          return '-'
        }
        if (number) {
          return moment(site.creatingDate).valueOf()
        }
        return moment(site.creatingDate).format("YYYY/MMM/DD")
      default:
        return site[columnId]
    }
  }
  const getStyle = (props: { columnId: string, site: any }) => {
    const { columnId, site } = props
    const value = getValue({ columnId, site })
    switch (columnId) {
      case "daysToRenewal":
        return value < 0 ? styles.red : value < 60 ? styles.orange : value >= 60 ? styles.green : ""
      case "contractedIDUs":
        const IDUConnectedValue = getValue({ columnId: "connectedIDUs", site })
        return value > IDUConnectedValue ? styles.red : value / IDUConnectedValue >= 0.9 ? styles.green : styles.orange
      default:
        return ""
    }
  }
  const hasFilters = !!Object.values(appliedFilters).flat().length;
  const sortedSites = _.orderBy(filteredSites, (site: any) => (+getValue({ columnId: orderBy, site, number: true }) || 0), [order]);

  useEffect(() => {
    const newSummation: any = {}
    sortedSites.forEach((site: any, index: number) => {
      columnOrder.forEach((columnId: string, i: number) => {
        const col: any = tableColumns[columnId] || {}
        if (!col.columnId) return null;
        if (col.withSum) {
          if (!newSummation[columnId]) {
            newSummation[columnId] = 0
          }
          newSummation[columnId] += (+getValue({ columnId, site }) || 0)
        }
      })
    })

    setSummation(newSummation)

  }, [filteredSites])

  const exportToExcel = () => {
    const excelColumns = columnOrder.map((colId) => ({ header: tableColumns[colId].label, key: colId, wrapText: colId === "enabledPackages" }));
    const data = sortedSites.map(site => {
      return columnOrder.reduce((acc: any, columnId) => {
        acc[columnId] = getValue({ columnId, site, text: true })
        return acc;
      }, {})
    })
    exportDataToExcel({ columns: excelColumns, data, fileName: "site management data", defaultWidth: 27 })
  }

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <ServiceNavigationBar
        title={t`All Sites`}
        filters={
          <SelectionsMenu
            hideSiteSelection
            hideUnitSelection
            hideSystemSelection
            onCustomerSelect={(id: any) => id ? history.push(`/customers/${id}/sites`) : history.push(`/sites`)}
            skipGetSites={true}
          />}
        {...props}
      >
            <Paper elevation={0} className={styles.paperPadding}>
                <div style={{ textAlign: "right", marginBottom: "12px" }}>
                <IconButton className={"styles.IconButton"}
                onClick={(event: any) => {
                  setSettingsMenuAnchor(event.currentTarget);
                  setOpenSettingsMenu(true);
                }}
                >
                <Settings />
                </IconButton>
                <Button variant="contained" onClick={exportToExcel}>
                {t`Export to Excel`}
                </Button>
                </div>
                {loading ? <div className={styles.loaderContainer}><CircularProgress /></div> :
                <>
                  <TableContainer style={{flex: 1}}>
                  <DragDropContext onDragEnd={handleColumnReorder}>
                    <Table stickyHeader aria-label="customized table">
                      <TableHead>
                        <Droppable droppableId="columns" direction="horizontal">
                          {(provided: any) => (
                            <TableRow ref={provided.innerRef} {...provided.droppableProps}>
                              {columnOrder.map((colId: string, index: number) => {
                                const { label, columnId, withSum, withFilter, sortable } = tableColumns[colId] || {}
                                if (!columnId) return null;

                                return <Draggable key={columnId} draggableId={columnId} index={index}>
                                  {(provided: any) => (
                                    <TableCell
                                      className={styles.headCells}
                                      align="left"
                                      ref={provided.innerRef}
                                      onClick={(e: any) => withFilter && handleFilterPopup(e, columnId)}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                                        {sortable ? <TableSortLabel
                                          active={orderBy === columnId}
                                          direction={orderBy === columnId ? order : "asc"}
                                          onClick={() => {
                                            if (orderBy === columnId) {
                                              if (orderBy === columnId && order === "asc") {
                                                setOrder("desc")
                                              } else {
                                                setOrderBy("")
                                              }
                                            } else {
                                              setOrderBy(columnId)
                                              setOrder("asc")
                                            }
                                          }}
                                        >
                                          {t`${label}`}{withSum && ` (${summation[columnId] || 0})`}
                                          {orderBy === columnId ? (
                                            <span className={styles.visuallyHidden}>
                                              {order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                            </span>
                                          ) : null}
                                        </TableSortLabel>
                                          : <>{t`${label}`}{withSum && ` (${summation[columnId] || 0})`}</>
                                        }
                                        {withFilter && <FilterList className={clsx(styles.filterStyle, { [styles.blueFilter]: !_.isEmpty(appliedFilters[columnId]) })} />}
                                      </div>
                                    </TableCell>
                                  )}
                                </Draggable>
                              })}
                              {provided.placeholder}
                            </TableRow>
                          )}
                        </Droppable>
                      </TableHead>
                      <TableBody>
                        {sortedSites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((site: any, index: number) => {
                          const { id } = site;
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={id}
                              onDoubleClick={() => props.history.push(`/site/${id}`)}
                            >
                              {columnOrder.map((columnId: string, i: number) => {
                                const col: any = tableColumns[columnId] || {}
                                if (!col.columnId) return null;
                                const val = getValue({ columnId, site })
                                return <TableCell style={{ padding: "5px 10px" }} align={col.align || "left"} className={getStyle({ columnId, site })} >
                                  {col.withSum ? (val || 0) : val}
                                </TableCell>;
                              })}
                            </TableRow>
                          );
                        }
                        )}
                      </TableBody>
                    </Table>
                  </DragDropContext>
                </TableContainer>
                <TablePagination
                style={{ minHeight: 52 }}
                rowsPerPageOptions={[10, 25, 50, 100, 250]}
                component="div"
                count={sortedSites.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </>
              }
              </Paper>
      </ServiceNavigationBar >

      {showFilterPopup && (
        <Lookup
          filtersList={filters[showFilterPopup]}
          appliedFilters={appliedFilters[showFilterPopup]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setFilterPopup("")}
          clearAllFilters={() => setAppliedFilters({})}
          hasFilters={hasFilters}
        />
      )}
      <Popover
        id={"popup-menu"}
        open={openSettingsMenu}
        style={{ overflow: "unset" }}
        anchorEl={settingsMenuAnchor}
        onClose={() => { setOpenSettingsMenu(false); setSettingsMenuAnchor(null) }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Typography className={styles.popoverTitle}>{t`Show/Hide columns`}</Typography>
        <FormGroup className={styles.listContainer} >
          {allColumnsIDs.map((columnId: string, index: number) => {
            const { label } = tableColumns[columnId]
            return <FormControlLabel
              key={index}
              control={<Checkbox checked={visibleColumns[columnId] || (columnOrder?.length === 1 && columnOrder[0] === columnId)} onChange={handleColumnDisplay} name={columnId} />}
              label={t`${label}`}
            />
          })}
        </FormGroup>
      </Popover>
      </>
  );
};

export default SiteManagement;
