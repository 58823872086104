import { Account as AccountSdk } from "coolremote-sdk";
import { Thunk, thunk } from "easy-peasy";
import { GlobalAdmin } from "coolremote-sdk";

export interface IAccountModel {
  description: string;
  name: string;
  region: string;
  category: string;
  enableAPI: boolean;
  CRMReferenceID: string;
  ownerCA: string;
  ownerCS: string;
  journyPhase: string;
  numberOfCustomers: number;
  numberOfSites: number;
  numberOfDevices: number;
  numberOfUsers: number;
  subscriptions: number;
  disableAccessToPro: boolean;
  advancedOperationsPackage: string;
  predictiveMaintenancePackage: string;
  hvacAdvancedOperations: string;
  predictiveMaintenance: string;
}

export interface IAccountMap {
  [key: string]: IAccountModel;
}

export interface IAccountsModel {
  getAccounts: Thunk<IAccountsModel>;
  getPoolSubscriptions: Thunk<IAccountsModel>;
  createPoolSubscriptions: Thunk<IAccountsModel, { data: any }>;
  updatePoolSubscriptions: Thunk<IAccountsModel, { id: string, data: any }>;
  deletePoolSubscriptions: Thunk<IAccountsModel, string>;
  updateAccount: Thunk<IAccountsModel, { id: string; data: any }>;
  getAccount: Thunk<IAccountsModel, string>;
}

export const AccountsModel: IAccountsModel = {
  getAccounts: thunk(async (actions) => {
    return GlobalAdmin.getAccounts();
  }),
  getPoolSubscriptions: thunk(async (actions) => {
    return AccountSdk.getPoolSubscriptions();
  }),
  createPoolSubscriptions: thunk((actions, payload) => {
    return AccountSdk.createPoolSubscriptions(payload.data);
  }),
  updatePoolSubscriptions: thunk((actions, payload) => {
    return AccountSdk.updatePoolSubscriptions(payload.id, payload.data);
  }),
  deletePoolSubscriptions: thunk((actions, payload) => {
    return AccountSdk.deletePoolSubscriptions(payload);
  }),
  updateAccount: thunk((actions, payload) => {
    return GlobalAdmin.updateAccount(payload.id, payload.data);
  }),
  getAccount: thunk((actions, payload) => {
    return GlobalAdmin.getAccountById(payload);
  }),
};
