import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	paper: {
		background: '#FFF',
		display: 'flex',
		flexDirection: 'column',
		padding: '16px',
		height: '100vh',
		overflow: 'hidden'
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	iframe: {
		width: '100%',
		height: '100%',
		border: 'none',
		flexGrow: 1,
		overflow: 'auto'
	},
	select: {
		width: '100%',
		padding: '12px 14px',
		border: '1px solid #ccc',
		borderRadius: '4px',
		backgroundColor: '#fff',
		fontSize: '14px',
		cursor: 'pointer',
		boxSizing: 'border-box'
	},
	option: {
		padding: '12px',
		fontSize: '14px'
	}
});

export default useStyles;
