import {
  Button,
  Dialog,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import CoolButton from "../../cool_widgets/Button";
import { Switch as CoolSwitch } from "../../cool_widgets/Switch";
import { Arrow as SvgArrow, Close } from "../../icons";
import { IBasicCustomerMap, ICustomerModel } from "../../models/CustomerModel";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { UserSchema, UserSchemaNew } from "../../models/UsersModel";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import useStyles from "./OneUser.style";
interface IProps {
  onSave: (data: any, id: string) => void;
  onClose: () => void;
  mainButtonLabel: string;
  withPasswordField?: boolean;
  customers: IBasicCustomerMap;
  user?: any;
  customerIdLockedto?: any;
  errorText?: string;
  selectedCustomer?: string;
  viewAsComponent?: boolean;
}

const timeFormatI = [
  {
    name: "iso8601_24Hours",
    text: "24 hours",
    value: 0
  },
  {
    name: "standard12Hours",
    text: "12 hours",
    value: 1
  }

];
const dateFormatI = [
  {
    name: "startWithDay",
    text: "DD/MM/YY",
    value: 0
  },
  {
    name: "startWithMonth",
    text: "MM/DD/YY",
    value: 1
  }
];

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t`must be at least 8 chars and contain 1 capital letter and 1 digit`)
    .notRequired()
    .when("password", {
      is: (val: any) => val !== undefined,
      then: Yup.string().required(t`Required`)
    }),
  repeatPassword: Yup.string()
    .when("newPassword", {
      is: (val: any) => val !== undefined && val !== "",
      then: Yup.string().required(t`Required`)
        .oneOf([Yup.ref("newPassword"), null], t`Passwords must match`)
    })
});

export default function OneUser(props: IProps) {
  const {
    user,
    customers,
    mainButtonLabel,
    withPasswordField,
    onClose,
    customerIdLockedto,
    onSave,
    errorText,
    selectedCustomer = "",
    viewAsComponent
  } = props;

  const {
    firstName = "",
    lastName = "",
    username = "",
    email = "",
    phone = "",
    customer = "",
    timeFormat = 0,
    dateFormat = 0,
    temperatureScale: tempScale = 1
  } = user || {};

  const styles: any = useStyles();
  const getCustomerById = useStoreActions((actions) => actions.customers.getCustomerById);
  const [selectedCustomerObj, setSelectedCustomerObj] = useState<ICustomerModel | null>(null)
  const [userCustomerObj, setUserCustomerObj] = useState<ICustomerModel | null>(null)
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [accessibleSites, setAccessibleSites] = useState<any>([]);
  const [temperatureScale, setTemperatureScale] = useState(tempScale);
  const [limitFreeSites, setLimitFreeSites] = useState<boolean>(user?.limitFreeSites || false);
  const [numOfFreeSites, setNumOfFreeSites] = useState<number>(user?.numOfFreeSites ?? 2);
  const enableCAPD = userCustomerObj ? userCustomerObj?.enablePowerDistribution : selectedCustomerObj ? selectedCustomerObj?.enablePowerDistribution : false;
  const [customersOptions, setCustomersOptions] = useState<any>([]);
  const selectedCustomerOption = { value: selectedCustomer, label: customers[selectedCustomer]?.name };
  const updatePassword = useStoreActions((action) => action.users.updatePassword);
  const getUserSites = useStoreActions((action) => action.users.getUserSites);
  const sitePackageMirror = useStoreState((s) => s.sitePackageMirror);

  const passwordBox = passwordError ? (
    <ErrorBox error={passwordError} onClose={() => setPasswordError("")} />
  ) : null;

  const getCustomers = () => {
    const menuOptions: any[] = [];

    _.map(customers, (customer) => {
      menuOptions.push({ value: customer.id, label: customer.name });
    });

    return _.sortBy(menuOptions, [(option) => option.label?.toUpperCase()], ["asc"]);
  };

  useEffect(() => {
    setCustomersOptions(getCustomers());
  }, [customers]);

  useEffect(() => {
    if (selectedCustomer || user?.customer)
      getCustomerById(user?.customer || selectedCustomer)
        .then(user?.customer ? setUserCustomerObj : setSelectedCustomerObj)
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    getUserSites(user.id)
      .then((res: any) => {
        setAccessibleSites(res);
      })
      .catch((e: any) => setPasswordError(e.message));
  }, [user]);

  const getPhoneNumber = (value: string) => {
    let newValue = value?.replace(/[^a-zA-Z0-9 ]/g, "");
    newValue = newValue?.replace(/\s/g, "");
    return newValue;
  };

  const onPasswordChangeSubmit = (values: any) => {
    if (values.newPassword && values.repeatPassword) {
      const data = { password: values.newPassword };
      updatePassword({ userId: user.id, data })
        .then(() => closePasswordChange())
        .catch((e: any) => setPasswordError(e.message));
    }
  };

  const closePasswordChange = () => {
    setPasswordOpen(false);
  };

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      temperatureScale,
      phone: values.phone ? getPhoneNumber(values.phone) : undefined,
      password: withPasswordField ? values.password : undefined,
      limitFreeSites,
      numOfFreeSites
    };

    delete data.customer

    if (!user) {
      data.username = data.email
      data.permissions = {
        customers: {
          [values?.customer?.value]: "customerAdmin"
        }
      };
    } else {
      data.permissions = user.permissions
    }

    user ? onSave(user.id, data) : onSave(values.customer?.value, data);
  };

  const getPackagesNames = (packages: any) => {
    return packages.map((packageName: any) => <Typography key={packageName}> {sitePackageMirror[packageName]} </Typography>);
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          username,
          phone,
          customer: selectedCustomer ? selectedCustomerOption : customerIdLockedto ? customerIdLockedto : customer,
          password: "",
          timeFormat,
          dateFormat
        }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={user ? UserSchema : UserSchemaNew}
        autoComplete="off"
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit, setFieldValue }: any = props;
          return (
            <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
              <div className={styles.twoFieldsRow}>

                <TextField
                  label={t`First name`}
                  name="firstName"
                  helperText={errors.firstName || t`up to 20 characters`}
                  className={styles.smallField}
                  variant={'standard'}
                  onChange={handleChange}
                  value={values.firstName || ""}
                  error={errors.firstName}
                  {...props}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                    maxLength: 20
                  }}
                />
                <TextField
                  className={styles.smallField}
                  label={t`Last name`}
                  name="lastName"
                  helperText={errors.lastName || t`up to 20 characters`}
                  onChange={handleChange}
                  variant={'standard'}
                  value={values.lastName || ""}
                  error={errors.lastName}
                  {...props}
                  inputProps={{
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                    maxLength: 20
                  }}
                />
              </div>
              <TextField
                label={user ? t`Email Address` : t`Username/Email`}
                name="email"
                helperText={errors.email || t`valid email address`}
                onChange={handleChange}
                variant={'standard'}
                value={values.email || ""}
                error={errors.email}
                className={styles.newBlock}
                {...props}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  }
                }}
              />
              {user && <TextField
                label={t`Username`}
                name="username"
                id={"test"}
                helperText={errors.username || t`6-20 characters, must be unique`}
                onChange={handleChange}
                variant={'standard'}
                value={values.username || ""}
                error={errors.username}
                className={styles.newBlock}
                {...props}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  }
                }}
              />}


              {withPasswordField && (
                <TextField
                  label={t`Password`}
                  name="password"
                  helperText={errors.password || t`8+ characters, 1 capital, 1 number`}
                  error={errors.password}
                  value={values.password || ""}
                  variant={'standard'}
                  onChange={handleChange}
                  className={styles.newBlock}
                  type="password" //to prevent auto fill
                  autoComplete="off"
                  {...props}
                />
              )}

              <InputLabel className={clsx(styles.fieldLabel, styles.newBlock)}>
                <MuiPhoneNumber
                  disableAreaCodes={true}
                  value={values.phone || ""}
                  label={t`Phone number`}
                  name="phone"
                  inputClass={clsx(styles.fieldStyle, styles.phoneStyle)}
                  defaultCountry={"us"}
                  onChange={(e: any) => setFieldValue("phone", e)}
                  InputProps={{
                    disableUnderline: true,
                    classes: { underline: styles.error }
                  }}
                />
                <Typography
                  className={
                    errors.phone && touched.phone ? styles.error : styles.helper
                  }
                >
                  {errors.phone && touched.phone
                    ? errors.phone
                    : t`International format telephone number`
                  }
                </Typography>
              </InputLabel>
              <div className={styles.twoFieldsRow}>
                <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
                  {t`Connect to customer`}
                  <Autocomplete
                    options={!selectedCustomer ? customersOptions : selectedCustomerOption}
                    id="customer-list"
                    value={!!selectedCustomer ? selectedCustomerOption :
                      !!customerIdLockedto
                        ? customerIdLockedto
                        : values.customer
                    }
                    defaultValue={""}
                    onChange={(e: any, value: any) => setFieldValue("customer", value)}
                    disabled={!!customerIdLockedto || !!selectedCustomer}
                    getOptionLabel={(option: any) => option.label || ""}
                    renderInput={(params: any) => (
                      <TextField
                        variant="filled"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          disableUnderline: true,
                          autoComplete: "new-password",
                          form: {
                            autocomplete: 'off',
                          }
                        }}
                        className={styles.autoCompleteStyle}
                      />
                    )}
                  />

                  <Typography
                    className={
                      errors.customer && touched.customer
                        ? styles.error
                        : styles.helper
                    }
                  >
                    {errors.customer && touched.customer
                      ? errors.customer
                      : t`Select to which customer to connect`}
                  </Typography>
                </InputLabel>
                <InputLabel className={clsx(styles.fieldLabel, styles.tempField)} >
                  {t`Temperature scale`}
                  <div>
                    ℃
                    <Switch
                      color="default"
                      onChange={() => {
                        setTemperatureScale(temperatureScale !== 2 ? 2 : 1);
                      }}
                      checked={temperatureScale === 2}
                      name="temperatureScale"
                    />
                    ℉
                  </div>
                </InputLabel>
              </div>

              <div className={styles.twoFieldsRow}>
                <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
                  {t`Time Format`}
                  <Select
                    displayEmpty
                    disabled={false}
                    value={values.timeFormat ? values.timeFormat : 0}

                    onChange={(event: any) => {
                      setFieldValue("timeFormat", event.target.value);
                    }}
                    variant="outlined"
                    disableUnderline
                    className={styles.formats}
                  >
                    {<MenuItem value={""} disabled style={{ display: "none" }}>{t`12 hours`}</MenuItem>}
                    {timeFormatI.map((formatObj: any) => {
                      return <MenuItem key={formatObj.value} value={formatObj.value} >{formatObj.text}</MenuItem>;
                    }
                    )}
                  </Select >
                </InputLabel>
                <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
                  {t`Date Format`}
                  <Select
                    displayEmpty
                    disabled={false}
                    value={values.dateFormat ? values.dateFormat : 0}
                    onChange={(event: any) => {
                      setFieldValue("dateFormat", event.target.value);
                    }}
                    variant="outlined"
                    disableUnderline
                    className={styles.formats}
                  >
                    {<MenuItem value={""} disabled style={{ display: "none" }}>DD/MM/YY</MenuItem>}
                    {dateFormatI.map((formatObj: any) => {
                      return <MenuItem key={formatObj.value} value={formatObj.value} >{formatObj.text}</MenuItem>;
                    }
                    )}

                  </Select >
                </InputLabel>
              </div>

              <div className={styles.twoFieldsRow}>
                <InputLabel className={clsx(styles.fieldLabel, styles.appsField)} >
                  {t`Enable CAPD`}
                  <div>
                    {t`disable`}
                    <CoolSwitch
                      color="default"
                      disabled
                      checked={enableCAPD}
                    />
                    {t`enable`}
                  </div>
                </InputLabel>

              </div>
              {user && !!accessibleSites.length && <div className={styles.formatContainer}>
                <Divider className={styles.divider} orientation="horizontal" />
                <Typography className={styles.fieldLabel}>{t`Accessible Sites`}</Typography>
                <TableContainer>
                  <Table stickyHeader className="" aria-label="customized table">
                    <TableHead className={styles.tableHead}>
                      <TableRow>
                        <TableCell className={styles.headCells}>{t`Customer`}</TableCell>
                        <TableCell className={styles.headCells}>{t`Site`}</TableCell>
                        <TableCell className={styles.headCells}>{t`Site Packages`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accessibleSites.map((site: any) => {
                        const { customer, name, id, packages } = site;
                        return (
                          <TableRow hover tabIndex={-1} key={id}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={styles.rowCell}
                            >
                              {customers[customer]?.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={styles.rowCell}
                            >
                              {name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={clsx(styles.rowCell, styles.packagesCell)}
                            >
                              {getPackagesNames(packages)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>}
              {user && <div className={styles.newBlock}>
                <div className={styles.changePassword} onClick={() => setPasswordOpen(!passwordOpen)}>
                  <Typography className={styles.passwordTitle}>{t`Change Password`}</Typography>
                  <SvgArrow />
                </div>
              </div>}

              {!!errorText && (
                <Typography className={styles.error}>{errorText}</Typography>
              )}
              <div className={styles.actionsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  className={styles.cancelBtn}
                >
                  {viewAsComponent ? t`Back` : t`Cancel`}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={styles.submitButton}
                >
                  {mainButtonLabel}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      {passwordOpen && <Dialog
        open={true}
        onClose={closePasswordChange}
        maxWidth="xs"
        fullWidth
      >
        <div className={styles.dialogHeader}>
          <Typography className={styles.headerTitle}>{t`Change Password`}</Typography>
          <IconButton disableRipple className={styles.iconBtnStyle} onClick={closePasswordChange}>
            <Close color="#7f7692" />
          </IconButton>
        </div>
        <Formik
          initialValues={{ newPassword: "", repeatPassword: "" }}
          onSubmit={onPasswordChangeSubmit}
          enableReinitialize={true}
          validationSchema={PasswordSchema}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {(props) => {
            const { errors, handleSubmit, handleChange }: any = props;
            return (
              <form onSubmit={handleSubmit} className={styles.dialogContent}>
                <div className={styles.PasswordChangeContent}>

                  <TextField
                    label={t`New Password`}
                    name="newPassword"
                    error={errors.newPassword}
                    helperText={
                      errors.newPassword
                        ? errors.newPassword
                        : t`8+ characters, 1 capital, 1 number`
                    }
                    variant='standard'
                    fullWidth
                    type="password"
                    onChange={handleChange}
                    {...props}
                  />
                  <TextField
                    label={t`Confirm New Password`}
                    name="repeatPassword"
                    error={errors.repeatPassword}
                    helperText={
                      errors.repeatPassword
                        ? errors.repeatPassword
                        : t`8+ characters, 1 capital, 1 number`
                    }
                    variant="standard"
                    type="password"
                    fullWidth
                    onChange={handleChange}
                    {...props}
                  />

                  <div className={styles.actionsContainer}>
                    {passwordBox}
                    <CoolButton
                      onClick={closePasswordChange}
                      width={150}
                      white
                      marginRight
                    >
                      {t`Cancel`}
                    </CoolButton>
                    <CoolButton
                      type="submit"
                      width={150}
                    // disabled={!dirty}

                    >
                      {t`Save Password`}
                    </CoolButton>
                  </div>
                </div>

              </form>
            );
          }}
        </Formik>

      </Dialog>}
    </>
  );
}
