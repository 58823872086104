
import { makeStyles } from '@mui/styles';

const useStyles: any = makeStyles((theme: any) => ({
  buttonStyle: {
    width: "200px",
    height: "40px",
    borderRadius: "4px",
    background: theme.gradiants.button,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#fdfdfe",
    margin: 0,
    marginBottom: "15px",
    padding: 0
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  tableHead: {
    height: "45px"
  },
  headCells: {
    border: "none",
    borderBottom: "none",
    color: theme.tables.headerCell.color,
    fontWeight: theme.tables.headerCell.fontWeight,
    fontFamily: theme.tables.headerCell.fontFamily,
    lineHeight: theme.tables.headerCell.lineHeight,
    letterSpacing: theme.tables.headerCell.letterSpacing,
    backgroundColor: theme.tables.headerCell.backgroundColor,
    textTransform: theme.tables.headerCell.textTransform,
    height: 45,
    fontSize: 13
  },
  rowCell: {
    fontFamily: theme.tables.tableCell.fontFamily,
    color: theme.tables.tableCell.color,
    fontSize: theme.tables.tableCell.fontSize,
    padding: theme.tables.tableCell.padding
  },
  paperPadding: {
    padding: "20px 21px",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    minHeight: "250px"
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px"
  }, searchAdorment: {
    marginLeft: "-10px",
    marginRight: "12px"
  },
  searchIcon: {
    transform: "scale(0.7)"
  },
  closeIconStyle: {
    color: "white",
    padding: "7px",
    "& svg": {
      fontSize: "24px"
    }
  },

}));
export default useStyles;
