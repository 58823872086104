import {
  Admin,
  Customer,
  User,
  xhr,
  GlobalAdmin,
} from "coolremote-sdk";
import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { t } from "ttag";
import * as Yup from "yup";
import { getPhoneValidation } from "../utils/validations";

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().max(20, t`Too long!`),
  lastName: Yup.string().max(20, t`Too long!`),
  email: Yup.string()
    .email(t`Invalid email`)
    .required(t`Required`),
  username: Yup.string()
    .min(6, t`Too short!`)
    .max(50, t`Too long!`)
    .required(t`Required`),
  phone: getPhoneValidation(),
  timeFormat: Yup.number(),
  dateFormat: Yup.number(),
});

export const InviteSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(t`Required`)
    .max(20, t`Too long!`),
  lastName: Yup.string()
    .required(t`Required`)
    .max(20, t`Too long!`),
  email: Yup.string()
    .email(t`Invalid email`)
    .required(t`Required`),
  customer: Yup.string()
    .nullable(true)
    .required(t`Required`),
});

export const UserSchemaNew = Yup.object().shape({
  firstName: Yup.string()
    .required(t`Required`)
    .max(20, t`Too long!`),
  lastName: Yup.string()
    .required(t`Required`)
    .max(20, t`Too long!`),
  email: Yup.string()
    .email(t`Invalid email`)
    .required(t`Required`),
  phone: getPhoneValidation(),
  password: Yup.string()
    .matches(
      /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      t`must be at least 8+ characters, 1 capital, 1 number`
    )
    .required(t`Required`),
  customer: Yup.mixed()
    .nullable(true)
    .required(t`Required`),
  timeFormat: Yup.number(),
  dateFormat: Yup.number(),
});

export interface IUserModel {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  role?: string;
  customer?: string;
  site?: string;
  temperatureScale?: number; // 0 - undefined, 1 - celcius, 2 - fahrenheit
  permissions?: any;
  language?: string;
  isLoggedIn: boolean;
  measurementUnits?: number;

  timeFormat?: number;
  dateFormat?: number;
  enableMgmtAppAccess?: boolean;
  enableServiceAppAccess?: boolean;
  enableCommercialAppAccess?: boolean;
  options?: any;
}

export interface IUserMap {
  [key: string]: IUserModel;
}

interface ILoginPayload {
  username: string;
  password: string;
}
export interface IUsersModel {
  me: IUserModel;
  otherUsers: IUserMap;
  setMe: Action<IUsersModel, { me: IUserModel }>;
  getCustomerUsers: Thunk<IUsersModel, String>;
  setUsers: Action<IUsersModel, { users: any }>;
  createUser: Thunk<IUsersModel, unknown>;
  addUser: Action<IUsersModel, IUserModel>;
  updateUser: Thunk<IUsersModel, unknown>;
  _updateUser: Action<IUsersModel, IUserModel>;
  deleteUser: Thunk<IUsersModel, string>;
  removeUser: Action<IUsersModel, string>;
  login: Thunk<IUsersModel, ILoginPayload>;
  checkLoggedin: Thunk<IUsersModel, void>;
  logout: Thunk<IUsersModel, void>;
  myFullName: Computed<IUsersModel, string>;

  setWasLoaded: Action<IUsersModel, string>;
  dataState: string;
  canLoggedInUserViewTriggerTemplates: Computed<IUsersModel, boolean>;
  canLoggedInUserViewTriggers: Computed<IUsersModel, boolean>;
  getFullName: Computed<IUsersModel, (userId: string) => string>;
  getUsername: Computed<IUsersModel, (userId: string) => string>;
  isLoggedInUserCustomerUser: Computed<IUsersModel, () => boolean>;
  timeFormat?: any;
  dateFormat?: any;
  getUsersBySearchValue: Thunk<IUsersModel, String>;
  // getLiteTree: Thunk<IUsersModel, void>;
  updatePassword: Thunk<IUsersModel, { userId: string; data: any }>;
  createInvite: Thunk<IUsersModel, { customerId: string; inviteObj: object }>;
  mySupportedPages: Computed<IUsersModel, any>;
  getRecoveryToken: Thunk<IUsersModel, string>;
  getUserSites: Thunk<IUsersModel, string>;
  getUserByUsername: Thunk<IUsersModel, { username: string }>;
  getUserFullPolicies: Thunk<IUsersModel, { userId?: string }>;
}

export const usersModel: IUsersModel = {
  me: { id: "", isLoggedIn: false },
  otherUsers: {},
  dataState: "loading",
  setWasLoaded: action((state, payload) => {
    state.dataState = payload;
  }),

  setMe: action((state, payload: any) => {
    // locale.setLocale(payload.language || "en");
    state.me = payload;
  }),

  getCustomerUsers: thunk((actions, payload) => {
    return Customer.getUsers(payload);
    // return GlobalAdmin.getCustomerUsers(payload);
  }),
  // getLiteTree: thunk(async (actions, payload, { getStoreActions }) => {
  //   const storeActions: any = getStoreActions();
  //   const liteTree = await Admin.getLiteTree();
  //   storeActions.selections.setFiltersTree(liteTree || {});
  // }),
  setUsers: action((state, payload) => {
    const fetchedUsers: IUserMap = _(Object.values(payload.users))
      .map((user: any) => {
        const addedUser: IUserModel = { ...user };

        return addedUser;
      })
      .keyBy("id")
      .value();

    state.otherUsers = fetchedUsers;
  }),
  isLoggedInUserCustomerUser: computed([state => state.me], me => () => {
    return !_.isUndefined(me.customer);
  }),
  createUser: thunk(async (actions, payload: any) => {
    // return GlobalAdmin.createUser(payload.data)
    return Customer.createUser(payload.id, payload.data)
      .then((data: any) => {
        actions.addUser(data);
        return data;
      })
      .catch((error: any) => {
        return { err: error.message };
      });
  }),

  addUser: action((state, payload) => {
    state.otherUsers[payload.id] = payload;
  }),

  updateUser: thunk(async (actions, payload: any) => {
    // return GlobalAdmin.updateUser(payload.id, payload.data)
    return User.update(payload.id, payload.data)
      .then((data: any) => {
        actions._updateUser(data);
        return true;
      })
      .catch((error: any) => {
        return { err: error.message };
      });
  }),

  _updateUser: action((state, payload) => {
    state.otherUsers[payload.id] = payload;
  }),

  deleteUser: thunk(async (actions, payload) => {
    // return GlobalAdmin.deleteUser(payload)
    return User.delete(payload)
      .then(() => {
        actions.removeUser(payload);
        return true;
      })
      .catch(() => false);
  }),

  removeUser: action((state, payload) => {
    delete state.otherUsers[payload];
  }),

  login: thunk(async (actions, payload, { getStoreActions }) => {
    const { username, password } = payload;
    const storeActions: any = getStoreActions();
    const { setLoggedIn } = storeActions;

    await actions.logout();
    // const data = await User.connect(username, password, "CoolConsole");
    const data: any = await GlobalAdmin.auth(username, password);
    // if (data.success === false) {
    if (!data) {
      return data;
    }

    const token = data.token; // data.data.token;

    xhr.setToken(token);

    // fetch user
    const user = await GlobalAdmin.getMe();

    if (!user) {
      setLoggedIn(false);
      actions.setWasLoaded("done");
      return;
    }

    const updatedUser = { ...user, isLoggedIn: true };
    localStorage.setItem("token", token);

    actions.setMe(updatedUser);
    setLoggedIn(true);
    actions.setWasLoaded("done");
    return updatedUser;
  }),
  checkLoggedin: thunk(async (actions, payload, { getStoreActions }) => {
    const token = localStorage.getItem("token");
    const storeActions: any = getStoreActions();
    const { setLoggedIn } = storeActions;

    if (!token) {
      actions.logout();
      actions.setWasLoaded("done");
      return;
    }

    xhr.setToken(token);
    let user: any;
    GlobalAdmin.getMe()
      .then((res: any) => {
        user = res;
        const updatedUser = { ...user, isLoggedIn: true };
        actions.setMe(updatedUser);
        if (!user) {
          actions.logout();
          actions.setWasLoaded("done");
          return;
        }
      })
      .catch((err: any) => {
        actions.logout();
        actions.setWasLoaded("done");
        return false;
      });

    setLoggedIn(true);
    actions.setWasLoaded("done");

    return true;
  }),
  logout: thunk(async (actions, payload, { getStoreActions }) => {
    const updatedUser: any = { id: "", isLoggedIn: false };
    actions.setMe(updatedUser);

    localStorage.removeItem("token");

    const storeActions: any = getStoreActions();
    storeActions.setIsLoaded(true);
    User.logout();
  }),
  canLoggedInUserViewTriggerTemplates: computed([state => state.me], me => {
    if (me.permissions === "globalAdmin") {
      return true;
    }

    return false;
  }),
  canLoggedInUserViewTriggers: computed([state => state.me], me => {
    // Currently everyone can view triggers.
    return true;
  }),
  getFullName: computed(
    [state => state.me, state => state.otherUsers],
    (me, users) => userId => {
      if (_.isNil(users[userId])) return "-";
      const user = users[userId];
      return _createUserNamePresentation(
        user.username,
        user.firstName,
        user.lastName
      );
    }
  ),
  myFullName: computed(
    [state => state.me, state => state.otherUsers],
    (me, users) => {
      return _createUserNamePresentation(
        me.username,
        me.firstName,
        me.lastName
      );
    }
  ),
  getUsername: computed(
    [state => state.me, state => state.otherUsers],
    (me, users) => userId => {
      if (_.isNil(users[userId]))
        return !_.isNil(me.username) && me.id === userId ? me.username : "-";
      return users[userId].username || "-";
    }
  ),
  timeFormat: computed(
    [(state: any) => state.me, (state, storeState: any) => storeState.types],
    (me, types) => {
      if (!types || !types.timeFormat) {
        return "HH:mm";
      }
      const { timeFormat: selectedTime = "0" } = me;
      const timeFormatObject = types.timeFormat[selectedTime];
      if (timeFormatObject.text === "24 hours") {
        return "HH:mm";
      }
      if (timeFormatObject.text === "12 hours") {
        return "hh:mma";
      }
    }
  ),
  dateFormat: computed(
    [(state: any) => state.me, (state, storeState: any) => storeState.types],
    (me, types) => {
      if (!types || !types.dateFormat) {
        return "DD/MM/YY";
      }
      const { dateFormat: selectedDate = "0" } = me;
      return types.dateFormat[selectedDate].text;
    }
  ),

  getUsersBySearchValue: thunk(async (actions, payload) => {
    const users = Admin.getUsersBySearchValue(payload);
    return users;
  }),
  updatePassword: thunk(async (actions, payload) => {
    return GlobalAdmin.changeUserPassword(payload.userId, payload.data);
  }),
  createInvite: thunk(async (actions, payload, { injections }) => {
    const { customerId, inviteObj } = payload;
    return Admin.createInvite(customerId, inviteObj);
  }),
  mySupportedPages: computed([state => state.me], me => {
    if (me?.options?.pages === "all") {
      return "all";
    } else {
      const pages: any = me?.options?.pages.reduce((vals: any, item: any) => {
        return { ...vals, [item.toLowerCase()]: true };
      }, {});
      return pages;
    }
  }),
  getRecoveryToken: thunk(async (actions, payload) => {
    const data = payload;
    const type = "email";
    return User.getRecoveryToken(data, type);
  }),
  getUserSites: thunk(async (actions, payload) => {
    const data = await Admin.getUserSites(payload);
    return data;
  }),

  getUserByUsername: thunk(async (actions, payload) => {
    const users = GlobalAdmin.getUserByUsername(payload.username);
    return users;
  }),

  getUserFullPolicies: thunk(async (actions, payload) => {
    const users = GlobalAdmin.getUserFullPolicies(payload.userId);
    return users;
  }),
};

function _createUserNamePresentation(
  username?: string,
  firstName?: string,
  lastName?: string
) {
  if (firstName) {
    if (lastName) {
      return firstName + " " + lastName;
    } else {
      return firstName;
    }
  } else {
    return username || "no name";
  }
}
