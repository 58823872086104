import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
    Popover
} from "@mui/material/";
import { Close, Search } from "@mui/icons-material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DeviceRecord.style";
import { GlobalAdmin } from "coolremote-sdk";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import CreateRecord from "./CreateRecord";
import ProductionData from "./ProductionData";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";
import Button from "../../cool_widgets/Button";
import CoolDateRangePicker from "../../components/CoolDateRangePicker/CoolDateRangePicker";
import { Arrow as SvgArrow, Calendar } from "../../icons/";
import moment from "moment";
import ExportDeviceRecord from "./ExportDeviceRecord";
const minDate = new Date("1900-01-01");

interface ISearchParam {
    startSerial?: string;
    endSerial?: string;
    numberOfDevices?: string;
    familyId?: string;
    dateRange: IDateRange
}

interface IDateRange {
    startDate: Date;
    endDate: Date;
}


const DeviceRecord = (props: any) => {
    const styles: any = useStyles();
    const [records, setRecords] = useState<any>({});
    const { addMessage } = useStoreActions((action) => action.errorMessage);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showProductionModal, setShowProductionModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [families, setFamilies] = useState<any>([]);
    const [searchType, setSearchType] = useState<number>(3);
    const [searchParams, setSearchParams] = useState<ISearchParam>({ numberOfDevices: "1", dateRange: { startDate: new Date(), endDate: new Date() } });
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        sdkGlobalAdmin.getDevicefamilies()
            .then((res: any) => { setFamilies(res) });
    }, []);

    const handleProductionModalApply = () => {
        setShowProductionModal(false)
        doSearch();
    }

    const handleCreateModalApply = () => {
        setShowCreateModal(false);
        doSearch();
    }

    const handleNewDateRange = (range: IDateRange | null) => {
        // If null - user chose Cancel, so we don't change current selection
        if (range) {
            setSearchParams({ ...searchParams, dateRange: range });
        }
        setAnchorEl(null);
    };


    const printDateRange = (range: IDateRange | null) => {
        if (_.isNil(range)) {
            return "no selection";
        }
        return `${moment(range.startDate).format("ll")} - ${moment(range.endDate).format("ll")}`;
    };

    const doSearch = () => {
        const { familyId, startSerial, endSerial, numberOfDevices, dateRange } = searchParams;
        if ((searchType == 3) && familyId) {
            setIsLoading(true);
            return GlobalAdmin.getDeviceRecordsByFamilyId({ familyId, startDate: dateRange?.startDate.getTime(), endDate: dateRange?.endDate.getTime() })
                .then((res: any) => {
                    setRecords(res)
                })
                .catch((err: any) => addMessage({ message: err.message }))
                .finally(() => setIsLoading(false))
        }
        if ((searchType == 2) && startSerial && endSerial) {
            setIsLoading(true);
            return GlobalAdmin.getDeviceRecordsBySerialRange(startSerial, endSerial)
                .then((res: any) => {
                    setRecords(res)
                })
                .catch((err: any) => addMessage({ message: err.message }))
                .finally(() => setIsLoading(false))
        }
        if ((searchType == 1) && startSerial && numberOfDevices) {
            setIsLoading(true);
            return GlobalAdmin.getDeviceRecordsByStartSerialAndCount(startSerial, numberOfDevices)
                .then((res: any) => {
                    setRecords(res)
                })
                .catch((err: any) => addMessage({ message: err.message }))
                .finally(() => setIsLoading(false))
        }
    }



    const RenderSearchInput = () => {
        switch (searchType) {
            case 1:
                return <div><TextField
                    label={t`Start Serial`}
                    id="startSerial"
                    className={styles.smallField}
                    variant={'outlined'}
                    onChange={(event) => { setSearchParams({ ...searchParams, startSerial: event?.target.value }) }}
                    value={searchParams.startSerial}
                    {...props}
                />
                    <TextField
                        label={t`Number Of Devices`}
                        id="numberOfDevices"
                        className={styles.smallField}
                        variant={'outlined'}
                        type="number"
                        onChange={(event) => { setSearchParams({ ...searchParams, numberOfDevices: event?.target.value }) }}
                        value={searchParams.numberOfDevices}
                        {...props}
                    />
                </div>
            case 2:
                return <div>
                    <TextField
                        label={t`Start Serial`}
                        id="startSerial"
                        className={styles.smallField}
                        variant={'outlined'}
                        onChange={(event) => { setSearchParams({ ...searchParams, startSerial: event?.target.value }) }}
                        value={searchParams.startSerial}
                        {...props}
                    />
                    <TextField
                        label={t`End Serial`}
                        id="endSerial"
                        className={styles.smallField}
                        variant={'outlined'}
                        onChange={(event) => { setSearchParams({ ...searchParams, endSerial: event?.target.value }) }}
                        value={searchParams.endSerial}
                        {...props}
                    />
                </div>
            case 3:
                return <div>
                    <Select
                        value={searchParams.familyId}
                        onChange={(event: any) => {
                            setSearchParams({ ...searchParams, familyId: event.target.value });
                        }}
                        variant="outlined"
                        className={styles.smallField}
                    >
                        {Object.values(families).map((family: any) => {
                            return <MenuItem key={family.id} value={family.id} >{family.name}</MenuItem>;
                        }
                        )}
                    </Select >
                    <React.Fragment>
                        <Button
                            disableRipple
                            className={styles.selectDateRangeButton}
                            classes={{
                                endIcon: styles.Arrow
                            }}
                            onMouseUp={handleClick}
                            endIcon={<SvgArrow />}
                        >
                            <Typography className={styles.selectDateRangeButton__text}>
                                {printDateRange(searchParams.dateRange)}
                            </Typography>
                        </Button>

                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                            }}
                            transformOrigin={{
                                vertical: -10,
                                horizontal: "left"
                            }}
                        >
                            <CoolDateRangePicker
                                handleSubmit={handleNewDateRange}
                                initialRange={searchParams.dateRange}
                                minDate={minDate}
                                noRangeLimit={false}
                            />
                        </Popover>
                    </React.Fragment>
                </div>
            default:
                return null;
        }
    }

    return (
        <div className={styles.view}>
            <ServiceNavigationBar
                title={t`Device Records`}
                {...props}
            >
                <div className={styles.sectionsButtonsContainer}>
                    <div >
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                                color: 'primary.main',
                            }}
                        >
                            {t`Search`}
                        </Typography>
                        <div className={styles.searchContainer}>
                            <Select
                                displayEmpty
                                disabled={false}
                                value={searchType}
                                onChange={(event: any) => {
                                    setSearchType(event.target.value);
                                }}
                                variant="outlined"
                                className={styles.mediumField}
                            >
                                <MenuItem key={"searchOption1"} value={1} >{t`Start serial & Number of records`}</MenuItem>
                                <MenuItem key={"searchOption2"} value={2} >{t`Start serial & End serial`}</MenuItem>
                                <MenuItem key={"searchOption3"} value={3} >{t`Family`}</MenuItem>
                            </Select >
                            {RenderSearchInput()}
                            <IconButton className={styles.searchButton} onClick={doSearch}>
                                <Search />
                            </IconButton>
                        </div>
                    </div>

                    <div>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                                color: 'primary.main',
                            }}
                        >
                            {t`Insert Data`}
                        </Typography>
                        <div className={styles.buttonsContainer}>
                            <Button
                                className={styles.buttonStyle}
                                onClick={() => setShowCreateModal(true)}
                            >{t`Create`}</Button>
                            <Button
                                className={styles.buttonStyle}
                                onClick={() => setShowProductionModal(true)}
                            >{t`Production Data`}</Button>
                        </div>
                    </div>

                    <div>
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                                color: 'primary.main',
                            }}
                        >
                            {t`Export`}
                        </Typography>

                        <div className={styles.buttonsContainer}>
                            <Button
                                className={styles.buttonStyle}
                                onClick={() => setShowExportModal(true)}
                            >{t`Stickers`}</Button>
                        </div>

                    </div>

                </div>


                <div className={styles.contentArea}>

                    {isLoading ?
                        <div className={styles.loaderContainer}>
                            <CircularProgress className={styles.loader} />
                        </div>
                        :
                        <Grid container className={styles.content}>
                            <Grid item xs={12}>
                                <TableContainer >
                                    <Table stickyHeader className="" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={styles.headCells}
                                                >
                                                    <div className={styles.headContainer}>
                                                        {t`Serial`}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={styles.headCells}
                                                >
                                                    <div className={styles.headContainer}>
                                                        {t`Family`}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={styles.headCells}
                                                >
                                                    <div className={styles.headContainer}>
                                                        {t`FW Version`}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={styles.headCells}
                                                >
                                                    <div className={styles.headContainer}>
                                                        {t`Boot Version`}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    className={styles.headCells}
                                                >
                                                    <div className={styles.headContainer}>
                                                        {t`Production Date`}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.values(records).map((record: any) => {
                                                const { id, serial, deviceFamily, deviceFWversion, devicebootloaderVersion, productionDate } = record
                                                return (
                                                    <TableRow hover tabIndex={-1} key={id} onDoubleClick={() => { }} >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            className={styles.rowCell}
                                                        >
                                                            {serial}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            className={styles.rowCell}
                                                        >
                                                            {families[deviceFamily].name}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            className={styles.rowCell}
                                                        >
                                                            {deviceFWversion}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            className={styles.rowCell}
                                                        >
                                                            {devicebootloaderVersion}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left"
                                                            className={styles.rowCell}
                                                        >
                                                            {new Date(productionDate).toDateString()}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>}
                </div>
            </ServiceNavigationBar>
            {showCreateModal &&
                <SimpleModal showModal={showCreateModal} title={t`Create`} hideBtns closeModal={() => setShowCreateModal(false)} fullWidth={true}>
                    <IconButton disableRipple className={styles.iconBtnStyle} onClick={() => setShowCreateModal(false)}>
                        <Close />
                    </IconButton>
                    <CreateRecord
                        families={families}
                        onCancel={() => setShowCreateModal(false)}
                        onApply={handleCreateModalApply}
                    />
                </SimpleModal>
            }
            {showProductionModal &&
                <SimpleModal showModal={showProductionModal} title={t`Production Data`} hideBtns closeModal={() => setShowProductionModal(false)} fullWidth={true}>
                    <IconButton disableRipple className={styles.iconBtnStyle} onClick={() => setShowProductionModal(false)}>
                        <Close />
                    </IconButton>
                    <ProductionData
                        onCancel={() => setShowProductionModal(false)}
                        onApply={handleProductionModalApply}
                    />
                </SimpleModal>
            }

            {showExportModal &&
                <SimpleModal showModal={showExportModal} title={t`Export`} hideBtns closeModal={() => setShowExportModal(false)} fullWidth={true}>
                    <IconButton disableRipple className={styles.iconBtnStyle} onClick={() => setShowExportModal(false)}>
                        <Close />
                    </IconButton>
                    <ExportDeviceRecord
                        close={() => setShowExportModal(false)}
                    />
                </SimpleModal>
            }
        </div >
    );
};

export default DeviceRecord;
