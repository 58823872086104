import { Clear } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import Checkbox from "../../cool_widgets/CoolCheckbox";
import { EditIcon } from "../../iconImages";
import { ArrowDownO, CheckboxChecked, IndeterminateCheckbox } from "../../icons";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField"
import Site from "../SiteManagement/Site";
import style from "./EditCustomer.module.css";

const CustomEditCustomer = (props: any) => {
  const {
    history,
    viewAsComponent = false,
    submit,
    selectedCustomer
  } = props;

  const customerId = selectedCustomer?.id;
  const [customer, setCustomer] = useState<any>(selectedCustomer);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [enablePowerDistribution, setEnablePowerDistribution] = useState<boolean>(false);
  const [enableMgmtAppAccess, setEnableMgmtAppAccess] = useState<boolean>(false);
  const [enableServiceAppAccess, setEnableServiceAppAccess] = useState<boolean>(false);
  const [enableCommercialAppAccess, setEnableCommercialAppAccess] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(!customerId ? true : false);
  const types = useStoreState((s) => s.types);
  const { customerLevelPackagesEnum, regionTypes, journyPhaseTypes } = types;
  const [error, setError] = useState<string>("");
  const [updatedPackageData, setUpdatedPackageData] = useState<any>({});
  const [initPackages, setInitPackages] = useState<any>({});
  const [basePackage, setBasePackage] = useState<number>(customerLevelPackagesEnum.none.value);
  const [advancedOperationsPackage, setAdvancedOperationsPackage] = useState<any>(customerLevelPackagesEnum.none.value);
  const [remoteServicePackage, setRemoteServicePackage] = useState<any>(customerLevelPackagesEnum.none.value);
  const [predictiveMaintenancePackage, setPredictiveMaintenancePackage] = useState<any>(customerLevelPackagesEnum.none.value);
  const [commercialBasePackage, setCommercialBasePackage] = useState<any>(customerLevelPackagesEnum.none.value);
  const [commercialWebPackage, setCommercialWebPackage] = useState<any>(customerLevelPackagesEnum.none.value);
  const createCustomer = useStoreActions((actions) => actions.customers.createCustomer);
  const getCustomerById = useStoreActions((actions) => actions.customers.getCustomerById);
  const customers = useStoreState((state) => state.customers.customersBasicList);
  const updateCustomer = useStoreActions((actions) => actions.customers.updateCustomer);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const getCustomerCategories = useStoreActions((actions) => actions.getCustomerCategories);
  const customerCategoriesOptions = useStoreState((state) => state.customerCategoriesOptions);
  const customerCategories = useStoreState((state) => state.customerCategories);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const { mySupportedPages = "all" } = useStoreState((s) => s.users);
  const showAllPages = mySupportedPages === "all";
  const getAllAdminUsers = useStoreActions((action) => action.adminUsers.getAllAdminUsers);
  const [adminOptions, setAdminOptions] = useState<any>([]);

  console.log('selectedCustomer   ', selectedCustomer)
  console.log('customerId   ', customerId)
  useEffect(() => {
    getAllAdminUsers()
      .then((res: any) => {
        setAdminOptions(Object.entries(res).map(([userId, { firstName = "", lastName = "" }]: any) => ({
          value: userId,
          label: `${firstName} ${lastName}`
        })))
      });

  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required(t`Name is required`),
    discription: Yup.string(),
    region: Yup.number().notOneOf([regionTypes["unknown"]], "Region is required")
  });
  useEffect(() => {
    (async () => {
      if (!customerId) {
        return;
      }
      getCustomerById(customerId).then((customerRes: any) => {
        const { packages, ...customerInfo } = customerRes;
        setInitPackages(packages);
        setBasePackage(packages.basePackageEnabled);
        setAdvancedOperationsPackage(packages.hvacAdvancedOperations);
        setPredictiveMaintenancePackage(packages.predictiveMaintenance);
        setEnablePowerDistribution(!!customerInfo.enablePowerDistribution);
        setEnableMgmtAppAccess(!!customerInfo.enableMgmtAppAccess);
        setEnableServiceAppAccess(!!customerInfo.enableServiceAppAccess);
        setEnableCommercialAppAccess(!!customerInfo.enableCommercialAppAccess);
        setCustomer({ ...customer, ...customerInfo });
      }).catch(({ message }: any) => {
        addMessage({ message });
      });
    })();
  }, [customerId, customers, getCustomerById]);

  useEffect(() => {
    if (!customerCategoriesOptions.length) { getCustomerCategories(); }
  }, [])

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const saveCustomer = (values: any) => {
    // delete values.ownerCS
    // delete values.ownerCA
    setError("");

    setSubmitting(true);
    values.enablePowerDistribution = enablePowerDistribution;
    values.enableMgmtAppAccess = enableMgmtAppAccess;
    values.enableServiceAppAccess = enableServiceAppAccess;
    values.enableCommercialAppAccess = enableCommercialAppAccess;
    values.packages = !_.isEmpty(updatedPackageData) ? updatedPackageData :
      {
        basePackageEnabled: !!basePackage,
        hvacAdvancedOperations: !!advancedOperationsPackage,
        predictiveMaintenance: !!predictiveMaintenancePackage,
      };

    updateCustomer({ id: customerId, data: values })
      .then(() => {
        setEditable(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };


  const onCreateCustomer = async (values: any) => {
    // delete values.ownerCS
    // delete values.ownerCA
    setSubmitting(true);
    values.enablePowerDistribution = enablePowerDistribution;
    values.enableMgmtAppAccess = enableMgmtAppAccess;
    values.enableServiceAppAccess = enableServiceAppAccess;
    values.enableCommercialAppAccess = enableCommercialAppAccess;

    createCustomer({ data: values })
      .then((res: any) => {
        setSubmitting(false);
        !viewAsComponent && history.push(`/customers`);
        submit && submit(res?.id)
      })
      .catch((err: any) => {
        setSubmitting(false);
        addMessage({ message: err.message });
      })

  };


  const regionOptions: any = useMemo(()=>{
    return Object.keys(regionTypes).sort().map((region: any) => {
      return {
        value: types.regionTypes[region],
        label: region,
        disabled: region === "unknown"
      };
    });
  }, [regionTypes, types]) 

  const journyPhaseOptions: any = useMemo(()=>{ 
    return Object.keys(journyPhaseTypes).map((phase: any) => {
    return {
      value: types.journyPhaseTypes[phase],
      label: phase,
      disabled: phase === "unknown"
    };
  });
  }, [journyPhaseTypes, types]) 
  
  const Checkbox1 = (props: any) => {
    return <Checkbox
      color="default"
      edge="end"
      variant="outlined"
      disabled={!editable}
      onClick={(event: any) => event.stopPropagation()}
      checkedIcon={<CheckboxChecked />}
      indeterminateIcon={<IndeterminateCheckbox />}
      className={style.smallCheckbox}
      {...props}
    />;
  };

  const handlePackageChange = (value: number, type: string, packageFlag: string, setThisPackage?: any) => {
    const checked = value === customerLevelPackagesEnum.all.value;

    if (type === "base") {
      if (checked) {
        setBasePackage(customerLevelPackagesEnum.none.value);
        setUpdatedPackageData({ ...updatedPackageData, [packageFlag]: false });
      } else {
        setBasePackage(customerLevelPackagesEnum.all.value);
        setAdvancedOperationsPackage(customerLevelPackagesEnum.none.value);
        setRemoteServicePackage(customerLevelPackagesEnum.none.value);
        setPredictiveMaintenancePackage(customerLevelPackagesEnum.none.value);
        setCommercialBasePackage(customerLevelPackagesEnum.none.value);
        setCommercialWebPackage(customerLevelPackagesEnum.none.value);

        setUpdatedPackageData({
          ...updatedPackageData,
          [packageFlag]: true,
          hvacAdvancedOperations: false,
          predictiveMaintenance: false
        });
      }
    }
    if (type === "professional") {
      if (checked) {
        setThisPackage(customerLevelPackagesEnum.none.value);
        setUpdatedPackageData({ ...updatedPackageData, [packageFlag]: false });
      } else {
        setBasePackage(customerLevelPackagesEnum.none.value);
        setThisPackage(customerLevelPackagesEnum.all.value);
        setUpdatedPackageData({
          ...updatedPackageData,
          [packageFlag]: true,
          "basePackageEnabled": false
        });
      }
    }

  };

  const resetPackages = () => {
    setBasePackage(initPackages.basePackageEnabled);
    setAdvancedOperationsPackage(initPackages.hvacAdvancedOperations);
    setPredictiveMaintenancePackage(initPackages.predictiveMaintenance);
  };

  return (
    <div className={clsx(style.editCustomerPageContainer, { [style.pageContainerAsComponent]: viewAsComponent })}>
      <div className={clsx(style.editCustomerPaper, { [style.fullWidthPaper]: viewAsComponent })}>
        <Formik
          initialValues={{
            description: customer ? customer.description : "",
            name: customer ? customer.name : "",
            region: customer?.region || regionTypes["unknown"],
            category: customer ? customer.category : customerCategories.residential,
            enableAPI: customer ? customer.enableAPI : false,
            CRMReferenceID: customer ? customer.CRMReferenceID : "",
            ownerCA: customer ? customer.ownerCA : "",
            ownerCS: customer ? customer.ownerCS : "",
            journyPhase: customer ? customer.journyPhase : "",
          }}
          enableReinitialize={true}
          onSubmit={!customerId ? onCreateCustomer : saveCustomer}
          validationSchema={validationSchema}
        >
          {(formProps) => {
            const {
              handleSubmit,
              handleReset
            } = formProps;
            return (
              <form onSubmit={handleSubmit} style={{ backgroundColor: "white" }}>
                {!viewAsComponent && <div className={style.customerDetailsHeader}>
                  <Typography className={style.editCustomerTitle}>
                    {t`Customer Details`}
                  </Typography>
                  <Typography className={style.errorMessage}>{error}</Typography>
                  {isSubmitting && (
                    <CircularProgress className={style.loader} />
                  )}
                  {customerId && !editable && (
                    <IconButton onClick={() => setEditable(true)}>
                      <img src={EditIcon} alt="edit" />
                    </IconButton>
                  )}
                  {customerId && editable && (
                    <div style={{ display: "flex" }}>
                      <IconButton type="submit" disabled={isSubmitting}>
                        <Check className={style.customerDetailsIcons} />
                      </IconButton>
                      <IconButton
                        disabled={isSubmitting}
                        onClick={() => {
                          handleReset();
                          resetPackages();
                          setEditable(false);
                        }}
                      >
                        <Close className={style.customerDetailsIcons} />
                      </IconButton>
                    </div>
                  )}
                </div>}
                <div className={style.customerDetailsFieldsContainer}>
                  <AdvancedInputField
                    name="name"
                    label={t`Customer Name`}
                    editable={editable}
                    {...formProps}
                  />

                  {customerId && <div>
                    <Typography className={clsx(style.rowItem, !editable && style.clickable)} onClick={() =>
                      !editable && history.push(`/customers/${customer.id}/sites`)
                    } >
                      {t`Sites`} (<span className={style.rowDetails}>{customer?.numOfSites}</span>)
                    </Typography>

                    <Typography className={clsx(style.rowItem, !editable && style.clickable)} onClick={() => {
                      if (!editable && (showAllPages || mySupportedPages["devices"])) {
                        history.push(`/devices`)
                        setSelections({ customerId: customer.id, siteId: null, systemId: null, unitId: null });
                      }
                    }}>
                      {t`Devices`} (<span className={style.rowDetails}>{customer?.numOfDevices}</span>)
                    </Typography>

                    <Typography className={clsx(style.rowItem, !editable && style.clickable)} onClick={() => {
                      if (!editable && (showAllPages || mySupportedPages["users2"])) {
                        history.push(`/users2`)
                        setSelections({ customerId: customer.id, siteId: null, systemId: null, unitId: null });
                      }
                    }}>
                      {t`Users`} (<span className={style.rowDetails}>{customer?.numOfUsers}</span>)
                    </Typography>

                    <Typography className={clsx(style.rowItem, !editable && style.clickable)} onClick={() => {
                      if (!editable && (showAllPages || mySupportedPages["subscriptions-site"])) {
                        history.push(`/subscriptions-site`)
                        setSelections({ customerId: customer.id, siteId: null, systemId: null, unitId: null });
                      }
                    }}>
                      {t`Subscriptions`} (<span className={style.rowDetails}>{customer?.numOfSubscriptions}</span>)
                    </Typography>
                  </div>}

                  <AdvancedInputField
                    name="description"
                    label={t`Description`}
                    editable={editable}
                    {...formProps}
                  />
                  <AdvancedInputField
                    name="CRMReferenceID"
                    label={t`CRM Reference ID`}
                    editable={editable}
                    {...formProps}
                  />
                  <AdvancedInputField
                    select
                    name="region"
                    options={regionOptions}
                    label={t`Sales Territory`}
                    editable={editable}
                    {...formProps}
                  />
                  <AdvancedInputField
                    select
                    name="category"
                    options={customerCategoriesOptions}
                    label={t`Customer Profile`}
                    editable={editable}
                    {...formProps}
                  />
                  <AdvancedInputField
                    toggle
                    name="enableAPI"
                    label={t`Enable API`}
                    editable={editable}
                    {...formProps}
                  />
                  <AdvancedInputField
                    select
                    name="ownerCA"
                    label={t`Sales account owner`}
                    options={adminOptions}
                    editable={editable}
                    {...formProps}
                  />
                  <AdvancedInputField
                    select
                    name="ownerCS"
                    label={t`CS account owner`}
                    options={adminOptions}
                    editable={editable}
                    {...formProps}
                  />
                   <AdvancedInputField
                    select
                    name="journyPhase"
                    options={journyPhaseOptions}
                    label={t`Account journy phase`}
                    editable={editable}
                    {...formProps}
                  />
                  {customerId &&
                    <TreeView
                      disableSelection={!editable}
                      defaultExpanded={["Professional"]}
                      defaultCollapseIcon={<><div className={style.arrowContainer} /> <ArrowDownO className={style.arrow} /> </>}
                      defaultExpandIcon={<><div className={style.arrowContainer} /> <ArrowDownO className={clsx(style.arrow, style.rotateArrow)} /></>}
                      defaultEndIcon={<div className={style.arrowContainer} />}
                    >
                      <TreeItem nodeId="Professional"
                        label={
                          <div className={style.labelRoot}>
                            <Typography variant="body2" className={style.labelText}>
                              {t`Professional Packages`}
                            </Typography>
                          </div>
                        } >
                        <TreeItem nodeId="2"
                          label={
                            <div className={style.labelRoot}>
                              <Checkbox1
                                indeterminate={advancedOperationsPackage === customerLevelPackagesEnum.some.value}
                                checked={advancedOperationsPackage === customerLevelPackagesEnum.all.value}
                                onChange={() => handlePackageChange(advancedOperationsPackage, "professional", "hvacAdvancedOperations", setAdvancedOperationsPackage)}
                              />
                              <Typography variant="body2" className={style.labelText}>
                                {t`Advanced HVAC Operations`}
                              </Typography>
                            </div>
                          } />
                        <TreeItem nodeId="4"
                          label={
                            <div className={style.labelRoot}>
                              <Checkbox1
                                indeterminate={predictiveMaintenancePackage === customerLevelPackagesEnum.some.value}
                                checked={predictiveMaintenancePackage === customerLevelPackagesEnum.all.value}
                                onChange={() => handlePackageChange(predictiveMaintenancePackage, "professional", "predictiveMaintenance", setPredictiveMaintenancePackage)}
                              />
                              <Typography variant="body2" className={style.labelText}>
                                {t`Predictive Maintenance`}
                              </Typography>
                            </div>
                          } />
                      </TreeItem>

                    </TreeView>
                  }

                  {customerId ? "" :
                    <div className={style.saveBtn}>
                      <Button
                        title={t`save customer`}
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ width: "7rem" }}
                        disabled={isSubmitting}
                      >
                        {viewAsComponent ? t`Next` : t`Save`}
                      </Button>
                    </div>
                  }
                </div>
              </form>
            );
          }}
        </Formik>
      </div>

      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title" className={style.title}>
          <div className={style.titleContent}>
            <Typography variant="h6">{t`Add site`}</Typography>
            <IconButton onClick={handleCloseDialog}>
              <Clear />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={style.dialogContent}>
          <Site
            handleCloseDialog={handleCloseDialog}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomEditCustomer;
