import { Services as ServicesSdk, Trap as TrapSdk } from "coolremote-sdk";
import { thunk, Thunk } from "easy-peasy";

export interface ITrapsModel {
  getTraps: Thunk<ITrapsModel, { type: any }>;
  getTrapParams: Thunk<ITrapsModel, string>;
  getAdminTraps: Thunk<ITrapsModel>;
  addTrap: Thunk<ITrapsModel, { data: any; customerId: string }>;
  addAdminTrap: Thunk<ITrapsModel, { data: any }>;
  updateTrap: Thunk<ITrapsModel, { data: any; trapId: string }>;
  deleteTrap: Thunk<ITrapsModel, { id: string }>;
  getServiceParams: Thunk<ITrapsModel, { brand: number; unitType: number }>; //assignToTraps
}

export const trapsModel: ITrapsModel = {
  getTraps: thunk((actions, payload) => {
    return TrapSdk.getTraps(payload.type);
  }),
  getTrapParams: thunk((actions, payload) => {
    return TrapSdk.getTrapParams(payload);
  }),
  getAdminTraps: thunk((actions, payload) => {
    return TrapSdk.getAdminTraps();
  }),
  addTrap: thunk((actions, payload) => {
    return TrapSdk.createTrap(payload.data, payload.customerId);
  }),
  addAdminTrap: thunk((actions, payload) => {
    return TrapSdk.createAdminTrap(payload.data);
  }),
  updateTrap: thunk((actions, payload) => {
    return TrapSdk.update(payload.data, payload.trapId);
  }),
  deleteTrap: thunk((actions, payload) => {
    return TrapSdk.delete(payload.id);
  }),
  getServiceParams: thunk((actions, payload) => {
    return ServicesSdk.getServiceParams(payload.brand, payload.unitType);
  })
};
