import { InputBase, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import clsx from "clsx";
import _ from "lodash";
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";
import moment from "moment";
import React from "react";
import styles from "./advancedInputField.module.css";
import { Switch as CoolSwitch } from "../../cool_widgets/Switch";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from "ttag";

const AdvancedInputField = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  name,
  label,
  select,
  toggle,
  phone,
  datePicker,
  options = [],
  editable,
  type = "",
  textField = false,
  columnStyle = false,
  width = "",
  ...props
}: any) => (
  <div style={width ? { width } : {}} className={clsx(styles.dataRow, columnStyle ? styles.removeBorder : "")}>
    <div className={clsx(styles.flexBox, columnStyle ? styles.columnFlexBox : "")}>
      <InputLabel className={styles.siteDetailsLabel}>{label}</InputLabel>
      {select ? (
        <TextField
          select
          disabled={!editable}
          name={name}
          label={!_.isNil(values[name]) && values[name] !== "" ? "" : `Select ${label}`}
          value={!_.isNil(values[name]) ? values[name] : null}
          onChange={handleChange}
          className={styles.selectField}
          InputProps={{
            disableUnderline: true,
            classes: {
              formControl: styles.inputRoot,
              root: styles.inputRoot
            }
          }}
          InputLabelProps={{ shrink: false }}
          margin="none"
        >
          {options.map((option: any, index: any) => (
            <MenuItem key={index} value={option.value} disabled={option?.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : phone ? (
        <MuiPhoneNumber
          disabled={!editable}
          disableAreaCodes={true}
          value={values[name].charAt(0) === "+" ? values[name] : "+" + values[name]}
          name={name}
          inputClass={clsx(styles.fieldStyle, styles.phoneStyle)}
          defaultCountry={"us"}
          onChange={(e: any) => setFieldValue(name, e)}
          InputProps={{
            disableUnderline: true,
            classes: { underline: styles.error }
          }}
        />
      ) : toggle ? (
        <div>
          {t`disable`}
          <CoolSwitch
            className={clsx(!editable && styles.disabledStyle)}
            color="default"
            onChange={(e: any, value: any) => setFieldValue(name, value)}
            checked={values[name]}
            name={name}
            disabled={!editable}
          />
          {t`enable`}
        </div>
      ) : textField ?
        (
          <TextField
            disabled={!editable}
            name={name}
            placeholder={label}
            value={values[name]}
            onChange={handleChange}
            className={styles.selectField}
            InputProps={{
              disableUnderline: true,
              classes: {
                formControl: styles.inputRoot,
                root: styles.inputRoot
              }
            }}
            InputLabelProps={{ shrink: false }}
            margin="none"
            type={type}
          />
        )
        : datePicker ? (<LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            showToolbar={false}
            value={values.renewalDate}
            onChange={(date: any) => setFieldValue("renewalDate", date)}
            renderInput={(params: any) => <TextField {...params} variant="outlined" className={styles.datePicker} />}
            disabled={!editable}
          />
        </LocalizationProvider>)
          : (
            <InputBase
              disabled={!editable}
              name={name}
              onChange={handleChange}
              value={name === "createdAt" || name === "creatingDate" ? values[name] && moment(values[name]).format("YYYY/MMM/DD") : !_.isNil(values[name]) ? values[name] : ""}
              className={styles.siteDetailsFields}
              placeholder={label}
              type={type}
              inputProps={{ ...props }}
            />
          )}
    </div>
    {errors[name] && (
      <Typography className={styles.siteDetailsError}>
        {errors[name] || "required field"}
      </Typography>
    )}
  </div>
);

export default AdvancedInputField;
