import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import {
  createStyles,
  // lighten,
  makeStyles,
  // Theme,
} from "@mui/styles";
import { Theme, lighten } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import useStyles from "./CoolTable.style";

type Order = "asc" | "desc";

export interface ICoolTableProps<T> {
  headCells: Array<IHeadCell<T>>;
  rows: T[];
  getPageRows: (
    page: number,
    rowsPerPage: number,
    order: Order,
    orderBy: keyof T
  ) => T[];
  renderCell: (rowData: T, rowId: keyof T) => any;
  defaultOrderBy: keyof T;
  options?: ICoolTableOptions;
  onRowDoubleClick?: (row: any) => void;
}

export interface ICoolTableOptions {
  withCheckboxes?: boolean;
  withToolbar?: boolean;
  // withDatePicker?: boolean;

  rowsPerPage?: number;
  rowsPerPageOptions?: number[];

  classNameForTableContainer?: string;
}
const defaultCoolTableOptions = {
  withCheckboxes: false,
  withToolbar: false,
  // withDatePicker: false,
  rowsPerPage: 13,
  rowsPerPageOptions: [13, 30, 50]
};

export interface ICoolTablePage<T> {
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: keyof T;
}

export interface IHeadCell<T> {
  id: keyof T;
  title: string | undefined;
  numeric?: boolean;
  width?: string | number; // css property 'width' on head cell's div, defaults to 'auto'
  disablePadding?: boolean;
  disableSort?: boolean;
  tableCellClassName?: string;
  tableHeadCellClassName?: string;
}

interface ICoolTableHeadProps<T> {
  headCells: Array<IHeadCell<T>>;
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  tableOptions: ICoolTableOptions;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<T>(order: Order, orderBy: any): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function CoolTable<T extends any>({
  headCells,
  rows,
  getPageRows,
  renderCell,
  defaultOrderBy,
  options,
  onRowDoubleClick
}: ICoolTableProps<T>) {
  options = { ...defaultCoolTableOptions, ...options };

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof T>(defaultOrderBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(options.rowsPerPage!);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [dense, setDense] = React.useState(false);
  const classes: any = useStyles();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const _getPageRows = () => {
    if (getPageRows) return getPageRows(page, rowsPerPage, order, orderBy);
    else
      return stableSort(rows, getComparator(order, orderBy as string)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {options.withToolbar && (
          <CoolTableToolbar numSelected={selected.length} />
        )}
        <div className={classes.tableMenu}>
          <TablePagination
            className={classes.paginationHeader}
            rowsPerPageOptions={options.rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <TableContainer classes={{ root: options.classNameForTableContainer }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <CoolTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy ? (orderBy as string) : ""}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableOptions={options}
            />
            <TableBody>
              {_getPageRows().map((row: any, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      options?.withCheckboxes
                        ? handleClick(event, row.id)
                        : null
                    }
                    onDoubleClick={() => onRowDoubleClick && onRowDoubleClick(row)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    {options?.withCheckboxes! && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    )}
                    {_.map(headCells, (col, index) => (
                      <TableCell
                        key={index}
                        align="left"
                        className={col.tableCellClassName}
                      >
                        {renderCell ? renderCell(row, col.id) : row[col.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

function CoolTableHead<T>({
  headCells,
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableOptions
}: ICoolTableHeadProps<T>) {
  const createSortHandler = (property: keyof T) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableOptions.withCheckboxes && (
          <TableCell
            padding="checkbox"
            classes={{ root: classes.tableHeadCell }}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: !_.isUndefined(headCell.width) ? headCell.width : "auto"
            }}
            className={headCell.tableHeadCellClassName}
            classes={{ root: classes.tableHeadCell }}
          >
            {!headCell.disableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.title}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.title}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles: any = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
    title: {
      flex: "1 1 100%"
    }
  })
);

interface CoolTableToolbarProps {
  numSelected: number;
}

const CoolTableToolbar = (props: CoolTableToolbarProps) => {
  const classes: any = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} {t`selected`}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t`Nutrition`}
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title={t`Delete`}>
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t`Filter list`}>
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
