import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box'
	},
	tabsContainer: {
		backgroundColor: theme.palette?.primary?.main,
		color: theme.palette?.primary?.contrastText || '#fff',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
	},
	tabs: {
		'& .MuiTab-root': {
			color: '#d5d2d5'
		},
		'& .Mui-selected': {
			color: '#fff !important',
			fontSize: '16px'
		}
	},
	tabPanel: {
		backgroundColor: theme.palette?.background?.paper || '#fff',
		border: theme.dialogs?.border,
		borderRadius: '5px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
		marginTop: '1rem',
		overflowX: 'hidden',
		overflowY: 'scroll'
	}
}));

export default useStyles;
