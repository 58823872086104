import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles: any = makeStyles((theme: Theme) => ({
  optionStyle: {
    color: "#fff",
    width: "100%",
    fontSize: "0.8125rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  optionText: {
    fontSize: "0.8125rem !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "calc(100% - 12px)"
  },
  selectContainer: {
    // padding: '0 9px',
    height: "36px"
    // display: 'flex',
    // alignItems: 'center'
  },
  selectStyle: {
    width: "10rem",
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: "4px",
    margin: "1rem .5rem",
    padding: "0 9px !important",
    height: "36px !important",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  inputStyle: {},
  iconStyle: {
    right: "0"
  },
  disabled: {
    "-webkit-text-fill-color": "gray !important"
  },
  focusStyle: {
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      borderRadius: "4px"
    }
  },
  arrowIcon: {
    color: "#fff",
    fontSize: "2.5rem !important",
    top: "0",
    height: "36px"
  },
  input: {
    color: "#fff"
  },
  subHeader: {
    position: "unset",
    background: "unset"
  },
  textField: {
    "& fieldset": {
      borderColor: "rgba(170, 162, 170, 0.3)"
    },
    "&:hover": {
      "& fieldset": {
        borderColor: "rgba(170, 162, 170, 0.3) !important"
      }
    }
  },
  hideOption: {
    visibility: "hidden",
    height: 0,
    padding: 0
  },
  list: {
    background: "#29132e"
  }
}));

export default useStyles;
