import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { isEmail } from "react-multi-email";
import { t } from "ttag";
import * as Yup from "yup";
import CoolButton from "../../components/cool_widgets/Button";
import { useStoreActions } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import useStyles from "../UserManagement/OneUser.style";
import styles from "./SupportView.module.css";

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t`must be at least 8 chars and contain 1 capital letter and 1 digit`)
    .required(t`Required`),
  repeatPassword: Yup.string()
    .when("newPassword", {
      is: (val: any) => val !== undefined && val !== "",
      then: Yup.string().required(t`Required`)
        .oneOf([Yup.ref("newPassword"), null], t`Passwords must match`)
    })
});

const SupportView: React.FC = (props: any) => {
  const deviceBySerial = useStoreActions((action) => action.deviceBySerial);
  const usersByDevice = useStoreActions((action) => action.usersByDevice);
  const deviceByUser = useStoreActions((action) => action.deviceByUser);
  const getUserByEmail = useStoreActions((action) => action.getUserByEmail);
  const getRecoveryToken = useStoreActions((action) => action.users.getRecoveryToken);
  const { addMessage } = useStoreActions((action) => action.errorMessage);
  const  copyUnitInfo  = useStoreActions((action) => action.copyUnitInfo);
  const [data, setData] = useState<any>({ 1: "", 2: "", 3: "" });
  const [user, setUser] = useState<any>({});
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [resData, setResData] = useState<any>({ failed: false, res: {} });
  const [actionType, setActionType] = useState<any>(0);
  const [openResetDialog, setOpenResetDialog] = useState<boolean>(false);
  const [resetMethodSelect, setResetMethodSelect] = useState<string>("email");
  const [copyUnitData, setCopyUnitData] = useState<any>({deviceSerial: "", lineX: "", lineY: ""});
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const classes: any = useStyles();
  const updatePassword = useStoreActions((action) => action.users.updatePassword);

  const getData = async (fn: any, type: number) => {
    const res = await fn(data[type]);
    if (_.isString(res)) {
      setResData({ failed: true, res });
    } else {
      setResData({ failed: false, res });
    }

    setActionType(type);
    setShowModal(true);
  };

  const handleGetUserByEmail = () => {
    setEmailError("");
    if (!isEmail(email)) {
      setEmailError(t`Please enter valid email address`);
      return;
    }
    getUserByEmail(email)
      .then((res: any) => {
        setUser(res);
        setOpenResetDialog(true);
      })
      .catch((err: any) => addMessage({ message: err.message }));
  };

  const handleSendResetEmail = (values: any) => {
    if (resetMethodSelect === "email") {
      getRecoveryToken(email)
        .catch((err: any) => addMessage({ message: err.message }))
        .finally(() => {
          setUser({});
          setEmail("");
          setOpenResetDialog(false);
        });
    } else {
      const data = { password: values.newPassword };
      updatePassword({ userId: user.id, data })
        .catch((err: any) => addMessage({ message: err.message }))
        .finally(() => {
          setUser({});
          setEmail("");
          setOpenResetDialog(false);
        });

    }
  };

  const getAction = (title: string, input: string, onAction: any, type: number) => {
    return (
      <Grid container className={styles.supportItem} key={type}>
        <Grid item xs={12} className={styles.titleWrapper}>
          <Typography className={styles.title}> {title}</Typography>
        </Grid>

        <Grid item xs={6} className={styles.inputStyle} >
          <TextField
            id="outlined-basic"
            label={input}
            variant="outlined"
            onChange={(event: any) => {
              setData({ ...data, ...{ [type]: event.target.value } });
            }}
            value={data[type]}
          />
        </Grid>
        <Grid item xs={6} className={styles.btnWrpper}>
          <Button
            variant="contained"
            className={styles.findBtn}
            onClick={() => {
              getData(onAction, type);
            }}>
            {t`Find`}
          </Button>
        </Grid>

      </Grid>
    );
  };

  const responseModal = () => {

    let bodyText: any = "";
    if (resData.failed) {
      bodyText = resData.res;
    } else {

      switch (actionType) {

        case 1:
          bodyText = `device pin : ${resData.res.pin}`;
          break;
        case 2:
          if (resData.res.length) {
            const users = _.map(resData.res, (user) => `userEmail: ${user.email}. username: ${user.username}`);
            bodyText = users.join("\n");
          } else {
            bodyText = `the device doesn't belong to any user`;
          }

          break;
        case 3:
          if (resData.res.length) {
            bodyText = `users devices: ${resData.res.join(", ")}`;
          } else {
            bodyText = `the user has no devices`;
          }
          break;
        default:
          bodyText = `something went wrong with the request ${JSON.stringify(resData.res)}`;
      }
    }

    const onAction = () => {
      setShowModal(false);
      setData({ 1: "", 2: "", 3: "" });
    };

    return (
      <SimpleModal
        showModal={showModal}
        title={t`The data`}
        contentText={bodyText}
        actionName={t`OK`}
        onAction={onAction}
        closeModal={onAction}
      />
    );
  };

  const copy = async () => {
    let success = true;
    try {
      await copyUnitInfo(copyUnitData);
    } catch (err: any) {
      addMessage({ message: err.message });
      success = false;
    }
    if(success)
      setShowSuccessModal(true);
  }

  const onOkOrCancle = () => {
    setShowSuccessModal(false)
    setCopyUnitData({deviceSerial: "", lineX: "", lineY: ""});
  }
    
  return (
    <ServiceNavigationBar title={t`Support`} {...props}>
      <Paper elevation={0} className={styles.paper}>
        {responseModal()}
        <Grid container spacing={3} className={styles.supportContanier}>
          {getAction(t`Get device pin by device serial`, t`Device serial`, deviceBySerial, 1)}
          {getAction(t`Get users by device serial`, t`Device serial`, usersByDevice, 2)}
          {getAction(t`Get device serials by username`, t`Username`, deviceByUser, 3)}
          <Grid container className={styles.supportItem}>
            <Grid item xs={12} className={styles.titleWrapper}>
              <Typography className={styles.title}> copy unit information from the source line to destination line</Typography>
            </Grid>
            <Grid item xs={2} className={styles.inputStyle} >
              <TextField
                id="outlined-basic"
                label="Device serial"
                variant="outlined"
                value={copyUnitData.deviceSerial}
                onChange={(event: any) => {
                  setCopyUnitData({...copyUnitData , deviceSerial: event.target.value});
                }}
              />
            </Grid>
            <Grid item xs={2} className={styles.inputStyle} >
              <TextField
                id="outlined-basic"
                label="Source line"
                variant="outlined"
                value={copyUnitData.lineX}
                onChange={(event: any) => {
                  setCopyUnitData({...copyUnitData , lineX: event.target.value});
                }}
              />
            </Grid>
            <Grid item xs={2} className={styles.inputStyle} >
              <TextField
                id="outlined-basic"
                label="Destination line"
                variant="outlined"
                value={copyUnitData.lineY}
                onChange={(event: any) => {
                  setCopyUnitData({...copyUnitData , lineY: event.target.value});
                }}
              />
            </Grid>
            <Grid item xs={6} className={styles.btnWrpper}>
              <Button
                variant="contained"
                className={styles.findBtn}
                onClick={() => {
                  copy();
                }}>
                {t`Copy`}
              </Button>
            </Grid>
            <SimpleModal
            showModal= {showSuccessModal}
            title={t`success`}
            contentText= {t`data copied from line ${copyUnitData.lineX} to line ${copyUnitData.lineY} for device ${copyUnitData.deviceSerial}`}
            actionName={t`OK`}
            onAction={onOkOrCancle}
            closeModal={onOkOrCancle}
            />
          </Grid>
        </Grid>

      </Paper>
    </ServiceNavigationBar>
  );

};

export default SupportView;
