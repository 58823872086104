
import React, { useState, useEffect } from 'react';
import {
    InputLabel,
    Autocomplete,
    TextField,
} from "@mui/material";
import useStyles from "./DeviceMigration.style";
import { useStoreState } from "../../models/RootStore";
import clsx from "clsx";
import _ from "lodash";

const CustomerSelection = (props: any) => {
    const { nextStep } = props;
    const styles: any = useStyles();
    const [customersOptions, setCustomersOptions] = useState<any>([]);
    const [customerId, setCustomerId] = useState<any>(null);
    const customers = useStoreState((state) => state.customers.customersBasicList);

    const getCustomers = () => {
        const menuOptions: any[] = [];

        _.map(customers, (customer) => {
            menuOptions.push({ value: customer.id, label: customer.name });
        });

        return _.sortBy(menuOptions, [(option) => option.label?.toUpperCase()], ["asc"]);
    };

    useEffect(() => {
        setCustomersOptions(getCustomers());
    }, [customers]);


    return (
        <div className={styles.customerPageContainer}>
            <div className={styles.existingCustomerContainer}>
                <InputLabel className={clsx(styles.fieldLabel, styles.smallField)} >
                    <Autocomplete
                        options={customersOptions}
                        id="customer-list"
                        value={customerId}
                        defaultValue={""}
                        className={styles.autocomplete}
                        onChange={(e: any, value: any) => { setCustomerId(value); nextStep(value); }}
                        getOptionLabel={(option: any) => option.label || ""}
                        renderInput={(params: any) => (
                            <TextField
                                variant="filled"
                                {...params}
                                name={"test-random-name"}
                                inputProps={{
                                    ...params.inputProps,
                                    disableunderline: "true",
                                    autoComplete: "new-password",
                                    form: {
                                        autocomplete: 'off',
                                    }
                                }}
                            />
                        )}
                    />
                </InputLabel>
            </div>
        </div>
    );
};

export default CustomerSelection;