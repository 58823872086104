import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import moment from "moment";
import { Check, Close, FilterList, Search } from "@mui/icons-material";
import { GlobalAdmin as sdkGlobalAdmin } from "coolremote-sdk";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./directTests.style";
import { Lookup } from "../../components/Lookup";
import exportDataToExcel from "../../utils/ExportDataToExcel";

export default function DierctTests(props: any) {
  const classes: any = useStyles();

  const [testsList, setTestsList] = useState<any>([]);
  const deviceTypes = useStoreState((state) => state.types.deviceTypes);
  const [searchVal, setSearchVal] = useState<string>("");
  const [submit, setSubmit] = useState<boolean>(false);
  const [filteredTests, setFilteredTests] = useState<any>([]);

  const [lookupAnchor, setAnchor] = useState(null);
  const [selectedTest, setSelectedTest] = useState<any>(null);
  const [filters, setFilters] = useState<any>({
    device: [],
    result: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    device: [],
    result: []
  });
  const [clickedColumnName, setClickedColumnName] = useState<any>("");


  const { addMessage } = useStoreActions((action) => action.errorMessage);


  useEffect(() => {
    (async () => {
      if (submit && searchVal) {
        sdkGlobalAdmin.getDeviceTests({
          serial: searchVal
        })
          .then((res: any) => {
            setTestsList(Object.values(res));
          })
          .catch((err: any) => addMessage({ message: err.message }))
          .finally(() => {
            setSubmit(false);
          })
      }
    })();
  }, [submit]);

  useEffect(() => {

    if (!testsList?.length) {
      return;
    }

    const lists: any = testsList.reduce((listsVal: any, test: any) => {
      const { device_id, result } = test;

      if (device_id && listsVal["device_id"]?.indexOf(device_id) === -1) {
        listsVal["device_id"].push(device_id);
      }

      if (result && listsVal["results"]?.indexOf(result) === -1) {
        listsVal["results"].push(result);
      }

      return listsVal;

    }, { device_id: [], results: [] });
    setFilters({
      device_id: lists.device_id,
      result: lists.results
    });

  }, [testsList]);

  useEffect(() => {
    if (!testsList) {
      return;
    }
    if (_.isEmpty(appliedFilters)) {
      const sorted = _.orderBy(testsList, "createdAt", "desc");
      setFilteredTests(sorted);
      return;
    }
    const filteredTests = _(testsList)
      .filter((test) => {
        return appliedFilters.device.length
          ? appliedFilters.device.includes(test.device)
          : true;
      })
      .filter((test) => {
        return appliedFilters.result.length
          ? appliedFilters.result.includes(test.result)
          : true;
      })
      .value();
    const sortedFiltered = _.orderBy(filteredTests, "createdAt", "desc");
    setFilteredTests(sortedFiltered);
  }, [appliedFilters, testsList]);


  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setSubmit(true)
    }
  };

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    console.log('clicked   ', clickedColumn)
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;

  const exportToExcel = () => {
    const headers = [
      {header: "DEVICE SERIAL", key: "device_id"},
      {header: "WIFI", key: "wifi", nested: "attributes", format: (value: any) => value ? "True" : "False"},
      {header: "Firmware Version", key: "firmware_version", nested: "attributes"},
      {header: "Model", key: "model", nested: "attributes"},
      {header: "Time", key: "createdAt", format: (timestamp: any) => moment(timestamp)?.format("DD/MM/YY HH:mm")},
      {header: "RESULT", key: "result"}
    ];

    const data = filteredTests.map((test: any) => {
      return headers.reduce((acc: any, { key, format, nested }) => {
        let value = nested? test[nested]?.[key] : test[key];
        acc[key] = format ? format(value) : value;
        return acc;
      }, {})
    })
    exportDataToExcel({ columns: headers, data, fileName: "Device tests", defaultWidth: 27 })
  }


  return (
    <ServiceNavigationBar
      title={t`Direct Tests`}
      searchComponent={
        <Input
          placeholder={t`Device Serial`}
          value={searchVal}
          onChange={(event: any) => setSearchVal(event.target.value)}
          disableUnderline={true}
          onKeyDown={handleKeyDown}
          classes={{ root: classes.inputRoot }}
          endAdornment={
            searchVal && (
              <IconButton
                onClick={() => setSubmit(true)}
                className={classes.searchIcon}
              >
                <Search />
              </IconButton>
            )
          }
        />
      }
      {...props}
    >
      <Button variant="contained" onClick={exportToExcel} className={classes.exportBtn}>
        {t`Export to Excel`}
      </Button>
      <Paper elevation={0} className={classes.paperPadding}>
        <TableContainer>
          <Table stickyHeader className="" aria-label="customized table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell
                  className={classes.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "device_id")}

                >
                  <div className={classes.headContainer}>
                    {t`DEVICE SERIAL`}
                    <FilterList
                      className={clsx(classes.filterStyle, {
                        [classes.blueFilter]: appliedFilters?.device_id?.length
                      })}
                    />
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="center"
                >
                  <div className={classes.headContainer}>
                    {t`WIFI`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="center"
                >
                  <div className={classes.headContainer}>
                    {t`Firmware Version`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="center"
                >
                  <div className={classes.headContainer}>
                    {t`Model`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="left"
                >
                  <div className={classes.headContainer}>
                    {t`Time`}
                  </div>
                </TableCell>

                <TableCell
                  className={classes.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "result")}
                > <div className={classes.headContainer}>
                    {t`RESULT`}
                    <FilterList
                      className={clsx(classes.filterStyle, {
                        [classes.blueFilter]: appliedFilters?.result?.length
                      })}
                    />
                  </div>
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTests.map((test: any, index: any) => {
                const { id, device_id, result, createdAt, attributes } = test;
                return (
                  <TableRow hover tabIndex={-1} key={`${id}-${index}`} onClick={() => setSelectedTest(test)}>
                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {device_id}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {attributes?.wifi ? <Check /> : <Close />}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {attributes?.firmware_version}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {attributes?.model}
                    </TableCell>

                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {moment(createdAt)?.format("DD/MM/YY HH:mm")}
                    </TableCell>
                    <TableCell
                      className={classes.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {result}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {clickedColumnName && (
        <Lookup
          filtersList={filters[clickedColumnName]}
          appliedFilters={appliedFilters[clickedColumnName]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedColumnName("")}
          clearAllFilters={() => setAppliedFilters({
            device_id: [],
            result: []
          })}
          hasFilters={hasFilters}
        />
      )}

      {
        selectedTest ? (
          <Dialog
            open={selectedTest}
            onClose={() => setSelectedTest(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xl"}
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{`Device ${selectedTest.device_id} test details`}</DialogTitle>
            <DialogContent>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "15%" }}>{t`Test Name`}</TableCell>
                    <TableCell style={{ width: "15%" }}>{t`Status`}</TableCell>
                    <TableCell >{t`Errors`}</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    selectedTest?.tests && Object.keys(selectedTest?.tests)?.map((testName: any) => {
                      const testObj = selectedTest?.tests[testName];
                      return (
                        <TableRow key={`${selectedTest._id}-${testName}`}>
                          <TableCell>{testName}</TableCell>
                          <TableCell>{testObj.status}</TableCell>
                          <TableCell>
                            {!_.isEmpty(testObj.errors) && <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell >{t`Code`}</TableCell>
                                  <TableCell >{t`Error`}</TableCell>
                                  <TableCell >{t`Details`}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {testObj.errors?.map((err: any, index: any) => {
                                  return (
                                    <TableRow>
                                      <TableCell>{`${err?.code}`}</TableCell>
                                      <TableCell>{`${err?.err_str}`}</TableCell>
                                      <TableCell>{`${err?.details}`}</TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedTest(null)} color="primary">
                {t`Close`}
              </Button>
            </DialogActions>
          </Dialog>
        )
          : <></>
      }
    </ServiceNavigationBar>
  );
}
