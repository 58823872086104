import { InputAdornment, ListSubheader, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import SvgArrow from "../../icons/Arrow";
import useStyles from "./MenuDropDown.style";
import { SelectChangeEvent } from "@mui/material";

const MenuDropDown = (props: any) => {
  const classes: any = useStyles();
  const { value, options, onChange, placeholder = "no options", enableSearch } = props;
  const [searchText, setSearchText] = useState<any>("");

  const handleChange = (event: SelectChangeEvent<any>) => {
    onChange(event.target.value as string);
  };

  const containsText = (name: any, searchText: any) =>
    name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => options.map((option: any) => containsText(option?.name, searchText) ? (option) : ({ ...option, hide: true })),
    [searchText, options]
  );

  return (
    <Select
      variant="filled"
      value={value}
      onChange={handleChange}
      className={classes.selectContainer}
      classes={{
        filled: classes.selectStyle,
        icon: classes.arrowIcon,
        select: classes.focusStyle,
        iconFilled: classes.iconStyle,
        disabled: classes.disabled
      }}
      displayEmpty
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        // getContentAnchorEl: null,
        classes: {
          list: classes.list
        }
      }}
      IconComponent={SvgArrow}
      // classes={{ icon: classes.arrowIcon }}
      disabled={options.length < 2}
      onClose={() => setSearchText("")}
    >
      {enableSearch &&
        <ListSubheader className={classes.subHeader}>
          <TextField
            classes={{ root: classes.textField }}
            size="small"
            variant="outlined"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              className: classes.input,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fill: "#aaa2aa" }} />
                </InputAdornment>
              )
            }}
            onChange={(e: any) => {
              setSearchText(e.target.value);
            }}
            onClick={(e: any) => e.stopPropagation()}
            onKeyDown={(e: any) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>}
      {
        options.length < 2 ?
          <MenuItem value="" className={classes.optionStyle}>
            <Typography className={classes.optionText}>{placeholder}</Typography>
          </MenuItem> :
          displayedOptions && displayedOptions.map((option: any) => {
            return (
              <MenuItem key={option.id} value={option.id} className={clsx(classes.optionStyle, { [classes.hideOption]: option.hide })}>
                <Typography className={classes.optionText}>{option.name}</Typography>
              </MenuItem>
            );
          })
      }
    </Select >
  );
};

export default MenuDropDown;
