import {
  AppBar,
  Autocomplete,
  Box,
  Grid,
  Menu,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ArrowIcon from "../../icons/ArrowLong";
import {
  GlobalAdmin as sdkGlobalAdmin
} from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./search.style";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { useStoreState } from "../../models/RootStore";
import { Info } from "@mui/icons-material";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: any;
}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        display: value === index ? 'flex' : 'none'
      }}
    >
      {value === index && (
        <Box p={3} style={{ flex: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}



export default function AdvancedSearch(props: any) {

  const classes: any = useStyles();
  const types: any = useStoreState((state) => state.types);
  const [selectedTab, setSelectedTab] = useState(0);
  const [brandOptions, setBrandOptions] = useState<any>([]);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const [siteHover, setSiteHover] = useState<any>(null);
  const [siteHoverAnch, setSiteHoverAnch] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!types) {
      return;
    }

    const options: any = types?.hvacBrands?.map((obj: any) => {
      return { label: obj.name, value: obj.value };
    })
    setBrandOptions(options);

  }, [types]);

  useEffect(() => {

    if (selectedTab === 0) {
      if (!selectedBrand) {
        return;
      }
      sdkGlobalAdmin.getLinesPerBrand(selectedBrand?.value)
        .then((resp: any) => {
          setData({ data: resp, tab: 0 })
        })
      return;
    }
    if (selectedTab === 1) {
      sdkGlobalAdmin.getSitesWithSensors()
        .then((resp: any) => {
          setData({ data: resp, tab: 1 })
        })
      return
    }
    if (selectedTab === 2) {
      sdkGlobalAdmin.getSitesWithPPD()
        .then((resp: any) => {
          setData({ data: resp, tab: 2 })
        })
      return
    }

  }, [selectedBrand, selectedTab])





  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handlePopoverOpen = (site: any, event: any) => {
    setSiteHoverAnch(event.currentTarget);
    setSiteHover(site);
  };

  const handlePopoverClose = () => {
    setSiteHoverAnch(null);
    setSiteHover(null);
  };



  return (
    <ServiceNavigationBar
      filters={<SelectionsMenu
        hideCustomerSelection
        hideSiteSelection
        hideSystemSelection
        hideUnitSelection
      />}

      {...props}
    >
      <Paper elevation={0} className={classes.paperPadding}>


        <AppBar position="static">
          <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example" style={{
            backgroundColor: '#fff'
          }}>
            <Tab label="Brand Search" {...a11yProps(0)} className={classes.tabBtn} classes={{ selected: classes.selectedTab }} />
            <Tab label="Sites with Sensors" {...a11yProps(1)} className={classes.tabBtn} classes={{ selected: classes.selectedTab }} />
            <Tab label="Sites With Power Meter Devices" {...a11yProps(2)} className={classes.tabBtn} classes={{ selected: classes.selectedTab }} />
          </Tabs>
        </AppBar>
        <TabPanel value={selectedTab} index={0} className={classes.tab}>
          <Autocomplete
            disablePortal
            options={brandOptions}
            sx={{ width: 500 }}
            renderInput={(params) => <TextField {...params} label="Brands" />}
            value={selectedBrand}
            onChange={(e: any, newVal: any) => setSelectedBrand(newVal)}
          />


          {
            !selectedBrand?.value ?
              <Grid container direction={"column"} className={classes.noContentContainer}>
                <div className={classes.grayOval}>
                  <ArrowIcon className={classes.arrowIcon} />
                </div>
                <Typography>
                  {t`Please select a brand using the above filter.`}
                </Typography>
              </Grid> :

              <TableContainer style={{
                marginTop: '20px'
              }}>
                <Table stickyHeader className="" aria-label="customized table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell
                        className={classes.headCells}
                        align="left"
                      >
                        {t`Customer`}
                      </TableCell>

                      <TableCell
                        className={classes.headCells}
                        align="left"
                      >
                        <div className={classes.headContainer}>
                          {t`Site`}
                        </div>
                      </TableCell>

                      <TableCell
                        className={classes.headCells}
                        align="left"
                      >
                        <div className={classes.headContainer}>
                          {t`Device`}
                        </div>
                      </TableCell>

                      <TableCell
                        className={classes.headCells}
                        align="center"
                      >
                        <div className={classes.headContainer}>
                          {t`Line #`}
                        </div>
                      </TableCell>

                      <TableCell
                        className={classes.headCells}
                        align="left"
                      >
                        <div className={classes.headContainer}>
                          {t`# of ODU`}
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.headCells}
                        align="left"
                      >
                        <div className={classes.headContainer}>
                          {t`# of IDU`}
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.headCells}
                        align="left"
                      >
                        <div className={classes.headContainer}>
                          {t`# of Service units`}
                        </div>
                      </TableCell>



                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.tab === 0 && data?.data?.map((line: any) => {
                      const { _id, deviceSerial, customerName, siteName, id } = line;
                      return (
                        <TableRow hover tabIndex={-1} key={_id} >
                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {customerName}
                          </TableCell>

                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {siteName}
                          </TableCell>

                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {deviceSerial}
                          </TableCell>

                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {id}
                          </TableCell>

                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="left"
                          >

                          </TableCell>
                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="left"
                          >

                          </TableCell>
                          <TableCell
                            className={classes.rowCell}
                            component="th"
                            scope="row"
                            align="left"
                          >

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
          }
        </TabPanel>


        <TabPanel value={selectedTab} index={1} className={classes.tab}>

          <TableContainer style={{
            marginTop: '20px'
          }}>
            <Table stickyHeader className="" aria-label="customized table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    {t`Customer`}
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    <div className={classes.headContainer}>
                      {t`Site`}
                    </div>
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    <div className={classes.headContainer}>
                      {t`Device`}
                    </div>
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="center"
                  >
                    <div className={classes.headContainer}>
                      {t`# of Sensors`}
                    </div>
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    <div className={classes.headContainer}>
                      {t`Sensor types`}
                    </div>
                  </TableCell>




                </TableRow>
              </TableHead>
              <TableBody>
                {data?.tab === 1 && data?.data?.map((site: any) => {
                  const { _id, deviceSerial, customerName, siteName, numOfSensors, sensorTypes } = site;
                  return (
                    <TableRow hover tabIndex={-1} key={_id}
                    >
                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {customerName || ""}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {siteName}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {deviceSerial}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {numOfSensors}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        <Info
                          onMouseEnter={(e: any) => handlePopoverOpen(site, e)}
                          onMouseLeave={handlePopoverClose}
                        />

                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

        </TabPanel>


        <TabPanel value={selectedTab} index={2} className={classes.tab}>

          <TableContainer style={{
            marginTop: '20px'
          }}>
            <Table stickyHeader className="" aria-label="customized table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    {t`Customer`}
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    <div className={classes.headContainer}>
                      {t`Site`}
                    </div>
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    <div className={classes.headContainer}>
                      {t`Device`}
                    </div>
                  </TableCell>

                  <TableCell
                    className={classes.headCells}
                    align="left"
                  >
                    <div className={classes.headContainer}>
                      {t`Brand`}
                    </div>
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {data?.tab === 2 && data?.data?.map((site: any) => {
                  const { _id, deviceSerial, customerName, siteName, brand } = site;
                  return (
                    <TableRow hover tabIndex={-1} key={_id} >
                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {customerName}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {siteName}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {deviceSerial}
                      </TableCell>

                      <TableCell
                        className={classes.rowCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {brand}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Paper>

      {siteHover &&

        <Popover
          open={!!siteHover && !!siteHoverAnch}
          anchorEl={siteHoverAnch}
          onClose={handlePopoverClose}
          style={{ pointerEvents: "none" }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}

        >

          <div style={{
            width: '200px',
            padding: '10px'
          }}>
            {
              siteHover?.sensorTypes?.map((type: any) => <Typography key={`${siteHover._id}-${type}`} style={{
                fontSize: '15px',
                marginBottom: '3px'
              }}>
                {types?.sensorTypes[type]?.name}
              </Typography>)
            }
          </div>
        </Popover>
      }
    </ServiceNavigationBar>
  );
}
