import _ from "lodash";
import React from "react";
import { t } from "ttag";
import styles from "./ErrorBox.module.css";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@mui/material";
import Button from "../../components/cool_widgets/Button";

interface ConfirmationDialogProps {
  error: string;
  title: string;
  closeText: string;
  onClose: Function;
}
interface IErrorBoxSatate {
  open: boolean;
}

class ErrorBox extends React.Component<
  ConfirmationDialogProps,
  IErrorBoxSatate
  > {
  public state: IErrorBoxSatate = {
    open: true
  };
  public static defaultProps = {
    title: t`Error`,
    closeText: t`Close`
  };

  public handleClose = () => {
    this.props.onClose();
    this.setState({ open: false });
  }

  public render() {
    const missingFields = _.isArray(this.props.error) ? (
      _.map(this.props.error, (error, index) => <li key={index}>{error}</li>)
    ) : (
        <li key={1}>{this.props.error}</li>
      );
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent className={styles.view}>
          <div className={styles.modalScreen}>
            <div className={styles.main}>
              <ul className={styles.content}>{missingFields}</ul>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>{t`Ok`}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ErrorBox;
