import { createStyles } from "@mui/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: "58px",
      height: "38px",
      padding: "12px"
    },
    switchBase: {
      color: "#d5d2d5",
      "&$checked": {
        color: "#4b1c46 !important",
        "& + $track": {
          backgroundColor: "rgba(75, 28, 70, 0.5)",
          border: "none",
          opacity: 1
        }
      },
      "&$focusVisible $thumb": {
        color: "#4b1c46"
      },
      "&.Mui-disabled": {
        opacity: 1,
        color: "#d5d2d5",
        "& + $track": {
          opacity: 1,
          backgroundColor: "rgba(213, 210, 213, 0.6)"
        }
      }
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: "none"
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: "rgba(213, 210, 213, 0.6)",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  });
